import { IAction } from '@common/types/';
import { ActionResponse } from '../excuteAction';
import { Platform, Share as ShareRN, Clipboard } from 'react-native';
import { getFieldValue } from '@common/utils/binding';
import { NativeModules } from 'react-native';
import useShareSNS from '@common/hooks/shareSNS';
import { Settings, ShareDialog } from 'react-native-fbsdk-next';

const SHARE_SNS_TYPE = {
  SHARE_DATA: 'shareData',
  SHARE_LIVE_STREAM: 'shareLiveStream',
  SHARE_APP: 'shareApp',
};

const FLATFORM_NAME = {
  CLIPBOARD: 'clipboard',
  ALL: 'all',
  FACEBOOK: 'FACEBOOK',
};

const shareSNS: (action: IAction) => Promise<ActionResponse> = async (
  action: IAction
) => {
  if (Platform.OS === 'web') {
    return {
      status: 'FAILED',
    };
  }
  try {
    const { createShareLink, getLastBuild } = useShareSNS();
    const {
      SCHEME_DEEP_LINK_APP,
      HOST_APP_LINK,
      APP_BUNDLE,
      APP_BUNDLE_ID,
      APP_UUID,
    } = NativeModules?.ReactNativeConfigModule;

    const typeShare = getFieldValue(action?.type);
    const messageValue = getFieldValue(action.options?.message);
    const streamId = getFieldValue(action.options?.streamId);
    const iosAppId = getFieldValue(action.options?.iosAppId);
    const iosAppName = getFieldValue(action.options?.iosAppName);
    const screenId = getFieldValue(action.options?.screen);
    const username = getFieldValue(action.options?.username);
    const platformName = getFieldValue(action.options?.platformName) || 'SMS';
    const facebookAppId = getFieldValue(action.options?.facebookAppId) || 'SMS';

    Settings.setAppID(facebookAppId);

    let handleShare: any;

    const onShareSingle = async (message: string) => {
      const Share = require('react-native-share').default;

      const SOCIAL_FLATFORMS: any = {
        FACEBOOK: Share.Social.FACEBOOK,
        TWITTER: Share.Social.TWITTER,
      };
      await Share.shareSingle({
        social: SOCIAL_FLATFORMS[platformName],
        message,
        url: '',
      });
    };

    const onShareFacebook = async (message: string, shortUrl?: string) => {
      const shareLinkContent: any = {
        contentType: 'link',
        contentUrl: shortUrl,
        contentTitle: message,
        contentDescription: message,
      };

      await ShareDialog.canShow(shareLinkContent).then(function (canShow) {
        if (canShow) {
          return ShareDialog.show(shareLinkContent);
        }
      });
    };

    const copyToClipboard = async (textValue: string) => {
      await Clipboard.setString(`${textValue}`);
    };

    const onShare = async (message: string) => {
      try {
        const result = await ShareRN.share({
          message,
        });
        if (result.action === ShareRN.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === ShareRN.dismissedAction) {
        }
      } catch (error: any) {
        alert(error.message);
      }
    };

    switch (platformName) {
      case FLATFORM_NAME.CLIPBOARD:
        handleShare = copyToClipboard;
        break;
      case FLATFORM_NAME.ALL:
        handleShare = onShare;
        break;
      case FLATFORM_NAME.FACEBOOK:
        handleShare = onShareFacebook;
        break;
      default:
        handleShare = onShareSingle;
        break;
    }
    let longLink: {
      ios?: string;
      android?: string;
    } = {};

    const lastBuild = await getLastBuild(APP_UUID);
    const iosSchemeDeepLink =
      lastBuild?.ios?.schemeDeepLinkApp || SCHEME_DEEP_LINK_APP;
    const iosBundle = lastBuild?.ios?.bundle || APP_BUNDLE_ID;
    const androidSchemeDeepLink =
      lastBuild?.android?.schemeDeepLinkApp || SCHEME_DEEP_LINK_APP;
    const androidBundle = lastBuild?.android?.bundle || APP_BUNDLE;

    switch (typeShare) {
      case SHARE_SNS_TYPE.SHARE_APP:
        longLink.ios = `deepLink=${iosSchemeDeepLink}&app=${iosBundle}&iosAppId=${iosAppId}&iosAppName=${iosAppName}`;

        longLink.android = `deepLink=${androidSchemeDeepLink}&app=${androidBundle}&iosAppId=${iosAppId}&iosAppName=${iosAppName}`;

        const dataShareApp = await createShareLink('app', longLink);
        const contentShareApp = `${username}のライブ配信が始まります！https://${HOST_APP_LINK}/share/${dataShareApp?.shortUrl} #LIVERHOUSE #ライブコマース #ライブ配信`;

        handleShare(
          contentShareApp,
          `https://${HOST_APP_LINK}/share/${dataShareApp?.shortUrl}`
        );

        break;
      case SHARE_SNS_TYPE.SHARE_LIVE_STREAM:
        longLink.ios = `deepLink=${iosSchemeDeepLink}&app=${iosBundle}&screen=${screenId}&streamId=${streamId}&iosAppId=${iosAppId}&iosAppName=${iosAppName}`;

        longLink.android = `deepLink=${androidSchemeDeepLink}&app=${androidBundle}&screen=${screenId}&streamId=${streamId}&iosAppId=${iosAppId}&iosAppName=${iosAppName}`;

        const data = await createShareLink('liverstream', longLink);
        const content = `https://${HOST_APP_LINK}/share/${data?.shortUrl}`;

        handleShare(
          content,
          `https://${HOST_APP_LINK}/share/${data?.shortUrl}`
        );

        break;
      default:
        onShare(messageValue);
        break;
    }
    return {
      status: 'SUCCEED',
    };
  } catch (error) {
    return {
      status: 'FAILED',
    };
  }
};

export default shareSNS;
