import React, { useState, useEffect, useRef } from 'react';
import { Checkbox } from 'react-native-paper';
import {
  Text,
  View,
  TextInput,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { get, isEmpty, isNil } from 'lodash';

import createStyles from './style';
import { checkMessage, checkType } from '../func';
import { stripeMessage } from '../locale';
import AsyncStorage from '@react-native-async-storage/async-storage';

type IProps = {
  height: number;
  width: number;
  onPress: any;
  stripePayment: any;
  updateStripePayment: any;
  accountConnectId: string;
  groupActionId: any;
  id: string;
  locale: string;
  changeInput?: any;
  valueInputs?: any;
  attributes: any;
};

const StripeComponent: React.FC<IProps> = ({
  height,
  width,
  attributes,
  onPress,
  stripePayment,
  updateStripePayment,
  accountConnectId,
  groupActionId,
  locale,
  id,
}) => {
  const styles = createStyles({ attrs: attributes, width, height });

  const {
    type,
    fontFamily,
    title,
    changedescription,
    paymentOptions,
    email,
    testMode,
    rememberCheckbox,
    submitButtonVisibility,
  } = attributes;

  const { enabled: titleEnabled = true, text: titleText = 'Card information' } =
    title || {};

  const [message, setMessage] = useState<any | null>(null);
  const [isError, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const refcardNumber: any = useRef();
  const refMonth: any = useRef();
  const refYear: any = useRef();
  const refCVC: any = useRef();

  const isPreview =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  const [rememberCard, setRememberCard] = useState(false);
  const [inputCardNumber, setInputCardNumber] = useState('');
  const [inputCardMonth, setInputCardMonth] = useState('');
  const [inputCardYear, setInputCardYear] = useState('');
  const [inputCardCVC, setInputCardCVC] = useState('');
  const [typeCard, setTypeCard] = useState('');

  useEffect(() => {
    AsyncStorage.getItem('cardInfo').then((keyValue: string | any) => {
      const keyValueObject = JSON.parse(keyValue);
      setRememberCard(keyValueObject?.remember);
      setInputCardNumber(keyValueObject?.number || '');
      setInputCardMonth(keyValueObject?.month || '');
      setInputCardYear(keyValueObject?.year || '');
    });
  }, []);

  useEffect(() => {
    if (inputCardNumber.length < 3) {
      const typeCardElement = checkType(inputCardNumber);
      setTypeCard(typeCardElement);
    } else {
      return;
    }
  }, [inputCardNumber]);

  const handleAction = (id: string, isAction: any) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});

    if (groupActionId && isNil(isAction)) {
      if (isEmpty(sectionOnpress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  const saveCard = () => {
    if (rememberCard === true) {
      const localCardInfo = {
        remember: rememberCard,
        number: inputCardNumber,
        month: inputCardMonth,
        year: inputCardYear,
      };
      AsyncStorage.setItem('cardInfo', JSON.stringify(localCardInfo));
    }
  };

  const handlePress = async () => {
    saveCard();
    if (
      (testMode.enabled &&
        !isEmpty(testMode.publishableKey) &&
        !isEmpty(testMode.secretKey)) ||
      testMode.enabled === false
    ) {
      try {
        const payload = {
          amount: paymentOptions.paymentAmount,
          currency: paymentOptions.typeCurrency,
          destination: accountConnectId,
          receipt_email: email.emailBuyer,
          description: changedescription.description,
          testMode,
          card: {
            number: inputCardNumber,
            exp_month: inputCardMonth,
            exp_year: `20${inputCardYear}`,
            cvc: inputCardCVC,
          },
        };

        setLoading(true);

        if (inputCardNumber.length < 16) {
          setMessage(stripeMessage({ locale, type: 'card_complte' }));
          setLoading(false);
          setError(true);
          setLoading(false);
          return;
        }

        if (
          (inputCardYear.length < 2 && inputCardNumber !== '') ||
          (inputCardMonth.length < 2 && inputCardNumber !== '') ||
          (inputCardMonth === '' && inputCardNumber !== '')
        ) {
          setError(true);
          setMessage(
            stripeMessage({
              locale,
              type: 'expiration_date_incomplete',
            })
          );
          setLoading(false);
          return;
        }

        if (
          inputCardYear.length === 2 &&
          new Date().getFullYear() > Number(`20${inputCardYear}`)
        ) {
          setError(true);
          setMessage(
            stripeMessage({
              locale,
              type: 'expiration_year',
            })
          );
          setLoading(false);
          return;
        }

        if (
          inputCardCVC.length < 3 &&
          inputCardNumber !== '' &&
          inputCardMonth !== '' &&
          inputCardYear !== ''
        ) {
          setError(true);
          setMessage(
            stripeMessage({
              locale,
              type: "card's_security_incomplete",
            })
          );
          setLoading(false);
          return;
        }
        if (
          (isEmpty(inputCardNumber) &&
            inputCardMonth.length === 1 &&
            isEmpty(inputCardYear) &&
            isEmpty(inputCardCVC)) ||
          (isEmpty(inputCardNumber) &&
            inputCardMonth.length === 2 &&
            inputCardYear.length < 2 &&
            isEmpty(inputCardCVC))
        ) {
          setError(true);
          setMessage(
            stripeMessage({
              locale,
              type: 'expiration_date_incomplete',
            })
          );
          setLoading(false);
          return;
        }

        if (
          isEmpty(inputCardNumber) &&
          inputCardMonth.length === 2 &&
          inputCardYear.length === 2 &&
          inputCardCVC.length < 3
        ) {
          setError(true);
          setMessage(
            stripeMessage({
              locale,
              type: "card's_security_incomplete",
            })
          );
          setLoading(false);
          return;
        }

        if (
          isEmpty(inputCardNumber) &&
          inputCardMonth.length === 2 &&
          inputCardYear.length === 2 &&
          inputCardCVC.length === 3
        ) {
          setError(true);
          setMessage(stripeMessage({ locale, type: 'card_complte' }));
          setLoading(false);
          return;
        }

        if (
          isEmpty(inputCardNumber) &&
          isEmpty(inputCardMonth) &&
          isEmpty(inputCardYear) &&
          !isEmpty(inputCardCVC) &&
          inputCardCVC.length < 3
        ) {
          setError(true);
          setMessage(
            stripeMessage({
              locale,
              type: "card's_security_incomplete",
            })
          );
          setLoading(false);
          return;
        }

        if (
          isEmpty(inputCardNumber) &&
          isEmpty(inputCardMonth) &&
          isEmpty(inputCardYear) &&
          inputCardCVC.length === 3
        ) {
          setError(true);
          setMessage(stripeMessage({ locale, type: 'card_complte' }));
          setLoading(false);
          return;
        }

        const result = await onPress('beforeActions');
        const purchaseIndex =
          result?.findIndex(
            (item: any) => item.actionType === 'purchase_product'
          ) || -1;
        const objectPurchase = result?.[purchaseIndex];

        if (objectPurchase?.status === 'SUCCEED' || purchaseIndex === -1) {
          let clientSecret = await stripePayment(payload);
          switch (clientSecret?.status) {
            case 'succeeded':
              setMessage(stripeMessage({ locale, type: 'success' }));
              setError(false);
              handleAction('successActions', attributes?.successActions);
              break;

            case 'processing':
              setMessage(stripeMessage({ locale, type: 'processing' }));
              setError(false);
              break;

            case 'requires_payment_method':
              setMessage(
                stripeMessage({ locale, type: 'requires_payment_method' })
              );
              setError(true);
              handleAction('failedActions', attributes?.failedActions);
              break;

            default:
              setMessage(stripeMessage({ locale, type: 'error_card' }));
              setError(true);
              handleAction('failedActions', attributes?.failedActions);
              break;
          }
        } else {
          setMessage(stripeMessage({ locale, type: 'not_purchase' }));
          setError(true);
          handleAction('failedActions', attributes?.failedActions);
        }
      } catch (error: any) {
        if (
          error.type === 'card_error' ||
          error.type === 'validation_error' ||
          error.response?.data?.message
        ) {
          if (
            isEmpty(inputCardNumber) &&
            isEmpty(inputCardMonth) &&
            isEmpty(inputCardYear) &&
            isEmpty(inputCardCVC)
          ) {
            setMessage(stripeMessage({ locale, type: 'error_occured' }));
            setError(true);
          } else if (error.response?.data?.message) {
            if (
              error.response?.data?.message === 'Your card number is incorrect.'
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'card_number_incorrect',
                })
              );
              handleAction('failedActions', attributes?.failedActions);
            } else if (
              error.response?.data?.message.includes('Invalid email')
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'invalid_email_address',
                })
              );
              handleAction('failedActions', attributes?.failedActions);
            } else if (
              error.response?.data?.message ===
              'Amount must be at least ¥50 jpy'
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'amount_least_¥50',
                })
              );
              handleAction('failedActions', attributes?.failedActions);
            } else if (
              error.response?.data?.message ===
              'Missing required param: amount.'
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'missing_amount',
                })
              );
            } else if (
              error.response.data.message.includes(
                'You have exceeded the maximum number of declines on this card'
              )
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'max_number',
                })
              );
              handleAction('failedActions', attributes?.failedActions);
            } else if (
              error.response?.data?.message.includes('Your card was declined.')
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'card_declined',
                })
              );
              handleAction('failedActions', attributes?.failedActions);
            } else if (
              error.response?.data?.message ===
              'This value must be greater than or equal to 1.'
            ) {
              setMessage(
                stripeMessage({
                  locale,
                  type: 'value_greater',
                })
              );
              handleAction('failedActions', attributes?.failedActions);
            } else if (
              error.response?.data?.message.includes('Invalid API Key provided')
            ) {
              setMessage(stripeMessage({ locale, type: 'invalid_api' }));
              handleAction('failedActions', attributes?.failedActions);
            } else {
              setMessage(error.response?.data?.message);
              handleAction('failedActions', attributes?.failedActions);
            }
          } else {
            setMessage(error.message);
            handleAction('failedActions', attributes?.failedActions);
          }
          setError(true);
        } else {
          setError(true);
          setMessage(stripeMessage({ locale, type: 'error_occured' }));
        }
      }
    } else {
      setError(true);

      if (
        isEmpty(inputCardNumber) &&
        isEmpty(inputCardMonth) &&
        isEmpty(inputCardYear) &&
        isEmpty(inputCardCVC)
      ) {
        setMessage(stripeMessage({ locale, type: 'error_occured' }));
        setError(true);
      } else if (isEmpty(testMode.publishableKey)) {
        setMessage(stripeMessage({ locale, type: 'publishable_key' }));
        setError(true);
      } else {
        setMessage(stripeMessage({ locale, type: 'secret_key' }));
        setError(true);
      }
    }

    setLoading(false);
  };

  const handePressDisabled = () => {
    saveCard();
    handleAction('submitActions', attributes?.submitActions);
  };

  useEffect(() => {
    setInputCardCVC('');
    setMessage('');
    setError(false);
  }, [id]);

  const isConnected =
    isNil(accountConnectId) && !isPreview && !get(testMode, 'enabled', false);

  const isKey =
    (testMode.enabled &&
      isEmpty(testMode.publishableKey) &&
      isEmpty(testMode.secretKey)) ||
    (testMode.enabled && isEmpty(testMode.publishableKey)) ||
    (testMode.enabled && isEmpty(testMode.secretKey)) ||
    (testMode.publishableKey !== '' &&
      !String(testMode.publishableKey).startsWith('pk_test') &&
      testMode.enabled === true) ||
    (testMode.secretKey !== '' &&
      !String(testMode.secretKey).startsWith('sk_test') &&
      testMode.enabled === true);

  const checkMonth = (text: any) => {
    if (Number(text) >= 2 && Number(text) < 10 && text.slice(0, 1) !== '0') {
      for (var i = 2; i < 10; i++) {
        setInputCardMonth(`0${text}`);
        refYear.current.focus();
      }
    } else if (Number(text) > 12) {
      const numberFirst = text.slice(0, 1);
      const numberTwo = text.slice(1, 2);
      setInputCardMonth(`0${numberFirst}`);
      refYear.current.focus();
      setInputCardYear(numberTwo);
    } else {
      setInputCardMonth(text);
      if (text.length === 2) {
        refYear.current.focus();
      }
    }
  };
  const handleCheck = () => {
    if (rememberCard === true) {
      const localCardInfo = {
        remember: false,
        number: '',
        month: '',
        year: '',
      };
      AsyncStorage.setItem('cardInfo', JSON.stringify(localCardInfo));
      setRememberCard(false);
    } else {
      setRememberCard(true);
    }
  };
  return (
    <View style={styles.container}>
      {isConnected && (
        <View>
          <Text
            style={{
              color: 'red',
            }}
          >
            {stripeMessage({
              locale,
              type: 'stripe_configured',
            })}
          </Text>
        </View>
      )}

      {isKey && !isPreview && (
        <View>
          <Text
            style={{
              color: 'red',
            }}
          >
            {checkMessage(
              testMode.publishableKey,
              testMode.enabled,
              testMode.secretKey,
              locale
            )}
          </Text>
        </View>
      )}

      {titleEnabled && <Text style={styles.title}>{titleText}</Text>}
      <View style={styles.block}>
        <View>
          {typeCard === 'VISA' ? (
            <Image
              style={styles.image}
              source={{
                uri: 'https://www.investo.vn/wp-content/uploads/2021/05/visa-1.png.webp',
              }}
              resizeMode="cover"
            />
          ) : typeCard === 'MASTER' ? (
            <Image
              style={[styles.image, { width: 23, borderRadius: 5, left: 2 }]}
              source={{
                uri: 'https://adcacademy.vn/wp-content/uploads/2016/07/mastercard_logo_on_black.png',
              }}
              resizeMode="cover"
            />
          ) : typeCard === 'AEXPRESS' ? (
            <Image
              style={styles.image}
              source={{
                uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj8ZF07m1S7mzF4J7nQ_AdvrlQzhf5sSXpIj1-gv7igkbhVYCHhISKB1HEdZ4FonZqQdM&usqp=CAU',
              }}
              resizeMode="cover"
            />
          ) : typeCard === 'DINERS' ? (
            <Image
              style={styles.image}
              source={{
                uri: 'https://pngimage.net/wp-content/uploads/2018/05/diners-png-5.png',
              }}
              resizeMode="cover"
            />
          ) : typeCard === 'JCB' ? (
            <Image
              style={styles.image}
              source={{
                uri: 'https://lh3.googleusercontent.com/proxy/Mx9Vj5PGRcOL_eqhB_UIDZYWO0WmZqWcS4G_NRcsDd1mACocCtelobh6AoGvDBYtH4FwoqG_-n3QpFk0gAa2IScMO60GFmM0X7pXqNsF',
              }}
            />
          ) : (
            <Image
              style={styles.image}
              source={{
                uri: 'https://cdn1.iconfinder.com/data/icons/cash-card-add-on/48/v-22-512.png',
              }}
            />
          )}

          <View style={{ left: 30 }}>
            <TextInput
              style={styles.cardNumber}
              maxLength={16}
              keyboardType="numeric"
              placeholder={stripeMessage({ locale, type: 'card_number' })}
              placeholderTextColor="#BDBDBD"
              value={inputCardNumber}
              onSubmitEditing={() => refMonth.current.focus()}
              onChangeText={(text: any) =>
                text.length === 16
                  ? (setInputCardNumber(text), refMonth.current.focus())
                  : setInputCardNumber(text)
              }
              returnKeyType="next"
              ref={refcardNumber}
            />
          </View>

          <View style={styles.rightPlaceWeb}>
            <TextInput
              style={styles.monthWeb}
              value={inputCardMonth}
              onChangeText={(text: any) => checkMonth(text)}
              placeholder={stripeMessage({ locale, type: 'MM' })}
              keyboardType="numeric"
              maxLength={2}
              placeholderTextColor="#BDBDBD"
              onSubmitEditing={() => refYear.current.focus()}
              ref={refMonth}
              returnKeyType="next"
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Backspace') {
                  if (inputCardMonth === '') {
                    refcardNumber.current.focus();
                  }
                }
              }}
            />
            <Text style={styles.spaceWeb}>/</Text>
            {/* <View
              style={styles.yearWeb}
              pointerEvents={isEmpty(inputCardMonth) ? 'none' : 'auto'}
            > */}
            <TextInput
              style={styles.yearWeb}
              value={inputCardYear}
              onChangeText={(text: any) =>
                text.length === 2
                  ? (setInputCardYear(text), refCVC.current.focus())
                  : setInputCardYear(text)
              }
              placeholder={stripeMessage({ locale, type: 'YY' })}
              keyboardType="numeric"
              placeholderTextColor="#BDBDBD"
              maxLength={2}
              ref={refYear}
              onSubmitEditing={() => refCVC.current.focus()}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Backspace') {
                  if (inputCardYear === '') {
                    refMonth.current.focus();
                  }
                }
              }}
            />
            {/* </View> */}

            <TextInput
              style={styles.cvcWeb}
              value={inputCardCVC}
              onChangeText={(text: any) =>
                text.length === 3
                  ? setInputCardCVC(text)
                  : setInputCardCVC(text)
              }
              placeholder="CVC"
              keyboardType="numeric"
              placeholderTextColor="#BDBDBD"
              maxLength={3}
              ref={refCVC}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Backspace') {
                  if (inputCardCVC === '') {
                    refYear.current.focus();
                  }
                }
              }}
            />
          </View>
        </View>
      </View>

      {!!message && (
        <View>
          <Text style={{ marginTop: 10, color: isError ? 'red' : 'green' }}>
            {message}
          </Text>
        </View>
      )}
      <View style={styles.rememberCard}>
        <Checkbox
          status={rememberCard === true ? 'checked' : 'unchecked'}
          onPress={handleCheck}
          color={get(attributes, 'submitButton.backgroundColor', 'orange')}
        />
        <Text style={[styles.title, { fontSize: 12 }]}>
          {rememberCheckbox?.text}
        </Text>
      </View>
      {isEmpty(accountConnectId) && testMode.enabled === false ? (
        <View
          style={[styles.click, { flexDirection: loading ? 'row' : 'column' }]}
        >
          <Text style={styles.label}>
            {get(attributes, 'submitButton.text')}
          </Text>
        </View>
      ) : (
        <TouchableOpacity
          style={[styles.click, { flexDirection: loading ? 'row' : 'column' }]}
          onPress={submitButtonVisibility ? handlePress : handePressDisabled}
        >
          {loading && (
            <ActivityIndicator
              size="small"
              color="white"
              style={{ paddingRight: 10 }}
            />
          )}
          <Text style={styles.label}>{attributes?.submitButton?.text}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default StripeComponent;
