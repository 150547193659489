import React, {
  FC,
  memo,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import ReactHlsPlayer from 'react-hls-player';
import { View } from 'react-native-animatable';
import { get } from 'lodash';

import createStyles from '@common/components/LiveStreamCamera/WebSource/style';
import {
  getLivestreamChannel,
  handleLivestreamSocket,
  livestreamSocket,
} from '@common/configs/livestream-socket';
import Item from '@common/components/LiveStreamCamera/MobileSource/Viewer/Item';
import useLiveStream from '@common/hooks/useLiveStream';
import { useBinding } from '@common/hooks/useBinding';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { useLocation } from '@common/routes/hooks';
import { isCanvas } from '@common/utils/functions';
import { LIVE_STREAM_ACTION_TYPES } from '@common/constants/livestream.constant';

const ViewLiveStream: FC<any> = ({ attrs, profile }) => {
  const styles = createStyles();
  const playerRef = useRef<RefObject<HTMLVideoElement>>();
  const currentRecord = useSelector(getCurrentRecord) || {};
  const getTableId = get(attrs, 'selectedItem.tableId');
  const location = useLocation();
  const { changeValue, pushView, getLiveStream } = useLiveStream(attrs);
  const { handleBindingField } = useBinding();
  const [liveStreamDetail, setLiveStreamDetail] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const streamIdValue = handleBindingField(
      attrs?.streamId || '',
      attrs?.record || currentRecord[getTableId]
    );

    let recordIdSelected = streamIdValue || location?.state?.streamId;

    if (recordIdSelected) {
      getLiveStream({
        streamId: recordIdSelected,
        accessToken: profile.accessToken,
        userRecordId: profile.userId,
      }).then((data: any) => {
        setLiveStreamDetail(data);
        if (data?.streamInfo) {
          setIsSuccess(data?.urls ? true : false);
        } else {
          setIsSuccess(false);
        }
      });
    }
  }, [
    location?.state?.streamId,
    setLiveStreamDetail,
    attrs?.streamId,
    attrs.record,
  ]);

  useEffect(() => {
    if (liveStreamDetail && isSuccess) {
      if (
        typeof liveStreamDetail === 'object' &&
        Object.keys(liveStreamDetail).length < 1
      ) {
        setIsSuccess(false);
      } else {
        const metadata = liveStreamDetail?.streamInfo?.metadata;
        const owner = metadata?.owner || {};

        changeValue({
          id: liveStreamDetail?.streamInfo?.id,
          streamer: owner?.userId,
        });
      }
    }
  }, [liveStreamDetail, isSuccess]);

  useEffect(() => {
    const streamId = liveStreamDetail?.streamInfo?.id;
    if (streamId && isSuccess) {
      pushView({ infoUser: profile, streamId }).then((data: any) => {
        changeValue({
          totalView: data?.total_view || 0,
        });
      });

      handleLivestreamSocket(streamId, (socket: SocketIOClient.Socket) => {
        socket.emit('get', 'livestreams', streamId, (data: any) => {
          data?.totalView &&
            changeValue({
              totalView: data?.totalView,
            });
        });
        socket.on(getLivestreamChannel(streamId), (_data: any) => {
          const { totalView, action, data } = _data;
          if (
            action === LIVE_STREAM_ACTION_TYPES.StopLiveStream &&
            data?.streamId === streamId
          ) {
            setIsSuccess(false);
            return;
          }

          totalView && changeValue({ totalView });
        });
      });
    }
  }, [liveStreamDetail?.data?.streamInfo?.id, isSuccess]);

  useEffect(() => {
    return () => {
      livestreamSocket?.removeAllListeners();
      livestreamSocket?.close();
      changeValue({
        totalView: 0,
      });
    };
  }, []);

  // set video
  const Hls = useMemo(() => {
    if (liveStreamDetail?.urls?.M3U8) {
      let url = liveStreamDetail.urls.M3U8;
      url = url.replace('http:', 'https:');
      return (
        <ReactHlsPlayer
          playerRef={playerRef.current as any}
          src={url}
          autoPlay={true}
          controls={true}
          muted={false}
          width="100%"
          height="100%"
        />
      );
    }
    return <View></View>;
  }, [liveStreamDetail?.urls?.M3U8]);

  return (
    <View style={styles.viewLiveStream}>
      {Hls}
      <Item item={{ id: 1 }} attrs={attrs} index={0} isActive={true} />
    </View>
  );
};

// export default ViewLiveStream;
export default memo(ViewLiveStream, (prev, next) => {
  if (isCanvas()) {
    return false;
  }

  const con =
    prev.id === next.id && prev.attrs?.streamerId === next.attrs?.streamerId;
  return con;
});
