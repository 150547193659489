import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { View, FlatList, Platform, Dimensions } from 'react-native';
import queryString from 'query-string';
import { isEmpty, get } from 'lodash';

import { ICustomHorizontalList } from '@nocode/types';
import CardItem from '@nocode/components/CustomHorizontalList/CardItem';
import { getItemList } from '@nocode/components/CardList/func';
import EmptyList from '@nocode/components/EmptyList';

export const CUSTOM_HORIZONTAL_LIST = 'customHorizontalList';
let timeOut: any;

const CustomHorizontalList: React.FC<ICustomHorizontalList> = (attribute) => {
  const flatListRef: any = useRef();
  const { width } = Dimensions.get('window');

  const [page, setPage] = useState<any>(1);
  const [heightCard, setHeightCard] = useState<number>();
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });
  const [isScroll, setScroll] = useState<boolean>(false);

  const cardItemsData: any = useMemo(() => {
    return getItemList(attribute, CUSTOM_HORIZONTAL_LIST);
  }, [attribute]);

  const tableId = get(attribute, 'attributes.items.source.tableId');
  const maximum = get(attribute, 'attributes.items.source.options.limit', null);

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;

  if (target && !tableId)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attribute.zIndex,
        }}
      >
        <EmptyList attributes={attribute} />
      </View>
    );

  const elementId = 'flat-list' + '-' + attribute.id;
  const isCanvas = Platform.OS === 'web' && !target;

  useEffect(() => {
    if (isCanvas) return;
    setPage(1);
    flatListRef.current.scrollToOffset({ animated: true, offset: 0, x: 0 });

    if (Platform.OS !== 'web') return;

    const flatListId = document.getElementById(elementId);

    setHeightCard(flatListId?.clientHeight);
  }, [elementId]);

  useEffect(() => {
    if (isCanvas) return;
    flatListRef.current.scrollToOffset({ animated: true, offset: 0, x: 0 });

    if (attribute?.hasAction) {
      attribute.setLoadMore && attribute.setLoadMore(1);
      setPage(1);
    }
  }, [attribute?.hasAction]);

  const getMargin = () => {
    const shadowSize = attribute?.attributes.shadow?.size || 0;
    const shadowX = attribute?.attributes.shadow?.x || 0;
    const shadowY = attribute?.attributes.shadow?.y || 0;
    const shadowBlur = attribute?.attributes.shadow?.blur || 0;
    if (!shadowBlur || !shadowSize) {
      return;
    }
    const getOffSet = (number: number) => (number > 0 ? number : 0);

    return {
      marginBottom: getOffSet(shadowSize + shadowY),
      marginTop: getOffSet(shadowSize - shadowY),
      marginLeft: getOffSet(shadowSize - shadowX),
      marginRight: getOffSet(shadowSize + shadowX),
    };
  };

  const setParentScroll = useCallback(
    (value: boolean) => {
      if (attribute?.enableScrollViewScroll !== value) {
        attribute?.setEnableScrollViewScroll &&
          attribute?.setEnableScrollViewScroll(value);
      }
    },
    [attribute?.enableScrollViewScroll, attribute?.setEnableScrollViewScroll]
  );

  const checkScroll = useCallback(() => {
    if (!isScroll) {
      setParentScroll(true);
    }
  }, [isScroll, setParentScroll]);

  useEffect(() => {
    if (!attribute?.enableScrollViewScroll) {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(checkScroll, 4000);
    }
  }, [attribute?.enableScrollViewScroll]);

  const cardMargin = getMargin();

  return (
    <FlatList
      nativeID={elementId}
      data={cardItemsData}
      horizontal
      pagingEnabled={Platform.OS === 'web'}
      scrollEnabled
      snapToAlignment="start"
      decelerationRate={'normal'}
      bounces={false}
      showsHorizontalScrollIndicator={false}
      ref={flatListRef}
      onEndReachedThreshold={0.1}
      onMomentumScrollEnd={() => {
        setParentScroll(true);
      }}
      onScroll={(e) => setScroll(true)}
      ItemSeparatorComponent={({ highlighted }) => (
        <View
          style={[
            { marginLeft: attribute.attributes.gap },
            highlighted && { marginLeft: 0 },
          ]}
        />
      )}
      onEndReached={() => {
        if (attribute?.loading && cardItemsData.length < 10) return;

        if (
          cardItemsData.length === attribute?.total ||
          cardItemsData.length === maximum
        ) {
          setPage(1);
          return;
        }

        setPage(page + 1);
        attribute.setLoadMore && attribute.setLoadMore(page + 1);
      }}
      renderItem={({ item, index }) => (
        <CardItem
          isLast={cardItemsData?.length === index + 1}
          index={index}
          cardItem={item}
          attributes={attribute.attributes}
          onPress={attribute?.onPress}
          heightCard={heightCard}
          cardMargin={cardMargin}
          records={attribute.records}
        />
      )}
      onScrollEndDrag={() => {
        setParentScroll(true);
      }}
      keyExtractor={(item, index) => `${(item?._id, index)}`}
      onTouchStart={() => {
        if (contentSize.width > width) {
          setParentScroll(false);
        }
      }}
      onContentSizeChange={(width, height) => {
        setContentSize({ width, height });
      }}
    />
  );
};

export default CustomHorizontalList;
