import { createSelector } from 'reselect';
import { RootState } from '../store';

export const pagesSelector = createSelector(
  (state: RootState) => state.pageInfo,
  (pageInfo) => pageInfo.pages
);

export const pageInfoSelector = (state: RootState) => state.pageInfo;

export const dimensionSelector = createSelector(
  (state: RootState) => state.pageInfo,
  (pageInfo) => pageInfo.dimension
);

export const googleMaploaded = createSelector(
  (state: RootState) => state.pageInfo,
  (pageInfo) => pageInfo.googleMaploaded
);

export const refreshingPageSelector = (state: RootState) =>
  state.pageInfo.pageReFreshing;
