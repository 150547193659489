import StatusBar from '@common/components/StatusBar';
import useDatabaseListener from '@common/hooks/databaseListener';
import { appInfoSelector } from '@common/redux/selectors/app';
import {
  getCurrentRecord,
  reFreshStatusSelector,
} from '@common/redux/selectors/database';
import { dimensionSelector } from '@common/redux/selectors/page';
import { setReloadStatus } from '@common/redux/slice/database';
import { useLocation } from '@common/routes/hooks';
import { PropRender } from '@common/types/';
import {
  getAttributesFixed,
  getAttributesNormal,
  getMetadataScreen,
  getPaddingBottomScreen,
} from '@common/utils/functions';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import { showAleart } from '@common/utils/handleActions/func/func';
import { isEmpty, isNil } from 'lodash';
import {
  default as qs,
  default as queryString,
  ParsedQuery,
} from 'query-string';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StatusBar as StatusBarRN,
  View,
  Text,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import BackgroundImage from './BackgroundImage';
import ObjectRender from './ObjectRender';
import { loseConnectSelector } from '@common/redux/selectors/liveStream';
import { HEIGHT_STATUS_BAR } from '@common/constants/shared';

export default function Render({ screen }: PropRender) {
  const dispatch = useDispatch();
  const dimension = useSelector(dimensionSelector);
  screen.metadata = getMetadataScreen(screen);
  const currentData = useSelector(getCurrentRecord);
  const appInfo = useSelector(appInfoSelector);
  const handleActions = useCallback(
    async (arrayAction: Array<any>, currentData: Record<string, any>) => {
      await actionPromise({
        actionComponent: arrayAction,
        currentRecord: currentData,
      });
    },
    [currentData]
  );

  const { search } = useLocation();
  const { hiddenStatusBar }: ParsedQuery<any> = queryString.parse(search);

  const pathname = qs.parse(search);
  const ref: any = useRef();

  useLayoutEffect(() => {
    const { targetModal } = pathname;
    if (isNil(targetModal) || isEmpty(targetModal)) {
      ref?.current?.scrollTo({ y: 0, x: 0 });
    }
  }, [screen, ref]);

  useEffect(() => {
    const {
      attributes: { onVisit, componentActions },
    } = screen;

    if (onVisit) {
      const pageActions = componentActions[onVisit.actionId];
      if (pageActions && pageActions.actions && pageActions.actions.length) {
        handleActions(pageActions.actions, {});
      }
    }
  }, [screen]);

  const isWeb = useMemo(() => appInfo?.platform === 'web', [appInfo]);
  const isPullToRefresh = useMemo(
    () => screen?.attributes?.pullToRefresh || false,
    [screen]
  );
  const isShowStatusBar =
    !screen.isModal &&
    !(Platform.OS === 'android' || Platform.OS === 'ios') &&
    !hiddenStatusBar &&
    screen?.attributes?.statusBarStyle !== 'hidden';

  const refreshing = useSelector(reFreshStatusSelector);

  const wait = (timeout: any) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  };

  const onRefresh = useCallback(() => {
    if (!refreshing) {
      dispatch(setReloadStatus(true));
    }
    wait(10000).then(() => dispatch(setReloadStatus(false)));
  }, [refreshing]);

  // refresh page hook
  useDatabaseListener();

  const refreshControlProps = {
    refreshing,
    onRefresh: onRefresh,
    tintColor: '#4259AC',
    progressViewOffset: 50,
    title: 'Refresh...',
  };

  const loseConnect = useSelector(loseConnectSelector);

  const isStartLiveComponent =
    screen?.metadata[0]?.type === 'LiveStreamCamera' &&
    screen?.metadata[0]?.defaultScreen === 'start';

  const renderItem = useCallback(() => {
    const originX = dimension.width / screen.width;
    const originY = dimension.height / screen.height;
    let paddingBottom = getPaddingBottomScreen(screen, originY);
    let arrNomal = getAttributesNormal(screen);
    let arrFixed = getAttributesFixed(screen);

    //TODO:
    // let heightStyles = { height: dimension.height };
    // if (Platform.OS !== 'web') {
    //   heightStyles.height = dimension.height;
    // }

    return (
      <View
        style={{
          backgroundColor: screen?.attributes?.backgroundColor,
          flex: 1,
          overflow: 'hidden',
          // ...(appInfo?.platform === 'web' && {
          //   maxWidth: 1200,
          //   margin: 'auto',
          //   width: '100%',
          // }),
        }}
      >
        {loseConnect && isStartLiveComponent && (
          <>
            <StatusBarRN barStyle={'light-content'} />
            <View
              style={{
                width: '100%',
                height:
                  Platform.OS === 'ios'
                    ? HEIGHT_STATUS_BAR + 25 * originY
                    : 25 * originY,
                alignItems: 'center',
                backgroundColor: 'red',
              }}
            >
              <Text
                style={{
                  color: '#fff',
                  position: 'absolute',
                  bottom: 3,
                  fontSize: 12,
                  fontFamily: 'Noto Sans JP',
                  fontWeight: '500',
                }}
              >
                インターネット接続がありません
              </Text>
            </View>
          </>
        )}

        {screen?.attributes?.backgroundImage && (
          <BackgroundImage
            source={screen?.attributes?.backgroundImage}
            resizeMode={screen?.attributes?.backgroundSize}
            positionX={screen?.attributes?.backgroundPositionX}
            positionY={screen?.attributes?.backgroundPositionY}
          />
        )}
        {!isWeb && (
          <StatusBar
            originX={originX}
            isShowStatusBar={isShowStatusBar}
            barStyle={screen?.attributes?.statusBarStyle}
          />
        )}
        <SafeAreaView
          style={{
            flex: 1,
            ...(Platform.OS === 'ios'
              ? { marginTop: StatusBarRN.currentHeight }
              : {}),
          }}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'android' ? undefined : 'padding'}
            style={{ flex: 1 }}
            // keyboardVerticalOffset={50}
          >
            <ScrollView
              ref={ref}
              bounces={isPullToRefresh}
              // style={{ flex: 1, paddingBottom: paddingBottom }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: paddingBottom,
              }}
              refreshControl={
                isPullToRefresh ? (
                  <RefreshControl {...refreshControlProps} />
                ) : undefined
              }
              keyboardShouldPersistTaps={
                screen?.attributes?.allowCancelDismissKeyboard
                  ? 'handled'
                  : 'never'
              }
            >
              <ObjectRender
                arrComp={arrNomal}
                isScreen={true}
                isShowStatusBar={isShowStatusBar}
              />
            </ScrollView>
          </KeyboardAvoidingView>
        </SafeAreaView>
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            height: '100%',
          }}
          pointerEvents="box-none"
        >
          <KeyboardAvoidingView
            behavior="height"
            style={{ flex: 1 }}
            pointerEvents="box-none"
            enabled={true}
            keyboardVerticalOffset={0}
          >
            <ObjectRender arrComp={arrFixed} isScreen={true} />
          </KeyboardAvoidingView>
        </View>
      </View>
    );
  }, [dimension, screen, appInfo, ref, loseConnect]);

  return <Fragment>{renderItem()}</Fragment>;
}
