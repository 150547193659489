import {
  find,
  get,
  isEmpty,
  minBy,
  some,
  sumBy,
  maxBy,
  ceil,
  map,
  findIndex,
  pick,
  filter,
  uniq,
  isNil,
  forEach,
  reduce,
  includes,
  isString,
  isArray,
  flatten,
  unionBy,
  omit,
  first,
  assign,
  orderBy,
  isNumber,
  lowerCase,
  every,
} from 'lodash';
import { IRecord } from '@common/types/database';
import {
  BINDING_SELECTOR_TYPE,
  DATE_FORMAT,
  ONE_DAY,
  RELATIONSHIP_TYPES,
} from '@common/constants/shared';
import {
  bindingType,
  FilterItem,
  getComparisonValue,
  getDataTypeFilter,
  getValueBindingInput,
  getValuePaginate,
} from '@common/utils/functions';
import moment from 'moment';
import store from '@common/redux/store';
import { getTextBinding } from '@common/utils/handleBinding';
import compare from '../compare';
import {
  getAvailability,
  getAvailabilityMultiConditional,
  isTwoDirectionArray,
} from '@common/hooks/useVisibility';
import { handlePermissionField } from '@common/hooks/usePermission';

import { databaseSelector } from '@common/redux/selectors/database';
import { findTable, getFieldTable } from '../database';
import {
  excuteRoundRelationship,
  getFieldRelationValue,
  getValueNestedList,
} from './helps';

export const manyRelation = ['hasManyRelation', 'manyToManyRelation'];

export const isRelation = [...manyRelation, 'belongsToRelation'];

const DATE_TIME_FIELD = ['dateOnly', 'date'];

export const DATE_TIME_FORMAT: any = {
  dateOnly: 'YYYY/MM/DD',
  date: 'YYYY/MM/DD HH:mm:ss',
};

export const flattenObj: any = (data: any) => {
  let result = [];

  while (data) {
    result.push(omit(data, 'source'));
    data = data.source;
  }

  return result.reverse();
};

export const isManyRoundRelaton = (source: any) => {
  const flattedSource = flattenObj(source);

  const lisSourceRelation = flattedSource.filter((item: any) =>
    isRelation.includes(item?.type)
  );

  return lisSourceRelation.length >= 2;
};

export const getRecordIdRelation = (idRelation: any[], recordsTable: any) => {
  return map(idRelation, (item) => {
    const index: number = findIndex(recordsTable, { _id: item });

    if (index > -1) {
      return recordsTable[index];
    }
  });
};

const caculatorValueRoundRelation = (
  source: any,
  initialValue: any,
  fieldId: string,
  type: string
) => {
  const records = excuteRoundRelationship(
    source?.source,
    flattenObj(source?.source),
    initialValue
  );

  if (isEmpty(records)) return 0;

  const valueFieldRecords =
    map(records, (record: any) => getFieldRelationValue(fieldId, record)) || [];

  switch (type) {
    case bindingType.SUM:
      return sumBy(valueFieldRecords);

    case bindingType.AVERAGE:
      return sumBy(valueFieldRecords) / records.length;

    case bindingType.MAX:
      return maxBy(valueFieldRecords);

    case bindingType.MIN:
      return minBy(valueFieldRecords);

    default:
      return ceil(minBy(valueFieldRecords) / maxBy(valueFieldRecords), 3);
  }
};

const getFilteredData = (
  records: Record<string, any>[],
  filterOptions: Record<string, any>[],
  currentRecord: Record<string, any>
) =>
  reduce(
    filterOptions,
    (acc, cur) => {
      const { comparator, comparison, comparison2 } = cur;

      const targetTableId = get(cur, 'comparison.tableId', undefined);
      const secondTargetTableId = get(cur, 'comparison2.tableId', undefined);

      const target = isString(comparison)
        ? comparison
        : getTextBinding(
            { source: comparison, type: 'binding' },
            targetTableId ? get(currentRecord, targetTableId) : currentRecord
          );

      const secondTarget = isString(comparison2)
        ? comparison2
        : getTextBinding(
            { source: comparison2, type: 'binding' },
            secondTargetTableId
              ? currentRecord[secondTargetTableId]
              : currentRecord
          );

      const filtered = acc.filter((item: Record<string, any>) => {
        const dataType: string = comparison?.dataType;
        let src: any;

        if (isString(cur.fieldId)) {
          src = includes(DATE_TIME_FIELD, dataType)
            ? moment(item[cur?.fieldId]).format(DATE_TIME_FORMAT[dataType])
            : item[cur?.fieldId];
        } else {
          src = getTextBinding(
            { source: cur?.fieldId?.source, type: 'binding' },
            item
          );
        }

        return compare({
          value: src,
          comparator,
          comparison: target,
          comparison2: secondTarget,
          dataType: includes(DATE_TIME_FIELD, dataType) ? dataType : src,
        });
      });

      return filtered;
    },
    records
  );

export const flatRecord = (listTable: IRecord[], childTableId: string) => {
  return get(listTable, childTableId, []).map((item: IRecord) => ({
    _id: item._id,
    ...item.record,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }));
};

export const getSourceSelector = (selectorType: string, source: any) => {
  return [
    BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR,
    BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR,
    BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR,
  ].includes(selectorType)
    ? source
    : source?.source;
};

export const getIdRelation = (
  initialValue: any,
  selector: any,
  source: any
) => {
  const isRecord = initialValue?.record;

  return isRecord
    ? get(initialValue, `record.${selector?.fieldId}`) ||
        get(
          initialValue[source?.tableId] ||
            initialValue[source?.source?.tableId],
          `record.${selector?.fieldId}`
        )
    : get(initialValue, selector?.fieldId) ||
        get(
          initialValue[source?.tableId] ||
            //comment for list in list action (Ticket 1804)
            // initialValue[source?.source?.tableId] ||
            get(initialValue[selector?.source?.tableId], 'record'),
          selector?.fieldId
        );
};

export const filterRelationFormIds = (
  listIdRelation: any[],
  recordOutput: any[]
) => {
  if (isEmpty(listIdRelation) || isEmpty(recordOutput)) return [];

  if (isString(listIdRelation)) {
    //relation 1N
    return [find(recordOutput, { _id: listIdRelation }) || {}];
  }

  return listIdRelation
    .map((value: any) => {
      return find(recordOutput, { _id: value }) || undefined;
    })
    .filter((item: any) => item);
};

export const getValueFileBinding = (
  source: any,
  initialValue: any,
  authProfile: any,
  listTable: any,
  type: string,
  currentRecord: any,
  collection: any
) => {
  //binding data of filepicker
  if (!type) {
    const fileUpLoad = getValueBindingInput(source);

    if (!fileUpLoad) return null;

    const fieldId = source?.fieldId;
    return fileUpLoad[fieldId];
  }

  const handleCurrentRecord = (argRecord: any) => {
    return handlePermissionField({
      response: argRecord,
      collection,
    });
  };

  //binding current record image and file
  if (type === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
    if (source?.source?.source.type === 'belongsToRelation') {
      return getValueFieldRelation(
        source.source,
        currentRecord,
        listTable,
        source?.fieldId
      );
    }

    const fileDatabase: { filename: string; url: string; size: number } = get(
      // currentRecord,
      handleCurrentRecord(currentRecord),
      source.source.fieldId,
      null
    );

    return get(fileDatabase, source.fieldId, '');
  }

  //binding logged user
  if (type === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
    if (source?.source?.source.type === 'belongsToRelation') {
      return getValueFieldRelation(
        source.source,
        initialValue,
        listTable,
        source?.fieldId
      );
    }

    const fileDatabase: { filename: string; url: string; size: number } = get(
      // authProfile,
      handleCurrentRecord(authProfile),
      source.source.fieldId,
      null
    );

    return get(fileDatabase, source.fieldId, '');
  }

  //binding current user
  if (type === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
    if (source?.source?.source?.type === 'belongsToRelation') {
      return getValueFieldRelation(
        source.source,
        initialValue,
        listTable,
        source?.fieldId
      );
    }

    const valueFile = handleCurrentRecord(
      initialValue?.record ? initialValue?.record : initialValue
    );

    const fileDatabase: { filename: string; url: string; size: number } =
      get(valueFile, source.source.fieldId, null) ||
      get(
        get(valueFile, `${source?.source?.source?.tableId}.record`, {}),
        source.source.fieldId,
        null
      );

    return get(fileDatabase, source.fieldId, '');
  }

  //relationship binding
  return getValueFieldRelation(
    source.source,
    initialValue,
    listTable,
    source?.fieldId
  );
};

export const getValueFieldRelation = (
  source: any,
  initialValue: any,
  listTable: any,
  fieldObjectId: string,
  isAction?: boolean | undefined,
  selectorType?: string | any,
  sourceFlatted?: Record<string, any>,
  currentId?: string
): any => {
  const state: any = store.getState();
  const formInputs = state.formInputs.values;
  const listDatabase = state.database.database;

  // filter list in list
  if (source?.dataType === 'list' && !isAction && source?.currentParent) {
    return getValueNestedList(source, listTable, selectorType, initialValue);
  }

  const flattenSource = flattenObj(source);

  // nested n-1 relationship
  if (!isRelation.includes(source?.type) && isManyRoundRelaton(source)) {
    return excuteRoundRelationship(source, flattenSource, initialValue);
  }

  if (!isRelation.includes(source?.type) && !isManyRoundRelaton(source)) {
    return excuteRoundRelationship(source, flattenSource, initialValue);
  }

  if (
    (isRelation.includes(source?.type) &&
      source?.source?.type === bindingType.BE_LONG_RELATIONSHIP) ||
    isManyRoundRelaton(source)
  ) {
    return excuteRoundRelationship(source, flattenSource, initialValue);
  }

  if (
    isRelation.includes(source?.type) &&
    manyRelation.includes(source?.source?.type)
  ) {
    const sourceFlat = source?.source;

    const currentValue =
      get(initialValue, sourceFlat.fieldId) ||
      get(initialValue, `record.${sourceFlat?.fieldId}`);

    // table relation
    const recordRelation = listTable[sourceFlat?.tableId];

    if (!currentValue) return [];

    const listIdRelation = map(currentValue, (item: string) => {
      const index = findIndex(recordRelation, { _id: item });

      if (index !== -1) {
        const recordObj = recordRelation[index];
        return get(recordObj, `record.${source?.fieldId}`);
      }
    })
      .filter((a) => a)
      .flat(1);

    if (!listIdRelation) return [];

    //current table
    const currentRecord = listTable[source?.tableId];

    return map(uniq(listIdRelation) || [], (item: string) => {
      const index = findIndex(currentRecord, { _id: item });

      if (index !== -1) return currentRecord[index];
    }).filter((a) => a);
  }

  if (manyRelation.includes(source?.type)) {
    const valueRelation = excuteRoundRelationship(
      source,
      flattenSource,
      initialValue
    );

    return valueRelation && valueRelation.length > 0
      ? valueRelation.map((item: IRecord) => {
          return isAction
            ? item?._id
            : {
                ...pick(item, ['createdAt', '_id', 'updatedAt', 'databaseId']),
                ...item?.record,
              };
        })
      : [];
  }

  if (
    isRelation.includes(source?.type) &&
    selectorType === BINDING_SELECTOR_TYPE.CREATED_OBJECT
  ) {
    const { tableId } = get(sourceFlatted, 0);
    return (
      get(initialValue, `${tableId}.record.${source.fieldId}`) ||
      get(initialValue, `record.${source.fieldId}`)
    );
  }

  if (
    !isRelation.includes(source?.type) &&
    ['file', 'image', 'boolean'].includes(source?.dataType)
  ) {
    const flatSource = source.source;

    const selectorType = get(flatSource, 'source.selector.type');

    if (!initialValue) return null;

    if (selectorType === 'SELECT_VALUE_SELECTOR') {
      const idRelation = get(initialValue, `record[${flatSource?.fieldId}]`);

      if (!idRelation) return null;

      const listObject = get(listTable, flatSource?.tableId);

      const objectValue = find(listObject, { _id: idRelation });

      if (!objectValue) return null;

      return get(objectValue, `record[${source?.fieldId}]`);
    }

    const selector = getSourceSelector(selectorType, flatSource);

    const idRelation = getIdRelation(initialValue, selector, flatSource);

    if (!idRelation) return null;

    const recordRelation = get(listTable, selector?.tableId, []);

    if (!recordRelation) return null;

    // const objectValueRelation = find(recordRelation, {
    //   _id: typeof idRelation === 'string' ? idRelation : fieldObjectId,
    // });

    const objectValueRelation = handlePermissionField({
      response: find(recordRelation, {
        _id: typeof idRelation === 'string' ? idRelation : fieldObjectId,
      }),
      collection: listDatabase.find(
        (item: any) => item.databaseUuid === source?.source?.tableId
      ),
    });

    const valueBind = get(objectValueRelation, `${source.fieldId}`);

    if (source?.dataType === 'boolean') {
      return valueBind || false;
    }

    if (!valueBind) return null;

    return valueBind[fieldObjectId] || valueBind;
  }

  if (isRelation.includes(source?.type) && source?.automatic) {
    return get(initialValue, `${source?.fieldId}`);
  }

  const selector = getSourceSelector(selectorType, source);

  //Fix me: break when initialValue is empty
  if (!initialValue) return null;

  // if (selectorType === 'SELECT_VALUE_SELECTOR' && isAction) {
  //   console.log('source', source);
  //   return get(formInputs, get(selector, 'selector.selectObjectId'));
  // }

  const idRecordRelation =
    selectorType === 'SELECT_VALUE_SELECTOR'
      ? get(formInputs, get(selector, 'selector.selectObjectId'))
      : getIdRelation(initialValue, selector, source);

  if (!idRecordRelation) return null;

  const recordRelation = get(listTable, selector?.tableId, []);

  if (!recordRelation) return null;

  const fieldRelation = source?.fieldId;

  // const objectValueRelation = find(recordRelation, {
  //   _id:
  //     typeof idRecordRelation === 'string' ? idRecordRelation : fieldObjectId,
  // });

  const objectValueRelation = handlePermissionField({
    response: find(recordRelation, {
      _id:
        typeof idRecordRelation === 'string' ? idRecordRelation : fieldObjectId,
    }),
    collection: listDatabase.find(
      (item: any) => item.databaseUuid === source?.source?.tableId
    ),
  });

  if (isAction && source.dataType === 'object') {
    if (selectorType === 'SELECT_VALUE_SELECTOR') {
      return get(objectValueRelation, `record.${source?.fieldId}`);
    }
    return objectValueRelation?._id;
  }

  const record = get(objectValueRelation, `record.${fieldRelation}`, null);

  const valueRelationShip = isNil(record)
    ? get(objectValueRelation, fieldRelation, null)
    : record;

  if (isNil(valueRelationShip)) return null;

  if (source?.dataType === 'image') {
    return isAction ? valueRelationShip : valueRelationShip?.url || null;
  }

  if (source?.dataType === 'boolean') return valueRelationShip;

  if (source?.dataType === 'file') {
    return valueRelationShip[fieldObjectId] || valueRelationShip || null;
  }

  if (['date', 'dateOnly'].includes(source?.dataType)) {
    if (['updatedAt', 'createdAt'].includes(source?.fieldId)) {
      return isAction
        ? valueRelationShip
        : moment(valueRelationShip).format(DATE_FORMAT.DATE_LABEL_FORMAT);
    }

    if (source?.dataType === 'dateOnly') {
      return moment(valueRelationShip).format(DATE_FORMAT.DATE_ONLY);
    } else {
      return moment(valueRelationShip).format(DATE_FORMAT.DATE_LABEL_FORMAT);
    }
  } else {
    return isArray(valueRelationShip) ? objectValueRelation : valueRelationShip;
  }
};

const handleRecordFilter = ({
  listRecord,
  source,
  currentRecord,
  initialValue,
}: any) => {
  let recordOutput: any = [];
  const filter = source?.options?.filter;
  const record = !isEmpty(currentRecord) ? currentRecord : initialValue;
  const config = isTwoDirectionArray(filter) ? filter : [[...filter]];

  if (source?.options && !isEmpty(source?.options?.filter)) {
    for (let index = 0; index < config.length; index++) {
      const option = config[index];

      recordOutput = [
        ...recordOutput,
        getFilteredData(listRecord, option, record),
      ];

      // if (recordOutput.length > 0) {
      //   break;
      // }
    }
  } else {
    recordOutput = listRecord;
  }

  const result = flatten(recordOutput);

  return unionBy(result, '_id');
};

export const getCountValue = (
  isRelationShipMany: boolean,
  listRecord: any[],
  initialValue: any,
  childFieldId: string,
  source: Record<string, any>,
  listTable: Record<string, any>,
  currentRecord: Record<string, any>
) => {
  let recordOutput: any[] = handleRecordFilter({
    listRecord,
    source,
    currentRecord,
    initialValue,
  });

  // count record current table
  if (!isRelationShipMany) {
    if (isEmpty(recordOutput)) return 0;

    const filters = source?.options?.filter;

    if (isEmpty(filters)) {
      return recordOutput.length;
    }

    const recordfilteroptions =
      recordOutput.filter((record) => {
        return some(filters, (option) => {
          if (isArray(option)) {
            return getAvailabilityMultiConditional(option, record);
          }
          return getAvailability(option, record);
        });
      }) || [];

    return recordfilteroptions.length;
  }
  // relationship field count
  else {
    const getValue = initialValue?.record
      ? get(initialValue, `record.${childFieldId}`, [])
      : get(initialValue, childFieldId, []);

    const valueRelationCount = recordOutput.filter((item: any) =>
      getValue.includes(item?._id)
    );

    const handleRecord = (params: Record<string, any>) => {
      const { table } = params;

      let result: Record<string, any>[] = [];

      forEach(table, (item) => {
        result = [...result, get(item, `record.${source?.source?.fieldId}`)];
      });

      return filter(flatten(result), (item) => !isNil(item)).length;
    };

    const getValueRelationCount = (flatSource: any, getTable: any[]) => {
      if (
        manyRelation.includes(flatSource?.type) &&
        bindingType.BE_LONG_RELATIONSHIP === flatSource?.source?.type
      ) {
        const idcurrent = get(initialValue, flatSource?.source?.fieldId);

        if (!idcurrent) return [];

        const currentRecord = find(getTable, { _id: idcurrent });

        const idRelation = get(currentRecord, `record.${flatSource?.fieldId}`);

        if (isEmpty(idRelation)) return [];

        return getRecordIdRelation(idRelation, getTable);
      }
    };

    const getSource: any = (obj: Record<string, any>) => {
      const isManyRoundRelation = isManyRoundRelaton(obj);

      if (isManyRoundRelation) {
        const records = excuteRoundRelationship(
          source?.source,
          flattenObj(source?.source),
          initialValue,
          source
        );

        return records ? records.length : 0;
      } else {
        if (obj?.source?.selector) {
          const getTable = get(listTable, obj.tableId);
          const getCurrentRecord = get(currentRecord, obj?.source?.tableId);
          const selectorType = get(obj, 'source.selector.type');
          //TODO:
          if (selectorType === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
            return handleRecord({
              table: filter(getTable, (item: Record<string, any>) =>
                get(getCurrentRecord, `record.${obj?.fieldId}`).includes(
                  item._id
                )
              ),
            });
          }
          if (selectorType === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
            if (obj?.type === 'belongsToRelation') {
              let table;
              if (get(initialValue, childFieldId)) {
                table = filter(getTable, (item: Record<string, any>) => {
                  return get(initialValue, childFieldId, []).includes(item._id);
                });
              } else {
                table = getValueRelationCount(source?.source, getTable);
              }
              return handleRecord({
                table,
              });
            } else {
              return get(initialValue, childFieldId)?.length;
            }
          }
          if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
            return handleRecord({
              table: filter(getTable, (item: Record<string, any>) =>
                get(initialValue, `record.${obj?.fieldId}`).includes(item._id)
              ),
            });
          }
        } else {
          return getSource(obj.source);
        }
      }
    };

    const result = isNil(source?.source?.source?.source)
      ? valueRelationCount.length
      : getSource(source);

    return result;
  }
};

export const getSumValue = (
  source: any,
  isRelationShipMany: boolean,
  listRecord: any[],
  initialValue: any,
  childFieldId: string,
  listTable: Record<string, any>,
  currentRecord: Record<string, any>
) => {
  // current table sum
  const fieldSumData = source?.fieldId;

  let recordOutput: any[] = handleRecordFilter({
    listRecord,
    source,
    currentRecord,
    initialValue,
  });

  if (!isRelationShipMany) {
    if (!some(recordOutput, fieldSumData)) return null;

    return sumBy(recordOutput, (record: any) => record[fieldSumData]);
  }

  const handleRecord = (params: Record<string, any>) => {
    const { table } = params;

    let cal: number = 0;

    if (!table) {
      cal = 0;
      return;
    }

    let newArr: Record<string, any>[] = [];

    forEach(table, (item) => {
      const valueArray = filter(listTable[source?.source?.tableId], (o: any) =>
        item.record[childFieldId].includes(o._id)
      );

      newArr.push(...valueArray);

      cal = newArr.reduce((sum, curr) => sum + curr.record[source.fieldId], 0);
    });

    return cal;
  };

  //TODO:
  const getSource: any = (obj: Record<string, any>) => {
    if (obj?.source?.selector) {
      const selectorType = get(obj, 'source.selector.type');
      const getTable = get(listTable, obj.tableId);

      if (selectorType === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
        const getRecord = get(
          currentRecord,
          `${obj?.source?.tableId}.record.${obj?.fieldId}`
        );

        return handleRecord({
          table: filter(getTable, (item: any) =>
            getRecord?.includes(item?._id)
          ),
        });
      }

      if (selectorType === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
        return handleRecord({
          table: filter(getTable, (item: any) =>
            get(initialValue, obj?.fieldId)?.includes(item?._id)
          ),
        });
      }

      if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
        return handleRecord({
          table: filter(getTable, (item: any) =>
            get(initialValue, `record.${obj?.fieldId}`, []).includes(item._id)
          ),
        });
      }
    } else {
      return getSource(obj.source);
    }
  };

  // relationship sum
  const recordBinding = initialValue?.record
    ? initialValue.record
    : initialValue;

  const isManyRoundRelation = isManyRoundRelaton(source);

  if (isManyRoundRelation) {
    return caculatorValueRoundRelation(
      source,
      recordBinding,
      fieldSumData,
      bindingType.SUM
    );
  }

  const listIdRelation = get(recordBinding, childFieldId);

  if (isEmpty(listIdRelation)) return getSource(source);

  const listRecordRelation = filterRelationFormIds(
    listIdRelation,
    recordOutput
  );

  if (isEmpty(listRecordRelation)) return 0;

  return sumBy(listRecordRelation, (record: any) => +record[fieldSumData]) || 0;
};

export const getAverageValue = (
  source: any,
  isRelationShipMany: boolean,
  listRecord: any[],
  initialValue: any,
  childFieldId: string,
  currentRecord: Record<string, any>
) => {
  // current table average
  const fieldAverage = source?.fieldId;

  let recordOutput: any[] = handleRecordFilter({
    listRecord,
    source,
    currentRecord,
    initialValue,
  });

  // if (source?.options) {
  //   const { filter } = source?.options;

  //   if (source?.options && !isEmpty(source?.options?.filter)) {
  //     recordOutput = getFilteredData(recordOutput, filter, currentRecord);
  //   }
  // }

  if (!isRelationShipMany) {
    if (!some(recordOutput, fieldAverage)) return null;

    return (
      sumBy(recordOutput, (record: any) => record[fieldAverage]) /
      recordOutput.length
    );
  }
  // relationship average
  const isManyRoundRelation = isManyRoundRelaton(source);

  if (isManyRoundRelation) {
    return caculatorValueRoundRelation(
      source,
      initialValue,
      fieldAverage,
      bindingType.AVERAGE
    );
  }

  const valueRecord = initialValue?.record
    ? initialValue?.record
    : initialValue;

  const listIdRelation = get(valueRecord, childFieldId);

  if (isEmpty(listIdRelation)) return 0;

  const listRecordRelation = filterRelationFormIds(
    listIdRelation,
    recordOutput
  );

  if (isEmpty(listRecordRelation)) return null;

  return (
    sumBy(listRecordRelation, (record: any) => +record[fieldAverage]) /
    listRecordRelation.length
  );
};

export const getValueMin = (
  source: any,
  isRelationShipMany: boolean,
  listRecord: any[],
  initialValue: any,
  childFieldId: string,
  currentRecord: Record<string, any>
) => {
  // current table min
  const fieldIdMin = source?.fieldId;

  let recordOutput: any[] = handleRecordFilter({
    listRecord,
    source,
    currentRecord,
    initialValue,
  });

  // if (source?.options) {
  //   const { filter } = source?.options;

  //   if (source?.options && !isEmpty(source?.options?.filter)) {
  //     recordOutput = getFilteredData(recordOutput, filter, currentRecord);
  //   }
  // }

  if (!isRelationShipMany) {
    if (!some(recordOutput, fieldIdMin)) return null;

    return minBy(recordOutput.map((item: any) => item[fieldIdMin])) || 0;
  }

  // relationship min
  const isManyRoundRelation = isManyRoundRelaton(source);

  if (isManyRoundRelation) {
    return caculatorValueRoundRelation(
      source,
      initialValue,
      fieldIdMin,
      bindingType.MIN
    );
  }
  const listIdRelation =
    get(initialValue, childFieldId) ||
    get(initialValue, `record.${childFieldId}`) ||
    get(
      get(initialValue, `${source?.source?.source.tableId}.record`, {}),
      childFieldId
    );

  if (isEmpty(listIdRelation)) return 0;

  const listRecordRelation = filterRelationFormIds(
    listIdRelation,
    recordOutput
  );

  return (
    minBy(listRecordRelation.map((item: any) => +item[fieldIdMin])) || null
  );
};

export const getValueMax = (
  source: any,
  isRelationShipMany: boolean,
  listRecord: any[],
  initialValue: any,
  childFieldId: string,
  currentRecord: Record<string, any>
) => {
  const fieldIdMax = source?.fieldId;

  let recordOutput: any[] = handleRecordFilter({
    listRecord,
    source,
    currentRecord,
    initialValue,
  });

  // current table max
  // if (source?.options) {
  //   const { filter } = source?.options;

  //   if (source?.options && !isEmpty(source?.options?.filter)) {
  //     recordOutput = getFilteredData(recordOutput, filter, currentRecord);
  //   }
  // }

  if (!isRelationShipMany) {
    if (!some(recordOutput, fieldIdMax)) return null;

    return maxBy(recordOutput.map((item: any) => item[fieldIdMax])) || 0;
  }
  // relationship max
  const isManyRoundRelation = isManyRoundRelaton(source);

  if (isManyRoundRelation) {
    return caculatorValueRoundRelation(
      source,
      initialValue,
      fieldIdMax,
      bindingType.MAX
    );
  }
  const listIdRelation =
    get(initialValue, childFieldId) ||
    get(initialValue, `record.${childFieldId}`) ||
    get(
      get(initialValue, `${source?.source?.source.tableId}.record`, {}),
      childFieldId
    );

  if (isEmpty(listIdRelation)) return null;

  const listRecordRelation = filterRelationFormIds(
    listIdRelation,
    recordOutput
  );

  return (
    maxBy(listRecordRelation.map((item: any) => +item[fieldIdMax])) || null
  );
};

export const getValueMinMax = (
  source: any,
  isRelationShipMany: boolean,
  listRecord: any[],
  initialValue: any,
  childFieldId: string,
  currentRecord: Record<string, any>
) => {
  const fieldIdMinMax = source?.fieldId;

  let recordOutput: any[] = handleRecordFilter({
    listRecord,
    source,
    currentRecord,
    initialValue,
  });

  // if (source?.options) {
  //   const { filter } = source?.options;

  //   if (source?.options && !isEmpty(source?.options?.filter)) {
  //     recordOutput = getFilteredData(recordOutput, filter, currentRecord);
  //   }
  // }

  // current table minMax
  if (!isRelationShipMany) {
    if (!some(recordOutput, fieldIdMinMax)) return null;

    const min: number =
      minBy(recordOutput.map((item: any) => +item[fieldIdMinMax])) || 0;

    const max: number =
      maxBy(recordOutput.map((item: any) => +item[fieldIdMinMax])) || 0;

    return ceil(min / max, 3);
  }

  // relation ship minMax
  const isManyRoundRelation = isManyRoundRelaton(source);

  if (isManyRoundRelation) {
    return caculatorValueRoundRelation(
      source,
      initialValue,
      fieldIdMinMax,
      bindingType.MIN_MAX
    );
  }
  const listIdRelation =
    get(initialValue, childFieldId) ||
    get(initialValue, `record.${childFieldId}`) ||
    get(
      get(initialValue, `${source?.source?.source.tableId}.record`, {}),
      childFieldId
    );

  if (isEmpty(listIdRelation)) return null;

  const listRecordRelation = filterRelationFormIds(
    listIdRelation,
    recordOutput
  );

  if (isEmpty(listRecordRelation)) return null;

  const min: number =
    minBy(listRecordRelation.map((item: any) => item[fieldIdMinMax])) || 0;

  const max: number =
    maxBy(listRecordRelation.map((item: any) => item[fieldIdMinMax])) || 0;

  return ceil(min / max, 3);
};

export const getValueBindingData = (
  selectorType: string,
  authProfile: any,
  source: any,
  listTable: any,
  initValBinding: any,
  isAction?: boolean | undefined
) => {
  const state: any = store.getState();

  const currentRecord = state.database.currentRecord;

  const tableId = source?.tableId;

  const records = get(listTable, tableId, []);

  const recordCreated = state.database.recordCreated;

  switch (selectorType) {
    case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
      if (isAction) {
        return authProfile?.userId || null;
      } else {
        return authProfile;
      }

    case BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR:
      const objectId = source?.selector?.selectObjectId;
      return getValueBindingInput({ ...source, objectId });

    case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
      return currentRecord?._id || get(currentRecord[source?.tableId], '_id');

    case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
      if (!initValBinding) return null;

      return initValBinding?._id || get(initValBinding[source?.tableId], '_id');

    case BINDING_SELECTOR_TYPE.CREATED_OBJECT:
      const record = get(recordCreated, source?.tableId);

      if (!record) return;

      return get(record, '_id');

    default:
      if (!records) return [];

      const valueData = isEmpty(initValBinding)
        ? records
        : map(records, (item: any) => {
            if (
              item?._id === initValBinding?._id ||
              item?._id === get(initValBinding[source?.tableId], '_id')
            ) {
              return isAction ? item?._id : item;
            }
          }).filter((a: any) => a);

      return valueData || [];
  }
};

export const getSelectValueSelector = (params: Record<string, any>) => {
  const { source, sourceFlatted, listTable, objectBinding } = params;

  const { selector, tableId } = get(sourceFlatted, '0');
  const fieldId = get(source, 'source.fieldId');
  const tableSource = get(listTable, tableId);

  const objectId = selector.selectObjectId;

  const selectedItem = find(tableSource, {
    _id: get(objectBinding, objectId),
  });

  let getObj: any = {};

  const getSource = (val: Record<string, any>) => {
    if (isEmpty(val) || isNil(val)) return;

    if (val?.source?.type === 'belongsToRelation') {
      getObj = val?.source;
      return;
    } else {
      getSource(val?.source);
    }
  };

  getSource(source);

  if (getObj?.type === 'belongsToRelation') {
    const fieldIdRelation = get(getObj, 'fieldId');
    const tableRelation = get(listTable, getObj?.tableId);
    const getFieldId = get(selectedItem, `record.${fieldIdRelation}`);

    const selectedItemRelation = find(tableRelation, {
      _id: getFieldId,
    });

    return get(selectedItemRelation, `record.${fieldId}`);
  }

  return get(selectedItem, `record.${fieldId}`);
};

export const getCurrentRecordDataSource = (
  listTable: any,
  targetedId: any,
  tableUuid: any
) => {
  if (!listTable[tableUuid]) return null;

  return targetedId ? find(listTable[tableUuid], { _id: targetedId }) : null;
};

export const getInitValueBindingRecord = (
  source: any,
  selectorType: string,
  initialValue: any,
  tableId: string
) => {
  const state: any = store.getState();

  const listTable = state.database.dataSource;

  const formInputs = state.formInputs.values;

  const currentRecord = state.database.currentRecord;

  const authProfile = state.auth.profile;

  switch (selectorType) {
    case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
      const databaseId = authProfile?.databaseId || tableId;
      const recordId = authProfile?.userId;

      const records = listTable[databaseId] || [];

      return find(records, { _id: recordId });

    case BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR:
      if (isRelation.includes(source?.source?.type)) {
        const flatSource = source?.source;
        const objectId = get(flatSource, 'source.selector.selectObjectId');
        const idRelation = formInputs[objectId];
        const listRecord = get(listTable, flatSource?.source?.tableId);

        if (!idRelation) return null;

        return find(listRecord, { _id: idRelation }) || null;
      }

      return null;

    case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
      const tableUuid = !isEmpty(
        currentRecord[get(source, 'source.source.tableId')]
      )
        ? get(source, 'source.source.tableId')
        : get(source, 'source.tableId');

      return isEmpty(currentRecord?._id)
        ? currentRecord[tableUuid]
        : currentRecord;

    default:
      // list item selector
      if (isEmpty(initialValue)) return {};
      if (!initialValue?.listObjectId) return initialValue;

      const flatSource = flattenObj(source);
      const firstSource: any = first(flatSource);
      const listObjectId = get(firstSource, 'selector.listObjectId', '');

      return initialValue?.listObjectId.includes(listObjectId)
        ? initialValue
        : initialValue?.parentRecord;
  }
};

export const parsedDateBinding = (
  date: any,
  handleBindingField: any,
  defaultValue: any = null
): string => {
  const format = 'YYYY-MM-DD';
  return parsedItemDateBinding({
    value: handleBindingField(date, []),
    format,
    defaultValue,
  });
};

export const parsedItemDateBinding = ({
  value,
  format = 'YYYY-MM-DD HH:mm:ss',
  defaultValue,
}: {
  value: any;
  format?: string;
  defaultValue?: string;
}): string => {
  const dateNumber = Number(value);
  if (isNaN(dateNumber)) {
    const momentDate = moment(value);
    if (!momentDate.isValid()) {
      return moment(defaultValue).format(format);
    }
    return momentDate.format(format);
  }

  let time = dateNumber * ONE_DAY + 1; // add 1 second
  return moment(new Date(time)).format(format);
};

export const flatSource = (source: any, result: any = []): any => {
  if (!source) return [];

  const state: any = store.getState();
  const { database: tablesMap } = databaseSelector(state);

  const { tableId, fieldId, type } = source;
  if (source.source) {
    if (type === 'count') {
      return flatSource(
        source.source,
        [{ fieldName: 'length' }].concat(result)
      );
    }

    if (!tableId) {
      return flatSource(source.source, [{ fieldName: fieldId }].concat(result));
    }

    const table: any = findTable(tablesMap, tableId);

    let field;

    const isRelationField = Object.values(RELATIONSHIP_TYPES).includes(type);

    if (isRelationField) {
      field =
        find(table?.fields, { db: { relationshipId: fieldId } }) ||
        find(table?.fields, { fid: fieldId });
    } else {
      field = find(table?.fields, { fid: fieldId });
    }

    return flatSource(
      source.source,
      [
        assign({}, source, {
          fieldName: field?.name,
        }),
      ].concat(result)
    );
  }
  return [source].concat(result);
};

export const getDataBindingRecord = (initSource: Record<string, any>) => {
  const { fieldId, source } = initSource;

  return {
    fieldId,
    tableId: source.tableId,
  };
};

export const paginate = (
  items: any[] = [],
  { maximum, sort, filterOptions = [], params, databaseUuid }: any
): any[] => {
  const direction = get(sort, 'direction');
  const fieldId = get(sort, 'fieldId');
  const sortType = get(sort, 'fieldType');
  const isSortRelation = get(sort, 'type.type');

  if (maximum === 0) return [];

  const field = getFieldTable(databaseUuid, fieldId) || {};

  const state: any = store.getState();
  const { database: tablesMap } = databaseSelector(state);
  const table: any = findTable(tablesMap, databaseUuid);

  if (direction && fieldId) {
    items = orderBy(
      items,
      [
        (item) => {
          let value = get(item, fieldId);
          if (table && table.isThirdParty) {
            if (field?.key) {
              value = get(item, field?.key?.replace('[0].', ''));
            } else {
              value = item;
            }
          }

          if (sortType === 'boolean') return isNil(value) ? false : value;
          if (isSortRelation === 'hasMany') return value ? value.length : 0;
          return isNumber(value) ? value : lowerCase(value);
        },
      ],
      [direction]
    );
  }

  const data = items.slice(0, maximum || items.length);

  const handleFilterOption = (data: any, record: any) => {
    const { fieldId, comparator, comparison, comparison2 } = data;

    if (!fieldId) return true;

    const value = getValuePaginate(
      record,
      params,
      fieldId,
      databaseUuid,
      table
    );

    const dataType = getDataTypeFilter(fieldId, value, databaseUuid);

    const comparisonVal = !isEmpty(comparison)
      ? getComparisonValue(comparison, record, {})
      : '';

    const comparison2Val = !isEmpty(comparison2)
      ? getComparisonValue(comparison2, record, {})
      : '';

    return compare({
      value: value,
      comparator,
      comparison: comparisonVal,
      comparison2: comparison2Val,
      dataType,
    });
  };

  const isTwoDirectionArray = (array: any[]) =>
    every(array, (item: any) => {
      return isArray(item);
    });

  const handleFilterOptions = (record: any) => {
    let options = isTwoDirectionArray(filterOptions)
      ? filterOptions
      : [[...filterOptions]];

    return some(options, (option) => {
      if (isArray(option)) {
        return every(option, (data: FilterItem) => {
          return handleFilterOption(data, record);
        });
      }

      return handleFilterOption(option, record);
    });
  };

  if (filterOptions && filterOptions.length > 0) {
    const result = filter(items, (record) => handleFilterOptions(record));

    if (maximum) {
      return result.slice(0, maximum || items.length);
    } else {
      return result;
    }
  }

  return data;
};
