import { IMap } from '@common/types/element';
import {
  GoogleMap,
  InfoBox,
  Marker as MakerWeb,
  MarkerClusterer,
} from '@react-google-maps/api';
import { isEqual, isUndefined, pick } from 'lodash';
import queryString from 'query-string';
import React, { memo, useMemo } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import {
  defaultImageMarker,
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  paths,
} from './constans';
import { mapImgInCanvas } from './constans/images';
import currentLocationIcon from './constans/images/currentLocation.png';
import roadmap from './constans/images/roadmap.png';
import selectedMarkerIcon from './constans/images/selectedMarker.png';
import createStyles from './constans/styles';
import { stringToJson } from './functions';
import useMap from './hook/index.web';

declare const window: any;
const Map: React.FC<IMap> = (props) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const mapType = props?.attributes?.style?.mapStyle || 'roadmap';

  /////canvas
  const mapImage = mapImgInCanvas[mapType];
  if (!target)
    return (
      <Image
        style={{
          width: props.width,
          height: props.height,
        }}
        source={mapImage || roadmap}
      />
    );

  /////preview
  const {
    width,
    height,
    currentLocation,
    apiKey,
    showCurrentUserLocation,
    customMapStyle,
    mapState,
    setMapState,
    markers,
    centerLocation,
    imgCluster,
    handleClick,
    handlePress,
    clusterRef,
  } = useMap(props);

  const styles = createStyles(props);

  const googleMapMemo = useMemo(() => {
    const markerRender = [
      ...markers,
      //default marker when is markers.length <  0
      ...(markers.length <= 0 && !showCurrentUserLocation
        ? [
            {
              ...DEFAULT_CENTER,
              scaledSize: window?.google
                ? new window.google.maps.Size(35, 35)
                : undefined,
            },
          ]
        : []),
      //current location is last item
      ...(showCurrentUserLocation && !isUndefined(currentLocation)
        ? [
            {
              ...currentLocation,
              imageMarker: currentLocationIcon,
              scaledSize: window?.google
                ? new window.google.maps.Size(30, 30)
                : undefined,
              origin: window.google
                ? new window.google.maps.Point(0, 0)
                : undefined,
              anchor: window.google
                ? new window.google.maps.Point(15, 15)
                : undefined,
              isCurrentLocation: true,
            },
          ]
        : []),
    ];

    return (
      <GoogleMap
        mapContainerStyle={{
          height,
          width,
        }}
        options={{
          zoom: DEFAULT_ZOOM,
          center: centerLocation,
          panControl: true,
          disableDefaultUI: true,
          zoomControl: true,
          mapTypeId: mapType,
          styles: stringToJson(customMapStyle),
        }}
        onLoad={(map) => {
          setMapState(map);
        }}
      >
        {/* current user location */}
        {!isUndefined(currentLocation) && showCurrentUserLocation && mapState && (
          <>
            <TouchableOpacity
              style={styles.currentLocationButton}
              onPress={handlePress}
            >
              <Image
                style={{ width: '28px', height: '28px' }}
                source={{
                  uri: 'https://cdn2.iconfinder.com/data/icons/boxicons-regular-vol-2/24/bx-current-location-256.png',
                }}
              />
            </TouchableOpacity>
          </>
        )}

        <MarkerClusterer
          options={imgCluster}
          onLoad={(clusterState: any) => {
            clusterRef.current = clusterState;
          }}
        >
          {(clusterer) => {
            return (
              <>
                {markerRender.map((marker: any, index) => {
                  const icon = {
                    url: marker?.imageMarker || defaultImageMarker, // url
                    scaledSize: marker?.scaledSize
                      ? marker?.scaledSize
                      : new google.maps.Size(35, 35), // scaled size
                    ...(marker?.origin ? { origin: marker.origin } : {}),
                    ...(marker?.anchor ? { anchor: marker.anchor } : {}),
                    isCurrentLocation: marker?.isCurrentLocation || false,
                  };

                  return (
                    <React.Fragment key={index}>
                      {marker.center ? (
                        <MakerWeb
                          clusterer={clusterer}
                          position={marker}
                          onClick={handleClick(marker)}
                          icon={selectedMarkerIcon}
                        />
                      ) : (
                        <MakerWeb
                          clusterer={clusterer}
                          position={marker}
                          onClick={handleClick(marker)}
                          icon={icon}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            );
          }}
        </MarkerClusterer>
      </GoogleMap>
    );
  }, [
    centerLocation,
    mapType,
    customMapStyle,
    markers,
    mapState,
    currentLocation,
    showCurrentUserLocation,
    handlePress,
  ]);

  const loadScriptMemo = useMemo(
    () =>
      props.googleMapisloaded || window?.google?.maps ? (
        googleMapMemo
      ) : (
        <>Loading...</>
      ),
    [
      googleMapMemo,
      props.googleMapisloaded,
      centerLocation,
      showCurrentUserLocation,
    ]
  );

  return (
    <View style={styles.container}>
      {!apiKey ? (
        <View style={styles.map}>
          <View style={styles.emptyKeyWrap}>
            <Text style={styles.emptyKeyContent}>API Key is not set.....</Text>
          </View>
        </View>
      ) : (
        loadScriptMemo
      )}
    </View>
  );
};

export default memo(Map, (prev, next) => {
  return isEqual(pick(prev, paths), pick(next, paths));
});
