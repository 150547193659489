import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { get } from 'lodash';
import React from 'react';
import StripeForm from './form';

const StripePayment: React.FC<any> = (props) => {
  console.log(props);
  const {
    attributes: { testMode },
    accountConnectId,
    publicKey,
  } = props;

  const stripeAccount = accountConnectId
    ? { stripeAccount: accountConnectId }
    : {};

  const getTestMode = get(testMode, 'publishableKey');

  const handleTestMode = (key: string) => {
    return testMode?.enabled && getTestMode
      ? Array.isArray(getTestMode)
        ? getTestMode[0]
        : getTestMode
      : key;
  };

  let stripePromise: any = loadStripe(handleTestMode(publicKey), stripeAccount);

  // useEffect(() => {
  //   const initStripe = async () => {
  //     stripePromise = await loadStripe(
  //       handleTestMode(
  //         'pk_test_51HxPplEmoxuBHlV1pa9LsDHPCCOrZ8v5bZ7MZXAHqLluP1i45llgUHdCydEfLLlyB5JnZQrySHVMF4aaEA0leH4s00FPWCEB2n'
  //       ),
  //       stripeAccount
  //     );
  //   };

  //   initStripe();
  //   console.log('stripePromise', stripePromise);
  // }, [props.attributes.testMode]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: props.locale,
        appearance: { theme: 'stripe' },
      }}
    >
      <StripeForm {...props} />
    </Elements>
  );
};

export default React.memo(StripePayment);
