import api from '@common/configs/api';
import { setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { IAction } from '@common/types/';
import { getFieldValue } from '@common/utils/binding';
import { getUserLoginRecord } from '@common/utils/handleActions/func/func';
import history from '@common/routes/history';
import { ActionResponse } from '../excuteAction';

const parseFieldValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.join();
  }
  return value;
};

export const livestreamRequestRevenues = async (
  action: IAction
): Promise<ActionResponse> => {
  const dispatch = store.dispatch;
  try {
    const appId = history.location.pathname.split('/')[2];
    const userLoginRecord: { userId: string } = getUserLoginRecord();
    const options: Record<string, any> = action.options;

    const body: Record<string, any> = {
      type: getFieldValue(options.type),
      account_holder_name: getFieldValue(options.account_holder_name),
      account_number: getFieldValue(options.account_number),
      bank_name: getFieldValue(options.bank_name),
      branch: getFieldValue(options.branch),
      email: getFieldValue(options.email),
      full_name: getFieldValue(options.full_name),
      phone_number: getFieldValue(options.phone_number),
      fee: getFieldValue(options.fee),
      userId: userLoginRecord.userId,
    };

    if (!body.userId) {
      dispatch(
        setError({
          message: 'User not login!',
        })
      );
      return {
        status: 'FAILED',
      };
    }
    Object.keys(body).forEach((key) => {
      body[key] = parseFieldValue(body[key]);
    });

    const response = await api.post(`/app-income?appid=${appId}`, body);

    if (response) {
      return {
        status: 'SUCCEED',
        message: 'Send request revenues success!',
      };
    } else {
      return {
        status: 'FAILED',
      };
    }
  } catch (error: any) {
    if (error?.response?.data) {
      error = error.response.data;
    }
    dispatch(
      setError({
        ...error,
      })
    );

    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};
