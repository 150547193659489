import store from '@common/redux/store';
import { IAction } from '@common/types/';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { getFieldValue } from '@common/utils/binding';
import { setListRecordValue } from '@common/redux/slice/listRecords';
import {
  getFieldId,
  searchRecord,
} from '@common/utils/handleActions/func/func';

const searchInDB: (
  action: IAction,
  currentRecord?: any,
  itemIndex?: number
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  itemIndex?: number
) => {
  const dispatch = store.dispatch;

  const keySearch = getFieldValue(
    action.options.keySearch,
    currentRecord,
    undefined,
    itemIndex,
    undefined
  );

  const fieldSearch = getFieldId(action.options.fieldSearch);

  const listId = action.options.listId;

  if (listId && fieldSearch) {
    const state: any = store.getState();
    const records = searchRecord(state, listId, fieldSearch, keySearch);
    dispatch(
      setListRecordValue({
        id: listId,
        value: records,
      })
    );
  }

  return {
    status: 'SUCCEED',
  };
};

export default searchInDB;
