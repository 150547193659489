import api from '@common/configs/api';
import history from '@common/routes/history';
import { IAction, IRecord } from '@common/types';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { getFieldValue } from '@common/utils/binding';
import { getUserLoginRecord } from '@common/utils/handleActions/func/func';
import store from '@common/redux/store';
import { setError } from '@common/redux/slice/formInput';
import { addItemDataSource } from '@common/redux/slice/database';

const sendGift: (
  action: IAction,
  currentRecord?: any
) => Promise<ActionResponse> = async (action: IAction, currentRecord?: any) => {
  const dispatch = store.dispatch;

  const appId = history.location.pathname.split('/')[2];
  const userSendId = getFieldValue(action.options?.userSendId, currentRecord);
  const userReceiveId = getFieldValue(
    action.options?.userReceiveId,
    currentRecord
  );
  const giftId = getFieldValue(action.options?.giftId, currentRecord);
  const channelId = getFieldValue(action.options?.channelId, currentRecord);

  const objField = action.options?.coinId;

  let coinFieldId = '';

  objField?.forEach((data) => {
    if (data && typeof data === 'object') {
      coinFieldId = data?.source?.fieldId;
      return;
    }
  });

  const userLoginRecord: IRecord | any = getUserLoginRecord();

  try {
    const response = await api({
      method: 'post',
      url: `iaps/send-gift`,
      data: {
        appid: appId,
        userSendId,
        userReceiveId,
        coinFieldId,
        giftId,
        accessToken: userLoginRecord.accessToken,
        room: channelId,
      },
    });

    if (response && response.data && response.data.giftData?.giftHistory) {
      dispatch(addItemDataSource(response.data.giftData?.giftHistory));
      return {
        status: 'SUCCEED',
        message: 'Send gift success !',
      };
    } else {
      return {
        status: 'FAILED',
        message:
          'ギフトの購入ができませんでした。電波状況が良好なエリアで再度実行してください。',
      };
    }
  } catch (error: any) {
    if (!error?.response)
      return {
        status: 'FAILED',
        message:
          'ギフトの購入ができませんでした。電波状況が良好なエリアで再度実行してください。',
      };

    if (error?.response?.data?.errors?.errors?.errorCode === 'COIN_NOT_ENOUGH')
      return {
        status: 'FAILED',
        message:
          '現在、持っているコインの数がギフトのコインの数より少ないため、ギフトの送信に失敗しました。',
      };

    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default sendGift;
