import React, { useCallback, useMemo } from 'react';
import {
  ActivityIndicator,
  Text,
  TouchableHighlight,
  View,
  Image,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Icon from '../AppBar/Icon';

interface Props {
  text: string;
  handlePress?: () => void;
  styles: {
    container: Record<string, any>;
    textWrapper: Record<string, any>;
    text: Record<string, any>;
    imageWrapper: Record<string, any>;
    iconImage: Record<string, any>;
  };
  uppercase?: boolean;
  linerColors?: string;
  loading?: boolean;
  icons: {
    enabled: boolean;
    iconEnableOutline?: boolean;
    icon?: string;
  };
  leadImage: {
    enabled: boolean;
    imageUrl?: string | Array<string>;
  };
  tailImage: {
    enabled: boolean;
    imageUrl?: string | Array<string>;
  };
  width: number;
  height: number;
}

const getTextTorus = (text: string, uppercase?: boolean) => {
  if (uppercase) {
    text = text.toUpperCase();
  }
  return text;
};

const getValueString = (value: any) => {
  return Array.isArray(value) ? value.join('') : value;
};

export const ButtonTorus: React.FC<Props> = (props) => {
  const {
    text,
    handlePress,
    styles,
    uppercase,
    linerColors = '',
    loading,
    leadImage,
    tailImage,
    icons,
    width,
    height,
  } = props;

  const imageSize = useMemo(
    () => Math.min(width, height) - 10,
    [width, height]
  );

  const renderIcon = useCallback(() => {
    if (!icons?.enabled) {
      return <React.Fragment />;
    }
    return <Icon name={icons.icon!} style={styles.text} />;
  }, [icons]);

  const renderLeadImage = useCallback(() => {
    if (!leadImage?.enabled || !leadImage?.imageUrl?.length) {
      return <React.Fragment />;
    }
    const url = getValueString(leadImage.imageUrl);
    return (
      <Image
        source={{
          width: imageSize,
          height: imageSize,
          uri: url,
        }}
      />
    );
  }, [leadImage]);

  const renderTailImage = useCallback(() => {
    if (!tailImage?.enabled || !tailImage?.imageUrl?.length) {
      return <React.Fragment />;
    }
    const url = getValueString(tailImage.imageUrl);
    return (
      <Image
        source={{
          width: imageSize,
          height: imageSize,
          uri: url,
        }}
      />
    );
  }, [tailImage]);

  return (
    <TouchableHighlight
      onPress={() => {
        if (loading) return;
        handlePress && handlePress();
      }}
      underlayColor={'transparent'}
    >
      <LinearGradient
        colors={`${linerColors}`.split(' ')}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={styles.container}
      >
        {!loading && renderIcon()}
        {!loading && renderLeadImage()}
        <View style={styles.textWrapper}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Text style={styles.text}>{getTextTorus(text, uppercase)}</Text>
          )}
        </View>
        {!loading && renderTailImage()}
      </LinearGradient>
    </TouchableHighlight>
  );
};
