import { setCurrentRecord } from '@common/redux/slice/database';
import {
  setCurrentRerordExternal,
  setIndexExternal,
  setRerordExternal,
} from '@common/redux/slice/externalCollection';
import { resetAllValue, setDefaultValue } from '@common/redux/slice/formInput';
import { setPastLocations } from '@common/redux/slice/locationHistory';
import store from '@common/redux/store';
import history from '@common/routes/history';
import { IAction } from '@common/types/';
import { MetaData } from '@common/types/action';
import { clone, forEach, isArray, isEmpty, isNil } from 'lodash';
import qs from 'query-string';
import { ActionResponse } from '../excuteAction';

function updatePastLocations(location: any, action: any) {
  const state: any = store.getState();
  const dispatch = store.dispatch;

  let pastLocations = clone(state.locationHistory.pastLocations);

  const { target } = qs.parse(location.search);
  const pageInfo = state.pageInfo?.pages?.find(
    (o: any) => o.screenUuid == target
  );
  switch (action) {
    case 'PUSH':
      // first location when app loads and when pushing onto history
      pastLocations.push(location);

      break;
    case 'REPLACE':
      // only when using history.replace
      pastLocations[pastLocations.length - 1] = location;

      break;
    case 'POP': {
      // happens when using the back button, or forward button
      pastLocations.pop();
      // location according to pastLocations
      const appLocation: any = pastLocations[pastLocations.length - 1];
      if (!(appLocation && appLocation.key === location.key)) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations = [location];
      }

      break;
    }
    default:
  }

  dispatch(setPastLocations(pastLocations));
}
history.listen(updatePastLocations);

function isPreviousLocationWithinApp(): boolean {
  const state: any = store.getState();

  const pastLocations = state.locationHistory.pastLocations;
  return pastLocations.length > 0;
}

const navigate = (action: IAction, dispatch: any) => {
  const search = history.location.search;
  const query = qs.parse(search);
  const target = action.options?.target;
  const state: any = store.getState();
  const pageInfo = state.pageInfo?.pages?.find(
    (o: any) => o.screenUuid == target
  );

  // const previousCurrent = state.database.previousCurrent;

  let targetModal = pageInfo?.isModal ? pageInfo.screenUuid : '';

  const transition = action?.options?.transition || 'TRANSITION_NONE';

  if (target === 'navigation.back') {
    if (isPreviousLocationWithinApp()) {
      history.goBack();
      // dispatch(overwriteCurrent(previousCurrent));
    }
  } else {
    // const previousCurrent = state.database.currentRecord;

    // dispatch(setPreviousCurrent(previousCurrent));

    if (
      targetModal &&
      !(
        transition === 'TRANSITION_SLIDE_DOWN' ||
        transition === 'TRANSITION_SLIDE_RIGHT' ||
        transition === 'TRANSITION_SLIDE_LEFT' ||
        transition === 'TRANSITION_SLIDE_UP'
      )
    ) {
      history.push({
        search: qs.stringify({
          ...query,
          ...{ targetModal: targetModal, modalTransition: transition },
        }),
      });
    } else {
      history.push({
        search: qs.stringify({
          ...query,
          ...{
            target: target,
            targetModal: targetModal,
            transition: transition,
          },
        }),
      });
    }
  }
};

const executeNavigate: (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  externalIndex?: string,
  externalUpdateId?: string,
  externalRecord?: any
) => Promise<ActionResponse> = async (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  externalIndex?: string,
  externalUpdateId?: string,
  externalRecord?: any
) => {
  const { hasAuthAction, lastLocation } = metadata;
  const { location } = history;

  const { target } = qs.parse(location.search);

  // if (lastLocation !== target)
  //   return {
  //     status: 'SUCCEED',
  //   };
  // add loading

  try {
    const state: any = store.getState();
    const pageInfo = state.pageInfo?.pages?.find(
      (o: any) => o.screenUuid == target
    );
    if (pageInfo?.name !== 'StatePage')
      await new Promise((resolve) => setTimeout(resolve, 1));

    const recordCreated = state.database.recordCreated;
    const dispatch = store.dispatch;
    const profile = state.auth.profile;

    if (externalIndex) {
      dispatch(
        setIndexExternal({
          index: externalIndex,
        })
      );
    }

    if (externalUpdateId) {
      dispatch(
        setCurrentRerordExternal({
          id: externalUpdateId,
        })
      );
    }

    if (!hasAuthAction) {
      navigate(action, dispatch);
    } else if (!isEmpty(profile)) {
      navigate(action, dispatch);
    }

    //Fix bug current bind data #2349
    const currentRecordStore = state.database.currentRecord;
    currentRecord =
      isEmpty(currentRecord) && !isEmpty(currentRecordStore)
        ? currentRecordStore
        : currentRecord;

    if (currentRecord) {
      if (isArray(currentRecord)) {
        forEach(currentRecord, (item: any) => {
          if (!isNil(item) || !isEmpty(item)) {
            dispatch(setCurrentRecord(item));
          }
        });
      } else {
        if (currentRecord && currentRecord?.record) {
          dispatch(setDefaultValue(currentRecord.record));
          dispatch(setCurrentRecord(currentRecord));
        } else {
          dispatch(setDefaultValue(profile));
        }
      }
    }
    if (
      !isNil(recordCreated) &&
      !isEmpty(recordCreated) &&
      isEmpty(currentRecord)
    ) {
      forEach(recordCreated, (item: any) => {
        dispatch(setCurrentRecord(item));
      });
    }

    const externalRecords = state.externalCollection.externalRecord;

    if (!isEmpty(externalRecord?.externalRecord)) {
      let listExternalRecord = [];
      const oldRecords = [...externalRecords];

      if (isArray(oldRecords)) {
        const index = oldRecords.findIndex(
          (val: any) => val.databaseUuid === externalRecord?.databaseUuid
        );

        if (index !== -1) {
          oldRecords[index] = externalRecord;
        } else {
          oldRecords.push(externalRecord);
        }
        listExternalRecord = oldRecords;
      } else {
        listExternalRecord.push(externalRecord);
      }

      dispatch(
        setRerordExternal({
          record: listExternalRecord,
        })
      );
    }

    if (externalIndex) {
      dispatch(
        setIndexExternal({
          index: externalIndex,
        })
      );
    }

    if (externalUpdateId) {
      dispatch(
        setCurrentRerordExternal({
          id: externalUpdateId,
        })
      );
    }

    return {
      status: 'SUCCEED',
    };
  } catch (err) {
    console.log('err', err);

    return {
      status: 'FAILED',
    };
  }
};

export default executeNavigate;
