import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'borderRadius',
        'width',
        'height',
        'zIndex',
        'backgroundColor',
      ]),
      zIndex: attributes.zIndex,
    },

    screenContainer: {
      width: '100%',
      height: '100%',
    },
    listProducts: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 58,
      alignItems: 'center',
      paddingEnd: 15,
      paddingStart: 15,
      backgroundColor: '#fff',
    },
    buttonBuyProduct: {
      backgroundColor: '#a8e349',
      padding: 8,
      borderRadius: 10,
      minWidth: 60,
      alignItems: 'center',
    },
  });
};

export default createStyles;
