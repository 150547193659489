import React, { FC, useEffect, useState } from 'react';
import {
  TouchableOpacity,
  Text,
  View,
  Image,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { ImagePicker } from '@common/utils/upload';
import createStyles from './style';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { excuteBinding } from '@common/hooks/useBinding';

export type imageObj = {
  url: string;
  filename: string;
  size: number;
  id: string;
};

const ImageUploadWeb: FC<any> = ({
  attributes,
  index,
  maxImage,
  defaultImage,
}) => {
  const [isDefault, SetIsDefault] = useState(false);

  const listDataBinding: any = [];

  attributes?.records?.map((item: any, index: number) => {
    const imageObj: imageObj = {
      url: '',
      filename: '',
      size: 0,
      id: '',
    };
    const imageUrl = excuteBinding(
      attributes?.image || '',
      item,
      index,
      attributes?.id
    );
    imageObj.url = imageUrl;
    listDataBinding.push(imageObj);
  });

  useEffect(() => {
    const result = listDataBinding.some((element: any) => {
      if (element.url === '') {
        return true;
      }
    });

    if (!result && !isDefault) {
      changeInput(listDataBinding);
      SetIsDefault(true);
    }
  }, [listDataBinding]);

  const uploadBg = require('./image/image-upload.png').default;

  const [error, setError] = useState('');

  const styles = createStyles(attributes);

  const { valueInputs, changeInput }: UseValueInputProps =
    useValueInputs(attributes);

  const [pending, setPending] = useState(false);

  const handlePress = () => {
    if (Platform.OS !== 'web') return;
    setPending(true);
    error && setError('');

    ImagePicker.showImagePicker(
      { quality: 0.7, setPending, imageUpload: true, setError, attributes },
      (response: any) => {
        if (response) {
          let valueInputsClone = [];
          if (Array.isArray(valueInputs)) {
            valueInputsClone = valueInputs.slice();
          } else {
            valueInputsClone = Array.from({ length: maxImage }, (_) => null);
          }
          valueInputsClone[index] = response;
          changeInput(valueInputsClone);
          setPending(false);
        }
      }
    );
  };

  const Loading = () => {
    if (!pending) return null;
    return (
      <View>
        <ActivityIndicator />
        <Text style={{ color: 'white' }}>Uploading...</Text>
      </View>
    );
  };

  if (valueInputs[index]) {
    return (
      <React.Fragment>
        <View style={styles.innerWrapper}>
          <TouchableOpacity onPress={handlePress} style={{ flex: 1 }}>
            <View style={styles.preview}>
              <Image
                style={[styles.previewImage, pending ? styles.faded : {}]}
                source={{ uri: valueInputs[index].url }}
                resizeMode="contain"
              />
              <Loading />
            </View>
          </TouchableOpacity>
        </View>
        {valueInputs[index] ? null : (
          <TouchableOpacity onPress={handlePress} style={styles.selectAnother}>
            <Text style={[styles.selectImage]}>Change Photo</Text>
          </TouchableOpacity>
        )}
      </React.Fragment>
    );
  }

  return (
    <View style={[styles.innerWrapper]}>
      <TouchableOpacity style={styles.emptyWrapper} onPress={handlePress}>
        <View style={[styles.indexNumber]}>
          <Text style={{ fontSize: 10, color: 'black', fontWeight: 'bold' }}>
            {index + 1}
          </Text>
        </View>
        <Loading />
        {!valueInputs[index] && !pending && (
          <Image
            style={[styles.previewImage]}
            source={{ uri: defaultImage || uploadBg }}
            resizeMode="contain"
          />
        )}
      </TouchableOpacity>
    </View>
  );
};

export default ImageUploadWeb;
