import { useEffect } from 'react';
import { batch, useSelector } from 'react-redux';

import {
  appDataBaseSocket,
  getAppDatabaseChannel,
  handleAppdataBaseSocket,
} from '@common/configs/app-database-socket';
import { appInfoSelector } from '@common/redux/selectors/app';
import {
  addItemDataSource,
  deleteRecordSuccess,
  upsertCurrentRecord,
  updateItemDataSource,
} from '@common/redux/slice/database';
import { METHOD } from '@common/constants/method';
import store from '@common/redux/store';
import { IRecord } from '@common/types/database';
import {
  getCurrentRecord,
  getDataSourceStore,
} from '@common/redux/selectors/database';
import {
  deleteRecordValue,
  setListRecordValue,
} from '@common/redux/slice/listRecords';
import { authSelector } from '@common/redux/selectors/auth';
import { signOut, updateLoggedUserSuccess } from '@common/redux/slice/auth';
import { handleCompareProfile } from '@common/utils/functions';

interface UpdateDataSocketType {
  data: IRecord;
  type: string;
}

const ALLOW_DATABASE_REALTIME = [
  '57dbce4e-fdd1-4cd7-90a8-6dbf922eedc6', // Users
  '63343df5-0c95-4e82-af58-fb481a4f8229', // Products
  'd6ceb108-3f32-4d6d-8e68-5ffd9b081ac8', // Orders
  '54a3d4d2-830d-49fd-8c9a-689cf34cc928', // OrderDetail
  '9341e96c-b7b2-464e-a410-292453a35a52', // Block
  'd18599de-9e75-4ae6-8ac3-e93b7477cbbb', // Follows
  'bdc0939c-b3b6-4b32-825d-9de357dd4d54', // Livestream

  'c1b6aea6-cec4-46bf-9e42-64358b4518da', //follows staging
  '46d25e89-4d9c-445c-a78d-db85b9671969', // orders staging
  'f97ae1fe-82ad-4b69-bad7-a81545afc550', // product staging
  '20f4cc77-6dfa-4359-a686-fd52eea3104f', // user block staging
  '9557414a-790a-48f4-8c2a-76fd5585fab9', // OrderDetail staging
  '704d8db5-20ad-4835-806a-879a3b729571', // Users staging
];

export const useAppDatabaseSocket = () => {
  const appInfo = useSelector(appInfoSelector);
  const auth = useSelector(authSelector);

  const appDatabaseId = appInfo?.shareDatabaseAppId || appInfo?.id;
  const channel = getAppDatabaseChannel(appDatabaseId);
  const dataSource = useSelector(getDataSourceStore);
  const currentRecord = useSelector(getCurrentRecord);
  const dispatch = store.dispatch;

  const handleUpdate = (data: UpdateDataSocketType) => {
    const record = data?.data;
    const databaseUuid = record?.databaseId;
    const type = data?.type;

    if (!ALLOW_DATABASE_REALTIME.includes(databaseUuid)) return;

    switch (type) {
      case METHOD.REMOVE:
        batch(() => {
          console.log('===record will delete', record);

          if (dataSource[databaseUuid]) {
            dispatch(
              deleteRecordSuccess({
                databaseUuid,
                recordDeleted: record,
              })
            );
          }
          //deleted item in list
          dispatch(deleteRecordValue(record));

          //deleted loggedUser
          if (record?._id && auth?.profile?.userId === record?._id) {
            dispatch(signOut(1));
          }
        });
        return;

      case METHOD.UPDATE:
      case METHOD.PATCH:
        batch(() => {
          dispatch(updateItemDataSource(record));
          // update current record
          if (record?._id) {
            dispatch(upsertCurrentRecord(record));
          }
          //update list
          dispatch(setListRecordValue(record));

          //update loggedUser
          if (
            record?._id &&
            auth?.profile?.userId === record?._id &&
            record?.record
          ) {
            dispatch(
              updateLoggedUserSuccess({
                user: handleCompareProfile(record as IRecord),
              })
            );
          }
        });
        return;

      case METHOD.CREATE:
        dispatch(addItemDataSource(record));
        return;
      default:
    }
  };

  useEffect(() => {
    if (channel) {
      handleAppdataBaseSocket(channel, () => {
        for (const method of Object.values(METHOD)) {
          appDataBaseSocket?.on(
            `v2/database/:databaseId/records ${method}`,
            (data: { data: UpdateDataSocketType; channel: string }) => {
              handleUpdate(data.data);
            }
          );
        }
      });
      return () => {
        for (const method of Object.values(METHOD)) {
          appDataBaseSocket?.off(`v2/database/:databaseId/records ${method}`);
        }
      };
    }
  }, [channel, auth]);
};
