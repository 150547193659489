import crashlytics from '@react-native-firebase/crashlytics';

const dismissKeyboard: () => Promise<any> = async () => {
  crashlytics().crash();
  return {
    status: 'SUCCEED',
    message: 'Crash app success !',
  };
};

export default dismissKeyboard;
