import {
  getCurrentLocation,
  getCurrentRecord,
} from '@common/redux/selectors/database';
import { getValueFields } from '@common/redux/selectors/formInput';
import { IRecord } from '@common/types/index';
import { bindingList, getTextBinding } from '@common/utils/handleBinding';
import { bindSourceHealthy } from '@common/utils/functions';
import { getValueLiveStream } from '@common/redux/selectors/liveStream';
import { filter, isArray, isString, map } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBinding = () => {
  const objectBinding = useSelector(getValueFields);
  const objectBindingLiveStream = useSelector(getValueLiveStream);

  const handleBindingField = useCallback(
    (value: any, record: any, index?: number) => {
      return getTextBinding(value, record, undefined, index);
    },
    [objectBinding, objectBindingLiveStream]
  );

  const handleBindingList = (
    records: IRecord[],
    attrChild: any,
    parentRecord?: Record<string, any>
  ) => {
    return map(records, (record, index) => ({
      ...bindingList({ ...record, parentRecord }, attrChild, index),
    }));
  };

  return { handleBindingField, handleBindingList };
};

const flatSource = (source: any) => {
  if (isString(source)) return source;
  if (isArray(source)) {
    return filter(source, (item) => item);
  }
};

export const excuteBinding = (
  value: any,
  record: any,
  index?: any,
  currentListId?: string
) => {
  const objectBinding = useSelector(getValueFields);
  const objectBindingLiveStream = useSelector(getValueLiveStream);

  const currentLocation = useSelector(getCurrentLocation);
  const currentRecord = useSelector(getCurrentRecord) || {};

  const [text, setText] = useState('');

  useEffect(() => {
    handleBindValue();

    async function handleBindValue() {
      const source = await bindSourceHealthy(value);

      const sourceFlated = flatSource(source);

      let resp = getTextBinding(
        sourceFlated,
        record,
        undefined,
        index,
        currentListId
      );

      setText(resp);
    }

    return () => setText('');
  }, [
    objectBinding,
    record,
    value,
    currentRecord,
    objectBindingLiveStream,
    currentLocation,
  ]);

  return text;
};
