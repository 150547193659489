import ImageUploadWeb from '@common/components/ImageUploadList/imageUploadWeb';
import { excuteBinding } from '@common/hooks/useBinding';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { get } from 'lodash';
import React, { FC } from 'react';
import { FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import createStyles from './style';

const imageNames = ['image1', 'image2', 'image3', 'image4', 'image5'];

const CusImageUploadList: FC<any> = (attributes) => {
  const styles = createStyles(attributes);
  const currentRecord = useSelector(getCurrentRecord) || {};
  const getTableId = get(attributes, 'selectedItem.tableId');

  const imageArray = imageNames.map((name) =>
    excuteBinding(
      get(attributes, name) || '',
      attributes.record || currentRecord[getTableId],
      attributes?.selectedItem?.itemIndex,
      attributes.currentListId
    )
  );

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      nestedScrollEnabled={true}
      horizontal
      style={[styles.flatlist]}
      scrollEnabled={true}
      data={imageArray}
      renderItem={({ item, index }) => {
        return (
          <ImageUploadWeb
            key={index}
            attributes={attributes}
            index={index}
            defaultImage={item}
          />
        );
      }}
    />
  );
};

export default CusImageUploadList;
