import { componentName } from '@common/constants/shared';
import { find, isNil, map, round } from 'lodash';
import { Platform } from 'react-native';

interface ObjectParams {
  obj: any;
  prevObj: any;
  index: any;
  dimension: any;
  offsetLeft: any;
  isScreen: any;
  metadata: any;
  insets: any;
  statusBarHeight: any;
  originX: any;
  originY: any;
  layout: any;
  offsetTop: any;
  modalMetadata: any;
  currentPageInfo: any;
  isShowStatusBar?: boolean;
}

// const defaultMargin = 15 as const;

export const getComponentChild = (metadata: any[], objId: string) => {
  let component;

  map(metadata, (item) => {
    if (item.children) {
      component = find(item.children, { id: objId });
    }
  });

  return component;
};

export const updateObjectOrigin = (
  params: ObjectParams,
  isFromViewLive?: boolean
) => {
  const {
    obj,
    prevObj,
    index,
    offsetLeft,
    isScreen,
    metadata,
    insets,
    statusBarHeight,
    originX,
    originY,
    modalMetadata,
    offsetTop,
    isShowStatusBar,
  } = params;

  const oldObj =
    find(metadata, { id: obj.id }) ||
    getComponentChild(metadata, obj.id) ||
    find(modalMetadata, { id: obj.id });
  const objectWidth = oldObj ? oldObj.width : obj.width - insets.left;
  const objectHeight = oldObj ? oldObj.height : obj.height;

  const objX = oldObj ? oldObj.x : obj.x;

  if (index == 0) {
    const marginTopPocth =
      insets.bottom + Platform.OS !== 'ios' && obj.y < statusBarHeight
        ? statusBarHeight - obj?.y || 0
        : 0;

    obj.marginTop = !isScreen
      ? obj.y - offsetTop
      : obj.y + (isShowStatusBar ? -statusBarHeight + marginTopPocth : 0);
  } else {
    let marginTop =
      prevObj?.type == 'section' ||
      prevObj?.type == 'group' ||
      prevObj?.type == 'image'
        ? (obj.y - prevObj.y - prevObj.height / originX) * originX
        : obj.y - prevObj.y - prevObj.height;
    obj.marginTop = marginTop;
  }

  obj.marginLeft = (objX - offsetLeft) * originX;

  // obj.height = objectHeight * originX;

  if (
    obj?.type == 'section' ||
    obj?.type == 'group' ||
    obj?.type == 'image' ||
    obj.type === 'image-upload'
  ) {
    obj.width = objectWidth * originX;
    obj.height = objectHeight * originX;

    // fix rounded image
    // if (
    //   obj?.type == 'image' &&
    //   round(obj.borderRadius / objectWidth, 2) >= 0.48
    // ) {
    //   obj.borderRadius = obj.borderRadius * objectWidth;
    // }

    // fix image not rounded when follow
  } else {
    obj.width = objectWidth * originX;
  }
  if (isFromViewLive) {
    obj.width = objectWidth;
  }

  if (
    oldObj &&
    [componentName.Toggle, componentName.Icon].includes(oldObj?.componentName)
  ) {
    const attributes = oldObj.attributes;

    const reduceOrigin = originX < 1 ? originX : 1;

    const iconSize = attributes.size;

    obj.attributes.size = iconSize * reduceOrigin;
  }

  obj.originX = originX;

  obj.originY = originY;

  return obj;
};

export const webComponent = (params: ObjectParams) => {
  const {
    obj,
    prevObj,
    index,
    offsetLeft,
    isScreen,
    metadata,
    insets,
    statusBarHeight,
    originX,
    originY,
    modalMetadata,
    offsetTop,
    layout,
    dimension,
    currentPageInfo,
  } = params;

  const oldObj =
    find(metadata, { id: obj.id }) ||
    getComponentChild(metadata, obj.id) ||
    find(modalMetadata, { id: obj.id });

  const objectWidth = oldObj ? oldObj.width : obj.width - insets.left;
  const objectHeight = oldObj ? oldObj.height : obj.height;

  if (index == 0) {
    const marginTopPocth =
      insets.bottom + Platform.OS !== 'ios' && obj.y < statusBarHeight
        ? statusBarHeight - obj?.y || 0
        : 0;

    obj.marginTop = !isScreen
      ? obj.y - offsetTop
      : obj.y - statusBarHeight + marginTopPocth + insets.top;
  } else {
    obj.marginTop = obj.y - prevObj.y - prevObj.height;
  }

  if (objectWidth / currentPageInfo.width >= 0.9) {
    obj.width = objectWidth * originX;
    // obj.height = objectHeight * originX;
  } else if (objectWidth / dimension.width >= 0.9) {
    obj.width = dimension.width * 0.9;
    // obj.height = (objectWidth / dimension.width) * 0.9 * obj.height;
  } else {
    obj.width = objectWidth;
  }

  let webOffset = 0;

  const scaleWidth = objectWidth * originX;
  webOffset = (scaleWidth - obj.width) / 2;
  obj.webOffset = webOffset;

  // obj.marginLeft = (obj.x - offsetLeft) * originX + webOffset;

  if (isNil(layout?.offsetLeft)) {
    obj.marginLeft =
      obj.x === 0 ? 0 : (obj.x - offsetLeft) * originX + webOffset;
  } else {
    obj.marginLeft = obj.x - offsetLeft;

    const objTopRight = obj.x + objectWidth;
    const parentTopRight = layout.offsetLeft + layout.parentWidth;
    if (objTopRight >= parentTopRight) {
      obj.marginLeft = obj.marginLeft - (objTopRight - parentTopRight);

      if (obj.marginLeft < 0) {
        obj.marginLeft = 0;
      }
    }
  }

  // const objectTopRight = obj.marginLeft + obj.width;
  // const offsetRight = dimension.width - objectTopRight;

  // if (!layout?.offsetLeft && obj.marginLeft <= defaultMargin) {
  //   obj.marginLeft = defaultMargin;

  //   if (dimension.width - 30 < objectWidth) {
  //     obj.width = dimension.width - 30;
  //   }
  // } else {
  //   if (offsetRight <= 15) {
  //     obj.marginLeft = obj.marginLeft - 15;
  //   }
  // }

  // obj.height = objectHeight * originX;

  if (
    oldObj &&
    [componentName.Toggle, componentName.Icon].includes(oldObj?.componentName)
  ) {
    const attributes = oldObj.attributes;

    const reduceOrigin = originX < 1 ? originX : 1;

    const iconSize = attributes.size;

    obj.attributes.size = iconSize * reduceOrigin;
  }

  obj.originX = originX;

  obj.originY = originY;

  return { ...obj, isWeb: true };
};

export const getColorMapping = (color: string, appInfo: any) => {
  if (color === '@primary') {
    return appInfo?.primaryColor || '#4259AC';
  }
  if (color === '@secondary') {
    return appInfo?.secondaryColor || '#88c6ff';
  }
  return color;
};

export const mapPropColor = (props: Record<string, any>, appInfo: any): any => {
  for (const key in props) {
    props[key] = getColorMapping(props[key], appInfo);
  }
  return props;
};
