import { ISelect } from '@common/types/element';
import React, { FC, Fragment } from 'react';
import DropDown from './CustomPicker';
import useDropdown, { useDropdownProps } from './hook';

const CustomPicker: FC<ISelect> = (attrs) => {
  const {
    showDropDown,
    value,
    list,
    handleShowDropdown,
    handleHiddenDropdown,
    handleChangeValue,
  }: useDropdownProps = useDropdown(attrs);

  if (list.length < 0) return <Fragment />;

  return (
    <DropDown
      mode={'outlined'}
      placeholder={attrs.placeholder}
      defaultValue={attrs.defaultValue}
      value={value}
      setValue={handleChangeValue}
      list={list as any}
      visible={showDropDown}
      showDropDown={handleShowDropdown}
      onDismiss={handleHiddenDropdown}
      attributes={attrs}
      numberOfLines={attrs.numberOfLines}
    />
  );
};

export default CustomPicker;
