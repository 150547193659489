export default function () {
  const crash = () => {
    console.log('Not support crash on Web!');
  };
  const isCrashlyticsCollectionEnabled = false;
  return {
    crash,
    isCrashlyticsCollectionEnabled,
  };
}
