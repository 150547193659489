import { combineReducers } from 'redux';
import pages from './page';
import database from './database';
import authReducer from './auth';
import formInputs from './formInput';
import appInfo from './app';
import action from './action';
import admob from './admob';
import liveStream from './liveStream';
import notification from './notification';
import locationHistory from './locationHistory';
import customAction from '@common/redux/slice/customAction';
import externalCollection from '@common/redux/slice/externalCollection';
import listRecords from '@common/redux/slice/listRecords';

const rootReducer = combineReducers({
  pageInfo: pages,
  database: database,
  auth: authReducer,
  formInputs: formInputs,
  appInfo: appInfo,
  action: action,
  admob,
  liveStream,
  locationHistory,
  notification,
  customAction: customAction,
  externalCollection: externalCollection,
  listRecords: listRecords,
});

export default rootReducer;
