import { BINDING_SELECTOR_TYPE } from '@common/constants/shared';
import { getRecord, getRecords, getTable } from '@common/utils/database';
import {
  setCreateRecordAddUpdate,
  setReloadStatus,
  updateItemDataSource,
} from '@common/redux/slice/database';
import { resetValue, setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { IAction, IRecord } from '@common/types/';
import { MetaData } from '@common/types/action';
import { getLastedRecordCreate } from '@common/utils/database';
import excuteUpdateExternal from '@common/utils/handleActions/ActionItem/updateExternal';
import { batch } from 'react-redux';
import { filter, find, get, isArray, isEmpty, isNil } from 'lodash';
import {
  ActionResponse,
  getCurrentRelationship,
  getRelationshipField,
} from '../excuteAction';
import { updateRecordRecordApi } from '../func/apiFunction';
import { handlePermissonDatabase, parsePayload } from '../func/func';
import updateLoggedIn from './updateLoggedUser';

export const getTargetRecord = (recordArray: any[], databaseUuid: string) => {
  return find(recordArray, (item: Record<string, any>) =>
    isNil(item) || isEmpty(item) ? false : item.databaseId === databaseUuid
  );
};

const excuteUpdateObject: (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  locale?: string
) => Promise<ActionResponse> = async (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  locale?: string
) => {
  const { record, databaseUuid } = metadata;
  const dispatch = store.dispatch;
  const state: any = store.getState();
  const auth = state.auth.profile;

  const appUser = state?.appInfo?.app?.app_team?.map(
    (user: any) => user.user_id
  );

  const {
    options: { selector, tableId, source, fieldId },
    autoValues,
  } = action;

  let targetRecord: IRecord | Record<string, any> | undefined;
  let updateUserStatus;

  const table = getTable(databaseUuid);

  if (selector) {
    switch (selector.type) {
      case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
        targetRecord = get(state, `database.currentRecord[${tableId}]`, {});
        break;

      case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
        // targetRecord = get(state, `database.currentRecord[${tableId}]`, {});

        targetRecord = isArray(currentRecord)
          ? getTargetRecord(currentRecord, databaseUuid)
          : currentRecord;
        break;

      case BINDING_SELECTOR_TYPE.CREATED_OBJECT:
        targetRecord = getLastedRecordCreate(databaseUuid);
        break;

      case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
        updateUserStatus = await updateLoggedIn(metadata, locale);
        break;

      default:
        targetRecord = undefined;
        break;
    }
  } else {
    if (source && fieldId && tableId)
      targetRecord = getCurrentRelationship(
        source,
        fieldId,
        tableId,
        currentRecord,
        action?.options
      );
  }

  const newPayload = parsePayload({
    databaseUuid,
    record: {
      ...autoValues,
      ...record,
    },
  });

  const recordArray: Array<IRecord> = getRecords(databaseUuid);

  const validEmail = isEmpty(
    filter(recordArray, ['record.email', record.email])
  );

  const isDatabseUser = databaseUuid === auth?.databaseId;
  const isCurrentRecord = targetRecord?.record?.email === record?.email;

  if (
    !validEmail &&
    isDatabseUser &&
    selector?.type !== BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR &&
    !isCurrentRecord
  ) {
    dispatch(
      setError({
        message: 'Email is ready exist !',
        isError: true,
      })
    );

    return {
      status: 'FAILED',
      message: 'Email is ready exist !',
    };
  }

  const permission = handlePermissonDatabase({
    databaseUuid,
    targetRecord: get(targetRecord, 'record', {}),
    payload: newPayload,
  });

  if (permission.message) {
    dispatch(
      setError({
        message: permission.message,
        isError: true,
      })
    );
  }

  if (table && table.isThirdParty) {
    const currentRecordId = state.externalCollection.recordId;
    return excuteUpdateExternal(
      state,
      table,
      permission.payload,
      dispatch,
      currentRecordId
    );
  }

  if (isNil(targetRecord)) {
    if (updateUserStatus) return updateUserStatus;

    return {
      status: 'FAILED',
      message: 'No selected record',
    };
  } else {
    try {
      if (isEmpty(permission.payload)) {
        return {
          status: 'FAILED',
          message: '',
        };
      }

      const recordCreated = getRecord(databaseUuid, targetRecord?._id);

      // const { tables, appId } = getRelationshipField(databaseUuid);

      const lastRecordCreated =
        !isEmpty(targetRecord) && !isEmpty(recordCreated);

      if (lastRecordCreated) {
        let recordUpdated = await updateRecordRecordApi(
          databaseUuid,
          permission.payload,
          targetRecord?._id,
          appUser
        );

        if (recordUpdated) {
          if (
            !metadata?.mappingField ||
            !Object.keys(metadata?.mappingField).length
          ) {
            dispatch(resetValue(recordUpdated));
          }

          // dispatch(setCurrentRecord(recordUpdated));

          batch(() => {
            dispatch(
              setCreateRecordAddUpdate({
                databaseUuid,
                record: recordUpdated,
              })
            );

            dispatch(updateItemDataSource(recordUpdated));

            dispatch(setReloadStatus(true));
          });

          return {
            status: 'SUCCEED',
            message:
              locale === 'ja' ? 'データが更新されました' : 'Updated record',
            target: currentRecord,
            current: recordUpdated,
          };
        } else {
          return {
            status: 'FAILED',
            message:
              locale === 'ja'
                ? 'データが見つかりませんでした'
                : 'Not found record',
          };
        }
      } else {
        return {
          status: 'FAILED',
          message:
            locale === 'ja'
              ? 'データが見つかりませんでした'
              : 'Not found record',
        };
      }
    } catch (err: any) {
      if (table?.databaseName === 'Orders' && !err.response)
        return {
          status: 'FAILED',
          message:
            '発送完了ができませんでした。電波状況が良好なエリアで再度実行してください。',
        };

      // reject(err);
      return {
        status: 'FAILED',
        message: err?.message,
      };
    }
  }

  // callBack();
};

export default excuteUpdateObject;
