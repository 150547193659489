import { get } from 'lodash';
const useVisibilityButton = (
  options: any,
  records: any,
  optionsComparison?: any
) => {
  let isDisplay;

  if (options.length !== 2 || optionsComparison.length !== 2)
    return {
      isDisplay: true,
      record: {},
    };

  const [comparison1, comparison2] = optionsComparison;
  const fieldValue = get(options, '0.field.value');
  const secondFieldValue = get(options, '1.field.value');

  const findRecord = records?.find((item: any) => {
    const firstFieldRecord =
      get(item, `record.${fieldValue}`) || get(item, `${fieldValue}`);
    const secondFieldRecord =
      get(item, `record.${secondFieldValue}`) ||
      get(item, `${secondFieldValue}`);

    return (
      firstFieldRecord === comparison1 && secondFieldRecord === comparison2
    );
  });

  const primaryValue = records?.find((item: any) => {
    const FieldRecord =
      get(item, `record.${fieldValue}`) || get(item, `${secondFieldValue}`);
    return FieldRecord === comparison1;
  });

  isDisplay = !primaryValue ? true : !findRecord;

  return { isDisplay, record: findRecord || {} };
};
export default useVisibilityButton;
