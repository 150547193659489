import api from '@common/configs/api';
import history from '@common/routes/history';
import { IAction } from '@common/types';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { getFieldValue } from '@common/utils/binding';
import store from '@common/redux/store';
import { setError } from '@common/redux/slice/formInput';

const follow: (
  action: IAction,
  currentRecord?: any
) => Promise<ActionResponse> = async (action: IAction, currentRecord?: any) => {
  const dispatch = store.dispatch;

  const appId = history.location.pathname.split('/')[2];
  const userFollowId = getFieldValue(
    action.options?.userFollowId,
    currentRecord
  );
  const userFollowingId = getFieldValue(
    action.options?.userFollowingId,
    currentRecord
  );
  const followType = getFieldValue(action.options?.followType, currentRecord);

  const followEmail = getFieldValue(action.options?.followEmail, currentRecord);
  const followUsername = getFieldValue(
    action.options?.followUsername,
    currentRecord
  );
  const followAvatar = getFieldValue(
    action.options?.followAvatar,
    currentRecord
  );
  const followingEmail = getFieldValue(
    action.options?.followingEmail,
    currentRecord
  );
  const followingUsername = getFieldValue(
    action.options?.followingUsername,
    currentRecord
  );
  const followingAvatar = getFieldValue(
    action.options?.followingAvatar,
    currentRecord
  );

  try {
    const response = await api({
      method: 'post',
      url: `socket-notification-common`,
      data: {
        channelName: `follow_${userFollowingId}`,
        metadata: {
          appId,
          followType,
          userFollow: {
            user: {
              userId: userFollowId,
              username: followUsername,
              email: followEmail,
              avatar: { url: followAvatar },
            },
          },
          userFollowing: {
            user: {
              userId: userFollowingId,
              username: followingUsername,
              email: followingEmail,
              avatar: { url: followingAvatar },
            },
          },
        },
      },
    });

    if (response && response.data) {
      return {
        status: 'SUCCEED',
        message: 'Follow success !',
      };
    } else {
      return {
        status: 'FAILED',
        message: 'Follow failed !',
      };
    }
  } catch (error: any) {
    dispatch(
      setError({
        message: error?.message,
        isError: true,
      })
    );

    console.log(error?.message);

    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default follow;
