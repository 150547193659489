import api from '@common/configs/api';
import { setCreateRecordAddUpdate } from '@common/redux/slice/database';
import { setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import history from '@common/routes/history';
import { IAction, IRecord } from '@common/types';
import { getFieldValue } from '@common/utils/binding';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { getUserLoginRecord } from '@common/utils/handleActions/func/func';
import { get } from 'lodash';

type PaymentParam = {
  currency: string;
  destination: string;
  receipt_email: string;
  description: string;
  testMode: {
    enabled: boolean;
    publishableKey: string;
    secretKey: string;
  };
  card: {
    number: string;
    exp_month: string;
    exp_year: string;
    cvc: string;
  };
};

const purchaseProductAll: (
  action: IAction,
  payment: PaymentParam
) => Promise<ActionResponse> = async (
  action: IAction,
  payment: PaymentParam
) => {
  const dispatch = store.dispatch;

  const appId = history.location.pathname.split('/')[2];
  const buyerId = getFieldValue(action.options?.buyerId, {});
  const productId = getFieldValue(action.options?.productId, {});
  const quantity = getFieldValue(action.options?.quantity, {});
  const price = getFieldValue(action.options?.price, {});
  const other = getFieldValue(action.options?.other, {});
  const isPushToChat = getFieldValue(
    get(action.options, 'isPushToChat', true),
    {}
  );

  const userLoginRecord: IRecord | any = getUserLoginRecord();

  const body = {
    appid: appId,
    userBuyerId: buyerId,
    productId: productId,
    quantity: +quantity,
    price: +price,
    other_info: other,
    accessToken: userLoginRecord.accessToken,
    payment,
    isPushToChat,
  };

  try {
    const response = await api({
      method: 'post',
      url: `app-order/purchase-all`,
      data: body,
    });

    if (response && response.data) {
      if (response.data.success) {
        const { orderDetailInfo } = response.data;
        dispatch(
          setCreateRecordAddUpdate({
            databaseUuid: orderDetailInfo.databaseId,
            record: orderDetailInfo,
          })
        );
        return {
          status: 'SUCCEED',
          message: 'Purchase success !',
          paymentIntent: response.data.paymentIntent,
        };
      }
      return {
        status: 'FAILED',
        message: 'Purchase failed !',
        paymentIntent: response.data.paymentIntent,
      };
    }
    return {
      status: 'FAILED',
      message: 'Purchase failed !',
    };
  } catch (error: any) {
    if (error?.response?.data) {
      dispatch(
        setError({
          message: error?.response.data.message,
          isError: true,
        })
      );

      throw error?.response.data;
    }

    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default purchaseProductAll;
