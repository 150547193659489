import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image } from 'react-native';

import { checkFont } from '../func';
import minus from './image/minus';
import plus from './image/plus';

const ChangeQuantity = (props: any) => {
  const { attributes, width, height, changeInput } = props;
  const {
    opacity,
    backgroundColor,
    fontSize,
    fontFamily,
    color,
    borderRadius,
    defaultQuantity,
    maximum,
    minimum,
  } = attributes;

  const [quantity, setquantity] = useState(
    +defaultQuantity ? +defaultQuantity : 0
  );

  const styles = StyleSheet.create({
    wrapper: {
      width,
      height,
      borderRadius,
      display: 'flex',
      justifyContent: 'center',
    },
    select: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    listItem: {
      marginLeft: 45,
      marginRight: 15,
      marginTop: 10,
      marginBottom: 10,
    },
  });

  const handlePlus = () => {
    if (quantity < +maximum && changeInput) {
      setquantity(quantity + 1);
    }
  };

  const handleMinus = () => {
    if (quantity > +minimum && changeInput) {
      setquantity(quantity - 1);
    }
  };

  useEffect(() => {
    changeInput && changeInput(quantity);
  }, [quantity]);

  return (
    <View
      style={{
        opacity,
        width,
        height,
        backgroundColor,
      }}
    >
      <View style={[styles.wrapper]}>
        <View style={[styles.select, { width: '100%' }]}>
          <View style={{ width: 25, height: 25 }}>
            {quantity > +minimum ? (
              <TouchableOpacity onPress={handleMinus} activeOpacity={1}>
                <Image
                  style={{
                    height: 25,
                    width: 25,
                    borderRadius: 17,
                  }}
                  source={{ uri: minus }}
                />
              </TouchableOpacity>
            ) : (
              <Image
                style={{
                  opacity: 0.4,
                  height: 25,
                  width: 25,
                  borderRadius: 17,
                }}
                source={{ uri: minus }}
              />
            )}
          </View>

          <Text
            style={{
              color,
              fontSize,
              fontWeight: 'bold',
              fontFamily: checkFont(fontFamily),
            }}
            numberOfLines={1}
          >
            {quantity}
          </Text>
          <View style={{ width: 25, height: 25 }}>
            {quantity < +maximum ? (
              <TouchableOpacity onPress={handlePlus} activeOpacity={1}>
                <Image
                  style={{
                    height: 25,
                    width: 25,
                    borderRadius: 17,
                  }}
                  source={{ uri: plus }}
                />
              </TouchableOpacity>
            ) : (
              <Image
                style={{
                  opacity: 0.4,
                  height: 25,
                  width: 25,
                  borderRadius: 17,
                }}
                source={{ uri: plus }}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default ChangeQuantity;
