import React from 'react';
import Svg, { Image } from 'react-native-svg';

const imageBase64 = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABUCAAAAAA5AE8dAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfmBR4JNRIqtnWSAAAGIElEQVRYw+2Ze2xUZRbAf7czd0rpY9oO6kaQV6iAQYtE0ZSSFAvVyAqJ
AR8ghiBiZP/Q+KCSKEWhQPhnExM3JJtsS1DA+GAju9EAUqQg4uIqrRIepcUuGArLtJ1hyjy/4x9z
78y0tjNzZ2pioueve7977u875zvnO3O/M5ow/JLzKzD/gP7uoKovHLu2Dw8y1L77uwm1twwfVMLX
TzV+cgX8b9uHCSq+U/sOftsDcC44TNDeA+8f7wpEr8tzYzNlLhFf27aZcatKW80HmVsauXh037GO
UHxg/mTzKlNouO2jf7b1JpY45+N6fKGtiwq5m1e4jPdjiT77kmTuvvh+ONB0ohcA/dY7T5+PmqvX
uGIqlqHeA7uOXY2GW5u6dO6eL4wlcFY7Eia24nff2XfuNfLGXlrd2NVbHyPN88T1LEDD7TtXldmi
iIJZrzf1KE+dExg5yQb291QG0OCZ9XcXG1EpXfxx5w0lgU1OQH9hvgbl18QiVAWvNa80qoWWN/HZ
EwERUZ7NDiB/zanxYN8q1qDK+/XGKqeRQOOWNLaFRETEW+cE8uuu/VWH21usQXs/WjzWCI5typsn
usPRxQtsKAb0Wvf/7wae6kkfGvGd2zbTCLC9ZO72q2Y0lGeLA8h/za8+0GDk9ki60PCFncvLjEQu
qFzX1B0PcNT3gjq39CwA7rgg6UGD32+4t1iLIkse/fBHf0LOBDYVA/or3UodHgOsCaUBVUF38zMl
RlrnTVr932D/yG2yA/lr/SLB9Q5wnpSUUOX96q0HDCPtY5c0tPW3Qzx1xYbvIldmAUtDKaHePYtu
M2v45A2xcMd93+IEbLXdSkQ+L4SivZIUqvrO/e0eM9ylcxu61MAplXeDAyio9YuIhJYDVZeSQcMd
O1fGwj3rjaaeXyBN3/PrukVE5NtRoG8MDA0NnXlrhtPY3SWLPu68MQhSApudgL7WrUREwq8CrqNq
KKj/yIo/Gbt75KTn/hOQwUR5NjmAglf80duO8gFFrz80vG2UsZK3Ldl+PiSDi8fY71HfJdJYBI7d
aijo9YWGmfOafhHuuO/1TkB/zW0o+JZpUN4tQ0H9q4zym3vfO2evRwZDmvs9GncRkbMTIGezDAmV
tpfvMmvRxKV/PzPImpq1zh2bZWsOlH2XBKp8p/9RU2isQeG055t9A3O+vhjQ17hj4+7poD3TmwQq
IuJvXjUhz6ghufN3/68vzlWeLbq5382hD20wckckBVQk2NawvMz40hgx5bn3O0MG17POrHUx6V4I
TG6XlFBR4d6WrTOMXWUruWd9a1jE3O/22oSSqg6PBl4KpQGNmvXp4tFmSSlZ8O/LAU/9QN9Fgm86
wNUq6UJF+VreXjja2LKF97+8uhjIX+dO1OmqBJYF04eKSOjq8fVTDaxdB+xr3f3yYd9gRS8FVETU
Tw3Vo8zvw4Jaf7+HoWVA5UXLUBHxHK2f59IA22p3/ycnXaBv9GcCFRW4vP8vRZDzal9/Q9cCo75U
GUFFRMIvAhU/9purYzowzzv4C+mc+GyPu6D1eOKQHGoHx4r8wV9I6xg5uRy870YSRvwHvTC1RssC
WliVC82nE44Nlw4LtqWlZAG1Vd8M3TvipsreSzDxQbKBatPKgX91xQbc28No94/LCkrRYzlw4Stl
GnqwBfLM6psplJo74MZ+n3HneQ8YPzsnS+hNT+agjlw2DG35BvjzGLKE5jxyC5w/Fo1/+OAVKHzC
li2UcTPB/24fAD37g2gLp5E1NL8qD458A0BLKxQlHG8zhtrm3Ao3GkNAaIcHps8geyhls4FDHcAP
e8Fe7UqimzY07wk7XD4aIbLLPeB4mzlUq7gL/Ieuy8X9wIw7teGAUrhCR450yBft4FhZkEzVQget
Zix0HvIf8MKUB5MZagU6ukIjvOvsYYVtiTO5aho/J4ZEGoog92E7lJ1MrmnBUq1iDAQ+C6NVjk+u
aQU6YQ6gYMQDBck1rbQ69aeKABhbmeItS/3T6RUALBiTQs8SdMTTOuB6OlXfyVqnd9ZU4KEpqdSs
QW+eY6f0yZQNMmsdtNzVns4FVSnVNIv/nQRDI2wplaxC05Lfdp//D+ivC/0Z+jsoUo2Xy80AAAAl
dEVYdGRhdGU6Y3JlYXRlADIwMjItMDUtMzBUMDY6NTM6MTgrMDM6MDCYqVDHAAAAJXRFWHRkYXRl
Om1vZGlmeQAyMDIyLTA1LTMwVDA2OjUzOjE4KzAzOjAw6fToewAAAABJRU5ErkJggg==`;

const SendBtn = () => {
  return (
    <Svg
      id="Layer_1"
      x="0px"
      y="0px"
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      enable-background="new 0 0 30 30"
    >
      <Image
        id="image0"
        width="30"
        height="30"
        x="0"
        y="0"
        href={{
          uri: imageBase64,
        }}
      />
    </Svg>
  );
};

export default SendBtn;
