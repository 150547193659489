import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Image, Button, Tag } from 'antd';

import api from '@common/configs/api';
import 'antd/dist/antd.css';

import { ITable } from '@common/types/element';
import {
  map,
  forEach,
  isEmpty,
  includes,
  debounce,
  filter,
  values,
  omit,
  some,
  isString,
} from 'lodash';
import { getTextBinding } from '@common/utils/handleBinding';
import { bindType, defaultURL, rowType } from '@common/constants/shared';
import styled from 'styled-components';
import SearchComponent from './SearchComponent';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRecordApi } from '@common/utils/handleActions/func/apiFunction';
import axios from 'axios';
import { deleteRecordSuccess } from '@common/redux/slice/database';
import AntStyles from './style';
import { getValueFields } from '@common/redux/selectors/formInput';
import { getSource } from '@common/utils/functions';

const styleUnWrapperText = `
max-width: 100%;
word-break: keep-all;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
display: block;
`;

const styleWrapperText = `
max-width: 100%;
overflow: inherit;
text-overflow: inherit;
white-space: break-spaces;
work-break: inherit;
`;

const TextWrapperStyled = styled.span`
  ${styleWrapperText}
`;

const TextUnWrapperStyled = styled.span`
  ${styleUnWrapperText}
`;

// const flexPos: any = {
//   left: 'flex-start',
//   right: 'flex-end',
//   center: 'center',
// };

// const buttonType: any = {
//   outline: 'default',
//   button: 'primary',
//   text: 'link',
// };

const ImageMinHeight = styled.div`
  min-height: 60px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
`;

const FileWrapper = styled.a`
  ${styleUnWrapperText}
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RowComponent: FC<Record<string, any>> = ({
  data,
  col,
  onPress,
  record,
  setLoading,
  fetchTableDataPaging,
  index,
  records,
}) => {
  switch (col.type) {
    case rowType.BOOLEAN:
      return <Checkbox checked={data} disabled />;
    case rowType.IMAGE:
      const imageType = col?.value?.imageType;

      const bindingOptions = col?.value?.binding?.options;

      const placeholderImage =
        imageType === bindType.UPLOADED
          ? defaultURL
          : bindingOptions?.placeholderImageEnabled
          ? bindingOptions?.placeholderImage || defaultURL
          : defaultURL;

      return !isEmpty(data) || bindingOptions?.placeholderImageEnabled ? (
        <>
          <ImageMinHeight />
          <ImageWrapper>
            <Image
              width="100%"
              height="100%"
              style={{ objectFit: col?.value?.imageResize || 'cover' }}
              src={isEmpty(data) ? placeholderImage : data?.url || data}
            />
          </ImageWrapper>
        </>
      ) : null;
    case rowType.FILE:
      return (
        <FileWrapper href={data?.url || data || ''} target="_blank">
          {data?.filename || data?.url || data || ''}
        </FileWrapper>
      );
    case rowType.ACTIONREF:
      if (!col.value) return <></>;

      const handleClick = async () => {
        setLoading(true);

        if (!onPress || !col.onPress) return;

        try {
          const externalRecord = records[record.indexInRecords];
          const data = await onPress(col.onPress.actionId, {
            itemId: record.id,
            indexRecord: index,
            externalId: externalRecord.id,
            externalRecord: externalRecord,
          });

          if (
            !some(
              data,
              (action: Record<string, any>) => action.actionType === 'navigate'
            )
          ) {
            await fetchTableDataPaging();
          }
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      };

      const colType = col.value?.type || 'button';
      const type = colType === 'button' ? 'primary' : 'default';
      const colWidthDefault = 130;
      const colMargin = 60;

      return (
        <ButtonWrapper
          {...col}
          style={{
            justifyContent: col?.value?.position,
            paddingRight: col?.value?.position === 'right' ? 16 : 0,
          }}
        >
          <Button
            style={{
              backgroundColor: col?.value?.backgroundColor,
              color: col?.value?.color,
              ...(colType === 'button' && { borderWidth: 0 }),
              ...(colType === 'text' && {
                borderWidth: 0,
                backgroundColor: '#fff',
              }),
              ...(colType === 'outline' && {
                borderColor: col?.value?.color,
                backgroundColor: '#fff',
              }),
              borderRadius: '5px',
            }}
            type={type}
            onClick={handleClick}
          >
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: (col?.width || colWidthDefault) - colMargin,
              }}
            >
              {data || ' '}
            </p>
          </Button>
        </ButtonWrapper>
      );
    case rowType.TAG:
      const TagWrapper = col?.wrapperText
        ? styled(Tag)`
            ${styleWrapperText}
          `
        : styled(Tag)`
            ${styleUnWrapperText}
          `;
      return data ? (
        <TagWrapper color={col?.color}>{data || ''}</TagWrapper>
      ) : null;
    case rowType.STRING:
      const TextWrapper = col?.wrapperText
        ? TextWrapperStyled
        : TextUnWrapperStyled;

      return <TextWrapper key={data}>{data || ''}</TextWrapper>;
    default:
      return <>{data || ''}</>;
  }
};

type PageData = {
  records: Record<string, any>[];
  limit: number;
  page: number;
};

const CusTable: FC<ITable> = (attributes) => {
  const objectBinding = useSelector(getValueFields);
  const appInfo = useSelector((state: any) => state.appInfo.app);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>([]);

  const [tableCols, setTableCols] = useState<Record<string, any>[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [filterText, setFilterText] = useState<string>('');

  const [pagingData, setPageData] = useState<PageData>({
    records: [],
    limit: 0,
    page: 1,
  });

  const appUser = useSelector((state: any) =>
    state?.appInfo?.app?.app_team?.map((user: any) => user.user_id)
  );

  const {
    columns,
    records,
    pagination: { pageSize, position },
    currentPage,
    onPress,
    attributes: { searchInput, selectable },
    databaseUuid,
    total,
    initializeList,
    onLoadMoreFilter,
  } = attributes;

  // const [pagination, setPagination] = useState<Record<string, any>>({
  //   current: 1,
  //   limit: pageSize,
  //   total: 0,
  // });

  const dispatch = useDispatch();
  const defaultWidth = attributes.width / columns?.length;

  const handleDelete = useCallback(async () => {
    if (!databaseUuid) return;
    setLoading(true);

    const deletePromises = map(selectedRowKeys, (id: string) =>
      deleteRecordApi(databaseUuid, id, appUser)
    );

    try {
      const dataDelete = await axios.all(deletePromises);
      forEach(dataDelete, (data: any) => {
        dispatch(
          deleteRecordSuccess({
            databaseUuid,
            recordDeleted: data,
          })
        );
      });
    } catch (e) {
      console.error('e', e);
      throw new Error();
    }
    // await fetchTableDataPaging();
    setSelectedRowKeys([]);
    setLoading(false);
  }, [selectedRowKeys, pagingData]);

  const handleSearch = debounce((e) => setFilterText(e.target.value), 500);

  const displayData = useMemo(() => {
    // const { records, limit, page } = pagingData;
    const dataBinding = map(records || [], (item, index: number) => {
      let data: Record<string, any> = {};
      forEach(columns || [], (col) => {
        const bindingValue =
          col?.value?.fileType === bindType.UPLOADED
            ? col?.value?.fileUploaded
            : getTextBinding(
                getSource(col),
                item,
                false,
                // index + (page - 1) * limit
                index
              );
        data[col.id] = bindingValue;
      });
      data.id = item._id;
      data.key = item._id;
      data.indexInRecords = index;
      return data;
    });

    if (isEmpty(filterText)) return dataBinding;

    const filteredData = filter(dataBinding, (item: Record<string, any>) => {
      return some(values(omit(item, ['key', 'id'])), (value: string) => {
        return includes(
          isString(value) ? value.toLowerCase() : value,
          filterText.toLowerCase()
        );
      });
    });

    return filteredData;
  }, [filterText, pagingData, objectBinding, records]);

  // const handleTableChange = useCallback(
  //   (e) => {
  //     setPagination({
  //       ...pagination,
  //       current: e.current,
  //     });
  //   },
  //   [pagination, pageSize]
  // );

  // const fetchTableDataPaging = useCallback(async () => {
  //   setLoading(true);

  //   try {
  //     const sort = attributes?.dataBinding?.source?.sort;

  //     const result: Record<string, any> = await api.get(
  //       `v2/database/${databaseUuid}/records`,
  //       {
  //         params: {
  //           appId: appInfo.id,
  //           limit: pagination.limit,
  //           page: pagination.current,
  //           ...(!isEmpty(sort) && {
  //             orderBy: sort?.fieldId,
  //             sort: sort?.direction === 'desc' ? 1 : -1,
  //           }),
  //         },
  //       }
  //     );

  //     setPagination({
  //       ...pagination,
  //       total: result.data.total,
  //     });

  //     const { data, limit, page } = result.data;

  //     setPageData({ records: data, limit, page });
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setLoading(false);
  // }, [appInfo, pagination, databaseUuid]);

  // useEffect(() => {
  //   fetchTableDataPaging();
  // }, [pagination.current, databaseUuid]);

  useEffect(() => {
    const tableColumns =
      columns && columns.length > 0
        ? map(columns, (item: Record<string, any>) => {
            return {
              title: item.title,
              key: item.id,
              dataIndex: item.id,
              width: item.width || defaultWidth,
              render: (
                data: any,
                record: Record<string, any>,
                index: number
              ) => (
                <RowComponent
                  data={data}
                  col={item}
                  record={record}
                  onPress={onPress}
                  setLoading={setLoading}
                  index={index}
                  records={records}
                  // fetchTableDataPaging={fetchTableDataPaging}
                />
              ),
            };
          })
        : [];

    setTableCols(tableColumns);
  }, [columns, currentPage, displayData]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (
        selectedRowsKey: React.Key[],
        selectedRows: Record<string, any>[]
      ) => {
        setSelectedRowKeys(selectedRowsKey);
        setSelectedRows(selectedRows);
      },
    }),
    [selectedRowKeys]
  );

  const disableDeleteButton = useMemo(
    () => selectedRowKeys?.length <= 0,
    [selectedRowKeys]
  );

  return (
    <div
      style={{
        height: attributes.height,
        width: attributes.width,
        marginTop: attributes.marginTop,
        marginLeft: attributes.marginLeft,
        zIndex: attributes.zIndex,
        opacity: attributes.opacity,
      }}
    >
      {/* {selectable && ( */}
      <SearchComponent
        disableDeleteButton={disableDeleteButton}
        disableSearch={!searchInput}
        selectable={selectable}
        handleSearch={handleSearch}
        handleDelete={handleDelete}
      />
      {/* )} */}
      <AntStyles
        {...attributes}
        columns={tableCols}
        dataSource={displayData}
        loading={loading || initializeList}
        rowSelection={
          selectable
            ? {
                type: 'checkbox',
                ...rowSelection,
              }
            : undefined
        }
        pagination={{
          position: ['none', position],
          pageSize,
          showSizeChanger: false,
          total: total,
          onChange: onLoadMoreFilter,
          // ...pagination,
        }}
        // onChange={handleTableChange}
        scroll={{ y: attributes.height, x: attributes.width }}
      />
    </div>
  );
};

export default CusTable;
