import { CardElement, Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElementLocale,
  StripeElements,
} from '@stripe/stripe-js';

import React, { useCallback } from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import DefaultCard from './DefaultCard';
import Form from './Form';
import createStyles, { IStripePaymentSecurity } from './style';
import SubmitButton from './SubmitButton';
import { useStripeSecurity } from './useStripeSecurity';

const StripeWeb: React.FC<IStripePaymentSecurity> = (props) => {
  const {
    attributes,
    width,
    height,
    opacity,
    id,
    publicKey: _publicKey,
    accountConnectId,
    locale,
  } = props;

  const { title, rememberCheckbox, email } = attributes;

  const {
    publicKey,
    loading,
    rememberCard,
    cardDefaultInfo,
    onClickCheckBox,
    handlePayment,
    message,
    setMessage,
    isSaveCardAction,
  } = useStripeSecurity(props);

  const styles = createStyles({
    attrs: attributes,
    width,
  });

  const { enabled: titleEnabled = true, text: titleText = 'Card information' } =
    title || {};

  const onChangeCard = useCallback((e) => {
    setMessage({
      error: e?.error?.message,
    });
  }, []);

  const handleSubmit = async ({
    stripe,
    elements,
  }: {
    stripe: Stripe | null;
    elements: StripeElements | null;
  }) => {
    if (loading) {
      return;
    }
    setMessage({});
    const handleCreatePaymentMethod = async () => {
      const stripeCardElement: StripeCardElement =
        elements?.getElement(CardElement)!;
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: stripeCardElement,
        billing_details: {
          email: email.emailBuyer,
        },
      });
      return paymentMethod;
    };
    handlePayment(handleCreatePaymentMethod);
  };

  const renderSubmitButton = useCallback(
    ({ stripe, elements }) => {
      return (
        <SubmitButton
          {...{
            loading,
            handleSubmit: () => handleSubmit({ stripe, elements }),
            isSaveCardAction,
            rememberCard,
            styles,
            text: attributes?.submitButton?.text,
          }}
        />
      );
    },
    [
      loading,
      handleSubmit,
      attributes?.submitButton?.text,
      rememberCard,
      isSaveCardAction,
    ]
  );

  if (cardDefaultInfo?.brand) {
    return (
      <View
        style={{
          width,
          height,
          opacity,
        }}
      >
        <DefaultCard
          {...{
            cardDefaultInfo,
            message,
            rememberCheckbox,
            titleEnabled,
            titleText,
          }}
        />
        {renderSubmitButton({})}
      </View>
    );
  }

  return (
    <View
      style={{
        width,
        height,
        opacity,
        justifyContent: 'space-around',
        alignContent: 'center',
      }}
    >
      {titleEnabled && <Text>{titleText}</Text>}
      <Elements
        stripe={loadStripe(publicKey, {
          stripeAccount: accountConnectId,
        })}
        options={{ locale: locale as StripeElementLocale }}
      >
        <Form
          {...{
            onChangeCard,
            message,
            styles,
            renderSubmitButton,
            rememberCard,
            attributes,
            onClickCheckBox,
          }}
        />
      </Elements>
    </View>
  );
};
export default StripeWeb;
