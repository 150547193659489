import createStyles from '@nocode/components/TorusLogin/style';
import { ITorusLogin } from '@nocode/types';
import React, { useCallback } from 'react';
import { ButtonTorus } from './ButtonTorus';

const getTextOpt = (value: any) =>
  Array.isArray(value) ? value.join('') : value;

const TorusLoginWeb: React.FC<ITorusLogin> = (props) => {
  const { text, upperCase, colors } = props.attributes;
  const styles = createStyles(props);
  const textOptions = Array.isArray(text) ? text[0] : text;
  const { linerColors } = colors || {};

  const handlePress = useCallback(() => {}, []);

  return (
    <ButtonTorus
      handlePress={handlePress}
      text={textOptions}
      styles={styles}
      uppercase={upperCase}
      linerColors={getTextOpt(linerColors)}
      icons={props.attributes.icons}
      tailImage={props.attributes.tailImage}
      leadImage={props.attributes.leadImage}
      width={props.width}
      height={props.height}
    />
  );
};

export default TorusLoginWeb;
