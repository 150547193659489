import store from '@common/redux/store';
import { IAction } from '@common/types/';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { setValues } from '@common/redux/slice/formInput';
import { getFieldValue } from '@common/utils/binding';

export const setInput: (
  action: IAction,
  currentRecord?: any,
  itemIndex?: number
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  itemIndex?: number
) => {
  const dispatch = store.dispatch;
  const data = getFieldValue(
    action.options.source,
    currentRecord,
    undefined,
    itemIndex,
    undefined
  );

  action.options.inputId &&
    dispatch(
      setValues({
        values: {
          [action.options.inputId]: data,
        },
      })
    );

  return {
    status: 'SUCCEED',
  };
};

export default setInput;
