import React from 'react';
import { find, get, isEmpty, cloneDeep, filter, isNil } from 'lodash';
import { View } from 'react-native';

import IconList from '../Icon';
import ImageList from '../Image';
import AvatarList from '../Avatar';
import Toggle from '../Toggle';
import { defaultURL } from '../../../utils/common.constant';

type Props = {
  values: any;
  records?: Record<string, any>;
  id?: string;
  auth?: Record<string, any>;
  externalCollection?: any;
  index?: number;
};

const BINDING_SELECTOR_TYPE = {
  CREATED_OBJECT: 'CREATED_OBJECT',
  CURRENT_USER_SELECTOR: 'CURRENT_USER_SELECTOR',
  ROUTE_PARAM_SELECTOR: 'ROUTE_PARAM_SELECTOR',
  LIST_ITEM_SELECTOR: 'LIST_ITEM_SELECTOR',
  SELECT_VALUE_SELECTOR: 'SELECT_VALUE_SELECTOR',
};

const LeftSection = ({
  values,
  records,
  id,
  auth,
  externalCollection,
  index,
}: Props) => {
  const flattenObject = (obj: Record<string, any>) => {
    const flattened: any = {};

    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });

    return flattened;
  };

  const bindingUrlIsString = (params: Record<string, any>) => {
    const { avatarUrl } = params;

    const cloneSource = cloneDeep(avatarUrl);

    const isObj = find(cloneSource, (item) => typeof item === 'object');

    let newArray = [];

    for (let i = 0; i < cloneSource?.length; i++) {
      const fieldName = get(isObj, 'source.fieldId', '');
      const fieldId = get(isObj, 'source.source.fieldId', '');

      if (typeof cloneSource[i] === 'object') {
        const selectorType = flattenObject(cloneSource[i]);
        if (BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR === selectorType.type) {
          if (fieldName && isEmpty(fieldId)) {
            cloneSource[i] = get(auth, `${fieldName}`);
          }

          if (fieldName && fieldId) {
            cloneSource[i] = get(auth, `${fieldId}.${fieldName}`);
          }

          newArray.push(cloneSource[i]);
        } else if (
          BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR === selectorType.type
        ) {
          if (externalCollection && externalCollection.isThirdParty) {
            const field = externalCollection.fields.find(
              (fieldInfo: any) => fieldInfo.fid === fieldName
            );
            cloneSource[i] =
              get(records, field?.key?.replace('[0]', `[${index}]`)) || '';
          } else {
            const record = find(records, (item) => item._id === id);

            if (fieldName && isEmpty(fieldId)) {
              cloneSource[i] = get(record, `${fieldName}`);
            }

            if (fieldName && fieldId) {
              cloneSource[i] = get(record, `${fieldId}.${fieldName}`);
            }
          }

          newArray.push(cloneSource[i]);
        }
      } else {
        newArray.push(cloneSource[i]);
      }
    }

    return newArray.join('');
  };

  const getValueImage = (sectionValue: any) => {
    const binding = get(sectionValue, 'imageUrl.binding');

    const imageType = get(sectionValue, 'imageUrl.imageType');

    switch (imageType) {
      case 'internal':
        return Array.isArray(binding) ? binding[0] : binding;

      case 'url':
        return bindingUrlIsString(sectionValue);

      case 'uploaded':
        return sectionValue.avatarUrl;

      default:
        return bindingUrlIsString(sectionValue);
    }
  };

  const renderItem = (sectionValue: any) => {
    const placeholderImage =
      sectionValue.imageUrl?.binding?.options?.placeholderImageEnabled &&
      sectionValue.imageUrl?.imageType !== 'uploaded'
        ? sectionValue.imageUrl?.binding?.options?.placeholderImage
        : null;

    const imageCanvas = Array.isArray(sectionValue.imageUrl?.imageUrl)
      ? sectionValue.imageUrl?.imageUrl[0]
      : sectionValue.imageUrl?.imageUrl;

    const getImageUrl = (item: any) => {
      const binding = get(item, 'binding');
      const isObj = filter(binding, (item) => typeof item === 'object');

      if (isEmpty(isObj)) {
        return (
          (Array.isArray(binding) ? binding[0] : binding) || placeholderImage
        );
      }

      if (sectionValue.type === 'image') {
        return imageCanvas || placeholderImage;
      }

      return placeholderImage;
    };

    switch (sectionValue.type) {
      case 'avatar':
        const value =
          getValueImage(sectionValue) || getImageUrl(sectionValue.imageUrl);

        // if (!value) return nullImage('avatar');

        return (
          <AvatarList
            avatarURL={!value ? defaultURL : value}
            placeholder={placeholderImage || defaultURL}
          />
        );
      case 'image':
        const imageUrl = getImageUrl(sectionValue.imageUrl) || imageCanvas;
        // if (!imageUrl) return nullImage('image');

        return (
          <ImageList
            imageURL={!imageUrl ? defaultURL : imageUrl}
            placeholder={placeholderImage}
          />
        );
      case 'toggle':
        return (
          <Toggle
            activeIcon={sectionValue.activeIcon}
            activeColor={sectionValue.activeColor}
            inactiveColor={sectionValue.inactiveColor}
            inactiveIcon={sectionValue.inactiveIcon}
          />
        );
      default:
        return (
          <IconList icon={sectionValue.icon} color={sectionValue.iconColor} />
        );
    }
  };

  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {renderItem(values)}
    </View>
  );
};

export default LeftSection;
