import api from '@common/configs/api';
import { MAILER_TOKEN } from '@common/constants/shared';
import history from '@common/routes/history';
import { LoginFailParams, SigninParams } from '@common/types/';
import { SendEmailParams, UnivaPayParams } from '@common/types/action';
import { apiRecordResponse } from '@common/types/database';
import { AxiosResponse } from 'axios';

export const updateRecordRecordApi = async (
  databaseUuid: string,
  record: any = {},
  recordId: string,
  appUser?: any
) => {
  const appId = history.location.pathname.split('/')[2];
  const recordUpdated: AxiosResponse<apiRecordResponse> = await api({
    method: 'patch',
    url: `v2/database/${databaseUuid}/records/${recordId}`,
    params: {
      appId,
      appUser,
    },
    data: record,
  });

  return recordUpdated.data || {};
};

export const createRecordApi = async (
  databaseUuid: string,
  record: any,
  appUser: any
) => {
  const appId = history.location.pathname.split('/')[2];
  let recordCreated: AxiosResponse<apiRecordResponse> = await api({
    method: 'post',
    url: `v2/database/${databaseUuid}/records`,
    params: {
      appId: appId,
      appUser,
    },
    data: record,
  });

  return recordCreated.data || {};
};

export const signinUser = async (data: SigninParams) => {
  const appId = history.location.pathname.split('/')[2];
  let response: AxiosResponse<LoginFailParams | any> = await api({
    method: 'post',
    url: `v2/database/authmanagement`,
    params: {
      appId: appId,
    },
    data: data,
  });

  return response.data;
};

export const deleteRecordApi = async (
  databaseUuid: string,
  recordId: string,
  appUser: any
) => {
  const appId = history.location.pathname.split('/')[2];
  let result: AxiosResponse<apiRecordResponse> = await api({
    method: 'delete',
    url: `v2/database/${databaseUuid}/records/${recordId}`,
    headers: {
      'Content-Type': 'text/plain;charset=UTF-8',
    },
    params: {
      appId,
      appUser,
    },
    data: recordId,
  });

  return result.data;
};

export const signUpAction = async (data: SigninParams) => {
  const appId = history.location.pathname.split('/')[2];

  let response: AxiosResponse<LoginFailParams | any> = await api({
    method: 'post',
    url: `v2/database/signup`,
    params: {
      appId: appId,
    },
    data: data,
  });

  return response.data;
};

export const stripePayment = async (data: any) => {
  let response: AxiosResponse<any> = await api({
    method: 'post',
    url: 'v2/payment-intent',
    data: data,
  });

  return response.data;
};

export const updateStripePayment = async (data: any) => {
  await api({
    method: 'patch',
    url: 'v2/payment-intent',
    data: data,
  });
};

export const handleSendEmail = async (data: SendEmailParams) => {
  let response: AxiosResponse<any> = await api({
    method: 'post',
    url: '/v2/app-mailer',
    headers: { 'Token-App-Mailer': MAILER_TOKEN },
    data,
  });

  return response.data;
};

export const stripePaymentSecurityActionApi = async (
  data:
    | {
        type: 'create-payment';
        secretKey?: string;
        paymentMethod: string;
        email: string;
        stripeAccount: string;
        amount: string | number;
        description: string;
        currency: string;
        customer?: string;
      }
    | {
        type: 'get-card-info';
        secretKey?: string;
        paymentMethod: string;
      }
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await api({
      method: 'post',
      url: '/stripe-payment-security',
      data,
    });
    return response.data;
  } catch (e: any) {
    if (e.response.data) {
      throw e.response.data;
    }
  }
};

export const univaPayPayment = async (params: UnivaPayParams) => {
  const { data }: any = await api({
    method: 'post',
    url: 'v2/payment-univapay-component',
    data: params,
  });

  return data;
};

export const handleCancelUnivaPaySubscription = async (params: {
  idUnivaPaySubscription: string | null;
  appId: string;
}) => {
  const { idUnivaPaySubscription, appId } = params || {};

  const { data }: any = await api({
    method: 'delete',
    url: `v2/payment-univapay-component/${idUnivaPaySubscription}`,
    params: { appId },
  });

  return data;
};
