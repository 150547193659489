import React, { FC, useMemo, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { ILibraryComponent } from '@common/types/element';
import { pluginSocket } from '@common/configs/plugin-socket';
import {
  PLUGIN_WRAPPER_KEY,
  loadPlugins,
  loadSinglePlugin,
} from '@common/utils/plugin';
import { Platform } from 'react-native';
import { config } from '@common/configs/api';
import Loading from '@common/components/Loading';

import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { getDeviceLocation, getLocaleApp } from '@common/utils/functions';
import { handleBinding } from '@common/components/LibraryComponent/function';
import { useBinding } from '@common/hooks/useBinding';
import { getActions, getApp, getAuth } from '@common/redux/selectors/database';

import createStyles from './style';
import { googleMaploaded } from '@common/redux/selectors/page';
import { setGoogleMaploaded } from '@common/redux/slice/page';
import { componentName } from '@common/constants/shared';
import {
  stripePayment,
  stripePaymentSecurityActionApi,
  updateStripePayment,
  univaPayPayment,
} from '@common/utils/handleActions/func/apiFunction';
import { includes, isNil, pick } from 'lodash';
import { useTorusLogin } from '@common/hooks/useTorusLogin';
import appConfig from '../../../appConfig.json';
import { appDataBaseSocket } from '@common/configs/app-database-socket';
import purchaseProductAll from '@common/utils/handleActions/ActionItem/purchaseProductAll';
import { updateLoggedInUserDispatch } from '@common/utils/handleActions/func/func';
const libraries = require('@nocode/components');

declare global {
  interface Window {
    [key: string]: any;
  }
}

const CusLibraryComponent: FC<ILibraryComponent> = (attributes) => {
  const auth = useSelector(getAuth);
  const appInfo: any = useSelector(getApp);
  const googleMapisloaded = useSelector(googleMaploaded);
  const actions: any = useSelector(getActions);

  const dispatch = useDispatch();
  const loadScriptMap = () => dispatch(setGoogleMaploaded(true));
  const { handleBindingField, handleBindingList } = useBinding();

  const locale = getLocaleApp();

  const devicePrev = getDeviceLocation();

  const attrChild = { ...attributes, devicePrev };

  const styles = createStyles(attributes);

  const bindingAttributes = handleBinding(
    attrChild,
    handleBindingField,
    handleBindingList
  );
  const hasAction =
    includes(
      ['createObject', 'updateObject', 'deleteObject'],
      actions?.isAction?.actionType
    ) && attrChild.databaseUuid === actions.isAction?.databaseId;

  const { changeInput, valueInputs }: UseValueInputProps = useValueInputs({
    ...bindingAttributes.attributes,
    type: attributes.componentName.toLowerCase(), //toggle, switch
    id: attributes.id,
  });

  const [Component, setComponent] = useState<React.FC>();

  const getPluginComponent = async (attributes: {
    componentUrl?: string;
    componentCode?: string;
  }): Promise<React.FC> => {
    try {
      if (Platform.OS === 'web') {
        if (!attributes.componentUrl && attributes.componentCode) {
          const module: any = window[attributes.componentCode]?.dev?.default;
          if (module) return module;
        } else if (attributes.componentUrl && attributes.componentCode) {
          if (window[attributes.componentCode]?.default) {
            return window[attributes.componentCode].default;
          }
          const loadPlugin = await loadSinglePlugin(
            {
              url: attributes.componentUrl,
              code: attributes.componentCode,
            },
            true
          );
          if (loadPlugin) {
            return loadPlugin;
          }
        }
      } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
        // is mobile
        if (attributes.componentCode) {
          const Plugin = libraries[attributes.componentCode];
          if (Plugin?.dev?.default) {
            return Plugin.dev.default;
          }
          if (Plugin?.default) {
            return Plugin.default;
          }
          if (Plugin) {
            return Plugin;
          }
        }
      }
    } catch (e) {}
    return libraries.Error;
  };

  useEffect(() => {
    if (attributes.isPlugin) {
      getPluginComponent({ ...attributes }).then((component: React.FC) => {
        setComponent(() => component);
      });
    } else {
      const component = libraries[attributes.componentName.replace(/ /g, '')];
      if (component) {
        setComponent(() => component);
      } else {
        setComponent(() => libraries.Error);
      }
    }
  }, [attributes.componentName]);

  useEffect(() => {
    if (pluginSocket) {
      pluginSocket.on('buildFileDone', (type: any) => {
        if (attributes.componentCode !== type?.pluginType) return;
        if (Platform.OS === 'web') {
          Promise.all(
            loadPlugins(PLUGIN_WRAPPER_KEY.DEV_PLUGINS, [
              {
                name: type?.pluginType,
                url: `${config.pluginSocket}?userId=${appInfo?.ownerId}&pluginType=${type.pluginType}`,
              },
            ])
          ).then(() => {
            const module: any =
              attributes.componentCode && window[attributes.componentCode].dev;
            setComponent(module.default);
          });
        }
      });
      return pluginSocket.off('buildFileDone', () => {});
    }
  }, []);

  const LoadingComponent = () => (
    <View
      style={{
        height: attributes.attributes.height,
        width: attributes.attributes.width,
        position: 'relative',
      }}
    >
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'transparent',
        }}
      >
        <Loading />
      </View>
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: attributes.attributes?.backgroundColor || '#e5eaff',
          opacity: 0.5,
        }}
      ></View>
    </View>
  );

  if (!Component) {
    return <LoadingComponent />;
  }

  if (attributes.isPlugin) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ changeInput, valueInputs, auth }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (attributes.componentName === componentName.MAP) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ auth: auth }}
          {...{ googleMapisloaded, loadScriptMap }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (attributes.componentName === componentName.BARCODESCANER) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{
            auth: auth,
            changeInput: changeInput,
            valueInputs: valueInputs,
          }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (
    attributes.componentName === componentName.STRIPE_PAYMENT ||
    attributes.componentName === componentName.STRIPE_SUBSCRIPTION
  ) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ locale: locale }}
          {...{
            stripePayment: stripePayment,
            updateStripePayment: updateStripePayment,
            accountConnectId: appInfo?.accountConnectId,
            customerId: auth?.customerId,
            changeInput: changeInput,
            valueInputs: valueInputs,
          }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (attributes.componentName === componentName.STRIPE_PAYMENT_SECURITY) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ locale: locale }}
          {...{ auth: pick(auth, ['databaseId', 'userId']) }}
          {...{
            accountConnectId: appInfo?.accountConnectId,
            updateRecordRecordApi: updateLoggedInUserDispatch,
            handlePayment: stripePaymentSecurityActionApi,
          }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (attributes.componentName === componentName.TORUS_LOGIN) {
    const { loginSuccessWithTorus } = useTorusLogin();
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ changeInput, valueInputs }}
          {...{ auth }}
          {...{ loginSuccessWithTorus, locale }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (attributes.componentName === componentName.LIVESTREAM_CHAT) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ auth: auth }}
          {...{ appConfig: appConfig, socketClient: appDataBaseSocket }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (
    [componentName.UNIVAPAY, componentName.UNIVAPAY_SUBSCRIPTION].includes(
      attributes.componentName
    )
  ) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ locale: locale }}
          {...{
            univaPayPayment: univaPayPayment,
            appId: appInfo.id,
            isConnected: appInfo.isConnectUnivapay,
          }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  if (
    attributes.componentName !== componentName.TOGGLE &&
    attributes.componentName !== componentName.SWITCH &&
    attributes.componentName !== componentName.SELECT_LIST &&
    attributes.componentName !== componentName.SIGNATURE_PAD &&
    attributes.componentName !== componentName.CHANGE_QUANTITY &&
    !attributes.isPlugin
  ) {
    return (
      <View style={styles.container}>
        <Component
          {...bindingAttributes}
          {...{ auth: auth }}
          {...{ hasAction }}
        >
          {attributes?.children}
        </Component>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Component {...bindingAttributes} {...{ changeInput, valueInputs }}>
        {attributes?.children}
      </Component>
    </View>
  );
};

export default CusLibraryComponent;
