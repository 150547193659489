import { PaymentIntent, PaymentMethodResult } from '@stripe/stripe-js';
import { get, isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Platform } from 'react-native';
import { stripeMessage } from '../locale';
import { CardStripeInfo, IStripePaymentSecurity } from './style';

export const useStripeSecurity = (props: IStripePaymentSecurity) => {
  const {
    attributes,
    onPress,
    accountConnectId,
    groupActionId,
    publicKey: _publicKey,
    updateRecordRecordApi,
    handlePayment: _handlePayment,
    auth,
    locale,
  } = props;

  const {
    changedescription,
    paymentOptions,
    email,
    testMode,
    rememberCheckbox,
    submitButton,
    loading: loadingActions,
  } = attributes;

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  const [publicKey, setPublicKey] = useState(_publicKey);

  const [_loading, setLoading] = useState<boolean>(false);
  const [getCardLoading, setGetCardLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{ success?: string; error?: string }>(
    {}
  );
  const [rememberCard, setRememberCard] = useState(false);

  const [cardDefaultInfo, setCardDefaultInfo] = useState<CardStripeInfo | null>(
    null
  );

  const isSaveCardAction = useMemo(() => {
    return submitButton.type === 'saveCard';
  }, [submitButton.type]);

  const loading = useMemo(() => {
    return loadingActions || _loading || getCardLoading;
  }, [loadingActions, _loading, getCardLoading]);

  const isTestMode = useCallback(() => {
    return testMode.enabled && testMode.publishableKey && testMode.secretKey;
  }, []);

  const isValidTestMod = useCallback(() => {
    return (
      testMode.secretKey.startsWith('sk_test') &&
      testMode.publishableKey.startsWith('pk_test')
    );
  }, []);

  const onClickCheckBox = useCallback(() => {
    setRememberCard((value) => !value);
  }, []);

  const handleAction = async (id: string, isAction: any) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});

    if (groupActionId && isNil(isAction)) {
      if (isEmpty(sectionOnpress)) {
        onPress && (await onPress(undefined, { groupActionId }));
      } else {
        onPress && (await onPress(id, { groupActionId }));
      }
    } else {
      onPress && (await onPress(id));
    }
  };

  useEffect(() => {
    if (isCanvas) {
      return;
    }
    setMessage({});
    if (isTestMode() && !isValidTestMod()) {
      setMessage({
        error: stripeMessage({
          locale,
          type: 'stripe_configured',
        }),
      });
      return;
    }
    if (isTestMode() && isValidTestMod()) {
      setPublicKey(testMode.publishableKey);
    }

    const getCardInfo = async () => {
      if (isCanvas) {
        return;
      }
      if (isTestMode() && !isValidTestMod()) {
        return;
      }
      const validCardTokenSaved =
        rememberCheckbox.cardTokenSaved &&
        rememberCheckbox.cardTokenSaved.startsWith('pm_');
      const isRememberCard = rememberCheckbox.enabled && validCardTokenSaved;
      if (!isRememberCard) return;
      if (validCardTokenSaved) {
        const res = await _handlePayment({
          type: 'get-card-info',
          paymentMethod: rememberCheckbox.cardTokenSaved!,
          secretKey: isTestMode() ? testMode.secretKey : undefined,
          stripeAccount: accountConnectId,
        });
        const card = res as CardStripeInfo;
        if (card?.brand) {
          setCardDefaultInfo(card);
        }
      }
    };
    setGetCardLoading(true);
    getCardInfo()
      .catch((error) => {
        setMessage({
          error: `${error.message}`,
        });
      })
      .finally(() => {
        return setGetCardLoading(false);
      });
  }, [rememberCheckbox.cardTokenSaved]);

  const handleSaveCard = useCallback(
    async ({
      paymentMethod,
      cardInfo,
    }: {
      paymentMethod: string;
      cardInfo: CardStripeInfo;
    }) => {
      if (!rememberCard) {
        return;
      }
      const userLoginRecord = auth;
      const payload = {
        [attributes.rememberCheckbox.cardTokenSavedFid!]: paymentMethod,
      };
      await updateRecordRecordApi(
        userLoginRecord.databaseId,
        payload,
        userLoginRecord.userId
      );
      setCardDefaultInfo(cardInfo);
    },
    [rememberCard]
  );

  const handleCheckStatusPaymentIntent = useCallback(async (status) => {
    switch (status) {
      case 'succeeded':
        setMessage({
          success: stripeMessage({ locale, type: 'success' }),
        });
        await handleAction('successActions', attributes?.successActions);
        break;

      case 'processing':
        setMessage({
          success: stripeMessage({ locale, type: 'processing' }),
        });
        await handleAction('successActions', attributes?.successActions);
        break;

      case 'requires_payment_method':
        setMessage({
          error: stripeMessage({ locale, type: 'requires_payment_method' }),
        });
        await handleAction('failedActions', attributes?.failedActions);
        break;

      default:
        setMessage({
          error: stripeMessage({ locale, type: 'requires_payment_method' }),
        });
        await handleAction('failedActions', attributes?.failedActions);
        break;
    }
  }, []);

  const createPayment = useCallback(
    async ({
      payload,
      paymentMethodId,
    }): Promise<{ paymentIntent?: PaymentIntent; success: boolean }> => {
      const response = await onPress('beforeActions', {
        record: {
          ...payload,
          paymentMethodId,
        },
      });

      const purchaseResponse = response.find(
        ({ actionType }: { actionType: string }) =>
          actionType === 'purchase_product_all'
      );

      if (!purchaseResponse) {
        const failure = [...(response || [])].find(
          (action) => action.status !== 'SUCCEED'
        );
        if (failure) {
          await handleAction('failedActions', attributes?.failedActions);
          return {
            success: false,
          };
        }
        const res = (await _handlePayment({
          type: 'create-payment',
          paymentMethodId,
          ...payload,
        })) as { paymentIntent: PaymentIntent };
        return {
          paymentIntent: res?.paymentIntent,
          success: true,
        };
      }

      if (purchaseResponse?.status === 'SUCCEED') {
        setMessage({
          success: stripeMessage({ locale, type: 'success' }),
        });
        return {
          paymentIntent: purchaseResponse.paymentIntent,
          success: true,
        };
      }
      if (purchaseResponse?.paymentIntent) {
        return {
          paymentIntent: purchaseResponse?.paymentIntent,
          success: false,
        };
      }
      await handleAction('failedActions', attributes?.failedActions);
      return {
        success: false,
      };
    },
    []
  );

  const handlePayment = useCallback(
    async (
      handleCreatePaymentMethod: () => Promise<PaymentMethodResult | undefined>
    ) => {
      try {
        setLoading(true);
        const payload = {
          amount: paymentOptions.paymentAmount,
          currency: paymentOptions.typeCurrency,
          receipt_email: email.emailBuyer,
          description: changedescription.description,
          destination: accountConnectId,
          testMode,
        };
        if (
          cardDefaultInfo?.brand &&
          rememberCheckbox.enabled &&
          rememberCheckbox.cardTokenSaved &&
          !isSaveCardAction
        ) {
          const { paymentIntent, success } = await createPayment({
            payload,
            paymentMethodId: rememberCheckbox.cardTokenSaved,
          });

          if (!success) {
            return;
          }
          paymentIntent?.status &&
            (await handleCheckStatusPaymentIntent(paymentIntent!.status));
          return;
        }
        const { paymentMethod, error } =
          (await handleCreatePaymentMethod()) || {};

        if (error) {
          setMessage({
            error: error.message,
          });
          return;
        }
        await handleSaveCard({
          paymentMethod: paymentMethod!.id,
          cardInfo: paymentMethod!.card!,
        });
        if (isSaveCardAction) {
          return;
        }
        const { paymentIntent, success } = await createPayment({
          payload,
          paymentMethodId: paymentMethod!.id,
        });
        if (!success) {
          return;
        }
        paymentIntent?.status &&
          (await handleCheckStatusPaymentIntent(paymentIntent!.status));
      } catch (e: any) {
        setMessage({
          error: stripeMessage({ locale, type: e?.message }),
        });
        await handleAction('failedActions', attributes?.failedActions);
      } finally {
        setLoading(false);
      }
    },
    [
      cardDefaultInfo,
      handleSaveCard,
      isTestMode,
      createPayment,
      handleCheckStatusPaymentIntent,
      rememberCheckbox,
      isSaveCardAction,
    ]
  );

  const handlePaymentMobile = useCallback(
    async (
      paymentMethodId: string,
      confirmPayment: (
        client_secret: string
      ) => Promise<{ paymentIntent?: any; error?: any }>
    ) => {
      try {
        if (isSaveCardAction) {
          return;
        }
        const payload = {
          amount: paymentOptions.paymentAmount,
          currency: paymentOptions.typeCurrency,
          receipt_email: email.emailBuyer,
          description: changedescription.description,
          destination: accountConnectId,
          testMode,
        };
        const { paymentIntent, success } = await createPayment({
          payload,
          paymentMethodId,
        });

        if (!paymentIntent) {
          return;
        }

        if (paymentIntent?.status === 'succeeded') {
          setMessage({
            success: stripeMessage({ locale, type: 'success' }),
          });
          await handleAction('successActions', attributes?.successActions);
          return;
        }

        // // Confirm the payment with the card details
        const { paymentIntent: paymentIntentConfirm, error } =
          await confirmPayment(paymentIntent!.client_secret!);

        if (error) {
          setMessage({
            error: error.localizedMessage || error.message,
          });
          await handleAction('failedActions', attributes?.failedActions);
          return;
        } else if (paymentIntent) {
          paymentIntentConfirm?.status &&
            (await handleCheckStatusPaymentIntent(paymentIntentConfirm.status));
        }
      } catch (e: any) {
        setMessage({
          error: stripeMessage({ locale, type: e?.message }),
        });
        await handleAction('failedActions', attributes?.failedActions);
      } finally {
      }
    },
    [createPayment, handleCheckStatusPaymentIntent, isSaveCardAction]
  );

  return {
    isTestMode,
    isValidTestMod,
    publicKey,
    loading,
    setLoading,
    message,
    setMessage,
    rememberCard,
    handleAction,
    cardDefaultInfo,
    onClickCheckBox,
    handlePayment,
    setCardDefaultInfo,
    handleSaveCard,
    handlePaymentMobile,
    isSaveCardAction,
  };
};
