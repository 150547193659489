import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { sortBy, get, find } from 'lodash';
import qs, { ParsedQuery } from 'query-string';
import BindingComponent from '@common/screens/RenderScreen/BindingComponent';
import {
  ElementType,
  ComponentType,
  ObjectRenderProps,
} from '@common/types/element';
import CusInput from '@common/components/Input';
import WebView from '@common/components/WebView';
import CusTable from '@common/components/Table/index';
import CusImage from '@common/components/Image';
import CusRectangle from '@common/components/Rectangle';
import CusLabel from '@common/components/Label';
import CusLibraryComponent from '@common/components/LibraryComponent';
import CusPassword from '@common/components/Password';
import CusSelect from '@common/components/Select';
import CusDatePicker from '@common/components/DatePicker';
import CusFrom from '@common/components/Form';
import CusYoutube from '@common/components/Youtube';
import CusVimeo from '@common/components/Vimeo';
import CusAdmob from '@common/components/Admob';
import CusLiveStreamCamera from '@common/components/LiveStreamCamera';
import CusImageUploadList from '@common/components/ImageUploadList';
import CusList from '@common/components/List';
import CusImageUpload from '@common/components/ImageUpload';
import CusFileUpload from '@common/components/FileUpload';
import CusGroup from '@common/components/Group';
import CusQuestion from '@common/components/Question';
import CusInAppPurchase from '@common/components/InAppPurchase';
import CusCustomerChat from '@common/components/CustomerChat';
import CusInvisibleShape from '@common/components/InvisibleShape';
import { useLocation } from '@common/routes/hooks';
import { dimensionSelector, pagesSelector } from '@common/redux/selectors/page';
import { DEVICE_NOT_TIMER, HEIGHT_STATUS_BAR } from '@common/constants/shared';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { appInfoSelector } from '@common/redux/selectors/app';
import {
  updateObjectOrigin,
  webComponent,
  mapPropColor,
  getComponentChild,
} from './utils';

export const mapping: Record<string, any> = {
  [ComponentType.input]: CusInput,
  [ComponentType.label]: CusLabel,
  [ComponentType.rectangle]: CusRectangle,
  [ComponentType.image]: CusImage,
  [ComponentType.libraryComponent]: CusLibraryComponent,
  [ComponentType.password]: CusPassword,
  [ComponentType.select]: CusSelect,
  [ComponentType.datePicker]: CusDatePicker,
  [ComponentType.list]: CusList,
  [ComponentType.form]: CusFrom,
  [ComponentType.youtube]: CusYoutube,
  [ComponentType.vimeo]: CusVimeo,
  [ComponentType.admob]: CusAdmob,
  [ComponentType.imageUpload]: CusImageUpload,
  [ComponentType.fileUpload]: CusFileUpload,
  [ComponentType.group]: CusGroup,
  [ComponentType.webView]: WebView,
  [ComponentType.table]: CusTable,
  [ComponentType.question]: CusQuestion,
  [ComponentType.liveStreamCamera]: CusLiveStreamCamera,
  [ComponentType.imageUploadList]: CusImageUploadList,
  [ComponentType.inAppPurchase]: CusInAppPurchase,
  [ComponentType.customerChat]: CusCustomerChat,
  [ComponentType.invisibleShape]: CusInvisibleShape,
};

const ObjectRender = ({
  arrComp,
  isScreen,
  layout,
  keyItem,
  isShowStatusBar,
  parentRecord,
  isFromViewLive,
  currentArrComp,
}: ObjectRenderProps) => {
  const dimension = useSelector(dimensionSelector);
  const appInfo = useSelector(appInfoSelector);

  const insets = useSafeAreaInsets();

  const { search } = useLocation();

  const query: ParsedQuery<any> = qs.parse(search);

  const pages = useSelector(pagesSelector);

  const currentPageInfo = useMemo(
    () =>
      pages.find((o) => {
        return (
          o.screenId.toString() == get(query, 'target', null) ||
          o.screenUuid == query?.target
        );
      }),
    [query]
  );

  const pageModalInfo = useMemo(
    () =>
      pages.find((o) => {
        return (
          o.screenId.toString() == get(query, 'targetModal', null) ||
          o.screenUuid == query?.targetModal
        );
      }),
    [query]
  );

  const modalMetadata = pageModalInfo?.metadata
    ? JSON.parse(pageModalInfo.metadata)
    : [];

  const metadata = currentPageInfo
    ? sortBy(JSON.parse(currentPageInfo.metadata), 'y')
    : [];

  const originX = currentPageInfo ? dimension.width / currentPageInfo.width : 0;
  const originY = currentPageInfo
    ? dimension.height / currentPageInfo.height
    : 0;

  const isWeb = useMemo(() => appInfo?.platform === 'web', [appInfo]);

  const statusBarHeight = isWeb
    ? 0
    : !DEVICE_NOT_TIMER.includes(query.device)
    ? HEIGHT_STATUS_BAR * originX
    : 25 * originX;

  let offsetLeft = layout?.offsetLeft || 0;
  let offsetTop = layout?.offsetTop || 0;
  let sortArrComp: ElementType[] = sortBy(arrComp, 'y');

  const utilityParams = useMemo(
    () => ({
      dimension,
      offsetLeft,
      isScreen,
      insets,
      originX,
      originY,
      layout,
      currentPageInfo,
    }),
    [
      dimension,
      isWeb,
      offsetLeft,
      insets,
      originX,
      layout,
      originY,
      currentPageInfo,
    ]
  );

  const renderComponent = useCallback(() => {
    if (!sortArrComp.length || !metadata.length || !currentPageInfo)
      return <Fragment />;

    let res = [];

    for (let i = 0; i < sortArrComp.length; i++) {
      const params = {
        obj: sortArrComp[i],
        prevObj: sortArrComp[i - 1],
        index: i,
        metadata,
        statusBarHeight,
        offsetTop,
        modalMetadata,
        ...utilityParams,
        isShowStatusBar,
      };

      let data = isWeb
        ? webComponent(params)
        : updateObjectOrigin(params, isFromViewLive);

      if (isFromViewLive && currentArrComp?.length) {
        let currentSortArrComp: ElementType[] = sortBy(currentArrComp, 'y');
        const oldObj =
          find(metadata, { id: currentSortArrComp[i].id }) ||
          getComponentChild(metadata, currentSortArrComp[i].id) ||
          find(modalMetadata, { id: currentSortArrComp[i].id });
        const objectWidth = oldObj
          ? oldObj.width
          : currentSortArrComp[i].width - insets.left;

        data.width = objectWidth * originX;
      }

      let newAttributes = { ...mapPropColor(data?.attributes, appInfo) };

      let obj = {
        ...data,
        attributes: { ...data?.attributes, ...newAttributes },
      };

      let ObjectClass = mapping[obj.type] as FC;
      if (!ObjectClass) {
        console.log(`I don't know how to render ${obj.name}`);
        return <React.Fragment></React.Fragment>;
      } else {
        if (obj?.fixPosition == 'top' && isScreen) {
          delete obj.marginTop;
          const left = obj.marginLeft;
          const zIndex = obj.zIndex;
          delete obj.marginLeft;
          delete obj.zIndex;
          res.push(
            <View
              style={{
                top: obj.y * originY,
                left,
                zIndex,
                position: 'absolute',
              }}
              key={i}
            >
              <BindingComponent
                keyItem={keyItem}
                ObjectClass={ObjectClass}
                key={i + 1}
                parentRecord={parentRecord}
                obj={obj}
              />
            </View>
          );
        } else if (obj?.fixPosition == 'bottom' && isScreen) {
          delete obj.marginTop;
          const left = obj.marginLeft;
          const zIndex = obj.zIndex;
          delete obj.marginLeft;
          delete obj.zIndex;
          res.push(
            <View
              style={{
                bottom: currentPageInfo
                  ? (currentPageInfo.height - obj.height - obj.y) * originY
                  : 0,
                left,
                zIndex,
                position: 'absolute',
              }}
              key={i}
            >
              <BindingComponent
                keyItem={keyItem}
                ObjectClass={ObjectClass}
                obj={obj}
                parentRecord={parentRecord}
              />
            </View>
          );
        } else {
          res.push(
            <BindingComponent
              keyItem={keyItem}
              ObjectClass={ObjectClass}
              key={i + 1}
              obj={obj}
              parentRecord={parentRecord}
            />
          );
        }
      }
    }

    return res;
  }, [
    currentPageInfo,
    originX,
    originY,
    statusBarHeight,
    offsetLeft,
    sortArrComp,
    metadata,
    utilityParams,
  ]);

  return <React.Fragment>{renderComponent()}</React.Fragment>;
};

export default ObjectRender;
