import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';
import { View } from 'react-native-animatable';

let timeOut: any;
const Item = ({ item, attrs, index, isSwipe, zIndex }: any) => {
  const { width } = Dimensions.get('window');

  const [enableScrollViewScroll, setEnableScrollViewScroll] = useState(true);
  const { children } = attrs;
  const [first, ...childrens] = children;
  const scrollViewRef = useRef<any>(null);
  // add attributes to children
  let newChildren = childrens.map((child: any) => {
    return {
      ...child,
      id: child.id + '__' + index,
      selectedItem: {
        itemId: item?._id,
        tableId: attrs.databaseUuid,
        itemIndex: index,
        externalId: item?.id,
        externalRecord: item,
      },
      setEnableScrollViewScroll,
      enableScrollViewScroll,
      groupActionId: attrs?.actions && Object.keys(attrs.actions)[0],
      record: item,
      isInCustomList: true,
      currentListId: attrs.id,
      parentListDatabaseUuid:
        attrs.parentListDatabaseUuid || attrs.databaseUuid,
      parentListItemId: attrs.parentListItemId || attrs.record?._id,
      ...(isNil(child.actions) || isEmpty(child.actions)
        ? {}
        : {
            actions: {
              ...child?.actions,
            },
          }),
    };
  });

  let sectionGroup: Array<any> = [];
  try {
    if (newChildren.length > 1 && newChildren[0].type == 'section') {
      let el = newChildren[0];
      let sectionChildren: Array<any> = [];
      let lastBottomLeft = 0;
      for (let i = 0; i < newChildren.length; i++) {
        if (i == 0) {
          continue;
        } else {
          let o = newChildren[i];
          const currentBottomLeft = o.y + o.height;
          if (o.y >= el.y && currentBottomLeft <= el.y + el.height) {
            if (currentBottomLeft > lastBottomLeft) {
              lastBottomLeft = currentBottomLeft;
            }
          } else {
            sectionGroup.push(o);
          }
        }
      }
      if (lastBottomLeft) el.paddingBottom = el.y + el.height - lastBottomLeft;
      el.children = sectionChildren;
      sectionGroup.push(el);
    } else {
      sectionGroup = newChildren;
    }
  } catch (error) {
    console.log(error);
  }

  const sectionGroupFixPosition = isSwipe
    ? sectionGroup.filter(
        (e) => e?.fixPositioninLivestream || e?.[0]?.fixPositioninLivestream
      )
    : sectionGroup;

  const sectionGroupScrollView = sectionGroup.filter((e) => {
    return !e?.fixPositioninLivestream && !e?.[0]?.fixPositioninLivestream;
  });

  useEffect(() => {
    if (!enableScrollViewScroll) {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(() => {
        scrollViewRef?.current?.scrollToEnd({ animated: true });
      }, 500);
    }
  }, [enableScrollViewScroll]);

  return (
    <>
      <View style={{ ...styles.container, width, zIndex }}>
        {isSwipe && (
          <>
            <ScrollView
              horizontal
              pagingEnabled
              ref={scrollViewRef}
              showsHorizontalScrollIndicator={false}
              scrollIndicatorInsets={{
                top: 10,
                left: 10,
                bottom: 10,
                right: 10,
              }}
              onContentSizeChange={() => {
                scrollViewRef.current.scrollToEnd({ animated: false });
              }}
              onScroll={(e) => {
                const index = Math.round(e.nativeEvent.contentOffset.x / width);
                if (index === 0) {
                  setEnableScrollViewScroll(true);
                }
              }}
              keyboardShouldPersistTaps="handled"
              scrollEnabled={enableScrollViewScroll}
              nestedScrollEnabled={true}
            >
              <View style={{ ...styles.itemScrollWrapper, width }}></View>
              <View style={{ ...styles.itemScrollWrapper, width }}>
                <View style={{ ...styles.objectRenderWrapper, width }}>
                  <ObjectRender
                    keyItem={index}
                    arrComp={sectionGroupScrollView}
                    isScreen={true}
                    layout={{
                      offsetTop: attrs?.y,
                      offsetLeft: attrs?.x,
                      parentWidth: attrs?.width,
                    }}
                    isFromViewLive
                    currentArrComp={sectionGroupScrollView.map((a) => ({
                      ...a,
                    }))}
                  />
                </View>
              </View>
            </ScrollView>
          </>
        )}
        <ObjectRender
          keyItem={index}
          arrComp={sectionGroupFixPosition}
          isScreen={true}
          layout={{
            offsetTop: attrs?.y,
            offsetLeft: attrs?.x,
            parentWidth: attrs?.width,
          }}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: '100%',
    flex: 1,
  },
  itemScrollWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  objectRenderWrapper: {
    position: 'absolute',
    height: '100%',
    flex: 1,
  },
});

export default React.memo(Item);
