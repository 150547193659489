import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import ContentLoader from 'react-content-loader/native';

import queryString from 'query-string';

import Comment from './Comment';

const getCommentChannel = (room: string) => `comment_${room}`;
const getSendChannel = (giftRoom: string) => `gift_send_${giftRoom}`;
const getBuyerChannel = (userId: string) => `user_buy_product_${userId}`;
const getSellerChannel = (userId: string) => `user_seller_product_${userId}`;
const getFollowChannel = (userId: string) => `follow_${userId}`;

const styles = StyleSheet.create({
  container: {
    paddingTop: 22,
    position: 'relative',
    zIndex: 1000,
  },
});

export const SOCKET_STATE = {
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not-connected',
  DISCONNECTED: 'disconnected',
};
export const MESSAGE_TYPE = {
  SEND_GIFT: 'sendGift',
  PRODUCT: 'product',
  JOIN: 'join',
  FOLLOW: 'follow',
};

type MessageType = {
  text: string;
  type?: string;
  purchase?: {
    buyerId: string;
    sellerId: string;
  };
};

const LivestreamChat = (props: any) => {
  const {
    width,
    height,
    attributes = {},
    auth = {},
    appConfig = {},
    socketClient: _socketClient,
    originY,
  } = props;
  const socketClient = _socketClient as SocketIOClient.Socket;

  const { userId, email, accessToken } = auth;
  const {
    channel, // streamerId
    activeColor,
    backgroundColor,
    opacity,
    fontSize,
    color,
    borderRadius,
    borderColor,
    borderWidth,
    streamerInfo, // streamer email
    showChat,
    showListComment,
    textExtend,
    textNarrow,
    heightFullSizeAndroid,
    heightFullSizeIOS,
    heightCommentIOS,
    heightCommentAndroid,
    maximumChat,
    joinColors,
    commentColors,
    giftColors,
    purchaseColors,
    followColors,
    enableScrollToEnd,
  } = attributes;

  const { appId } = appConfig;
  const room = `${channel}${appId}`;
  const giftRoom = `${channel}_${appId}`;

  const channel_name_send = getSendChannel(giftRoom);
  const channel_follow = getFollowChannel(channel);
  const channelSocket = getCommentChannel(room);
  const channel_name_buyer = getBuyerChannel(userId);
  const channel_name_seller = getSellerChannel(userId);

  const listRef = useRef<any>();
  const inputRef = useRef<any>();
  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [rootHeight, setRootHeight] = useState(height);
  const [isFullChat, setIsFullChat] = useState(false);
  const [isNewMessages, setIsNewMessages] = useState({
    isBottom: true,
    isNew: false,
  });

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const addMessage = useCallback(
    (message) => {
      const messageId = message.id;
      const ids = messages.map((e) => e.id);
      const messageExisted = messageId && ids.includes(messageId);
      if (!messageExisted) {
        message.message.text = message.message.text;
        messages.push(message);
        setMessages([...messages]);
      }
      if (!isNewMessages.isBottom) {
        setIsNewMessages({ ...isNewMessages, isNew: true });
      }
    },
    [messages]
  );

  const sendMessage = useCallback((message: MessageType) => {
    if (message.text) {
      socketClient?.emit(
        'create',
        'livestreams',
        {
          Action: 'Comment',
          room: channelSocket,
          userRecordId: userId,
          accessToken,
          message,
        },
        (err: any, ...other: any) => {
          if (err) {
            if (Platform.OS === 'web') {
              alert(err.message);
            } else if (Platform.OS === 'ios' || Platform.OS === 'android') {
              Alert.alert(err.message);
            }
          } else if (message?.type !== MESSAGE_TYPE.JOIN) {
            inputRef.current?.focus();
            setMessageText('');
          } else {
            setMessageText('');
          }
        }
      );
    }
  }, []);

  const alertMaxLength = useCallback((messageText) => {
    if (messageText && messageText.length >= 100) {
      if (Platform.OS === 'web') {
        alert('Enter up to 100 characters');
      }
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        Alert.alert('Enter up to 100 characters');
      }
    }
  }, []);

  const showInput = () => {
    if (Platform.OS === 'web') {
      if (email || streamerInfo) return email === streamerInfo ? false : true;
      else return true;
    } else {
      return email === streamerInfo ? false : true;
    }
  };

  const isOnlySpace = messageText.trim().length === 0;

  useEffect(() => {
    if (channel && userId && !showChat) {
      sendMessage({
        text: '入室しました。',
        type: MESSAGE_TYPE.JOIN,
      });
    }
  }, []);

  const _handleChatsSocket = (data: any) => {
    if (data.room === channelSocket) {
      if (data?.user?.userId !== channel) {
        if (data?.message?.type === MESSAGE_TYPE.PRODUCT) {
          if (
            data?.message?.purchase?.sellerId !== userId &&
            data?.message?.purchase?.buyerId !== userId
          ) {
            addMessage(data);
            return;
          }
          return;
        }
        if (data?.message?.type === MESSAGE_TYPE.FOLLOW) {
          return;
        }

        addMessage(data);
      }
    }
  };

  useEffect(() => {
    if (channel && showListComment) {
      socketClient?.on(channelSocket, _handleChatsSocket);
      return () => {
        socketClient?.off(channelSocket);
      };
    }
  }, [channel, showListComment, socketClient, channelSocket]);

  const _handlePurchaseSocket = useCallback(
    (data: any) => {
      const userBuyerInfo = data?.connections?.data?.userBuyerInfo;
      const userSellerInfo = data?.connections?.data?.userSellerInfo;
      const productInfo = data?.connections?.data?.productInfo;
      if (userId === userBuyerInfo?._id) {
        const buyerNoti = {
          user: userBuyerInfo?.record,
          message: {
            text: `${productInfo?.record?.name} を購入しました`,
            type: MESSAGE_TYPE.PRODUCT,
          },
        };
        addMessage(buyerNoti);
      }
    },
    [addMessage]
  );
  useEffect(() => {
    if (channel && userId && showListComment) {
      socketClient?.on(channel_name_buyer, _handlePurchaseSocket);
      return () => {
        socketClient?.off(channel_name_buyer);
      };
    }
  }, []);

  const _handleSellSocket = useCallback(
    (data: any) => {
      const userSellerInfo = data?.connections?.data?.userSellerInfo;
      const userBuyerInfo = data?.connections?.data?.userBuyerInfo;
      const productInfo = data?.connections?.data?.productInfo;
      if (userId === userSellerInfo?._id) {
        const sellerNoti = {
          user: userBuyerInfo?.record,
          message: {
            text: `${productInfo?.record?.name}が購入されました`,
            type: MESSAGE_TYPE.PRODUCT,
          },
        };
        addMessage(sellerNoti);
      }
    },
    [addMessage]
  );
  useEffect(() => {
    if (channel && userId && showListComment) {
      socketClient?.on(channel_name_seller, _handleSellSocket);
      return () => {
        socketClient?.off(channel_name_seller);
      };
    }
  }, []);

  const _handleGiftSocket = useCallback(
    (data: any) => {
      const userSend = data?.connections?.giftData?.userSendData;
      const giftInfo = data?.connections?.giftData?.giftInfo;
      if (giftInfo) {
        const receiveNoti = {
          user: userSend?.userSendRecordInfo,
          message: {
            text: `${giftInfo?.record?.gift_name} が贈られました🎁`,
            type: MESSAGE_TYPE.SEND_GIFT,
          },
        };
        addMessage(receiveNoti);
      }
    },
    [addMessage]
  );
  useEffect(() => {
    if (channel && userId && showListComment) {
      socketClient?.on(channel_name_send, _handleGiftSocket);
      return () => {
        socketClient?.off(channel_name_send);
      };
    }
  }, []);

  const _handleFollowSocket = useCallback(
    (data: any) => {
      if (data?.connections?.followType === 'follow') {
        const userFollow = data?.connections?.userFollow;
        const userFollowing = data?.connections?.userFollowing;

        const followNoti = {
          user: userFollow?.user,
          message: {
            text: `${userFollowing.user?.username} をフォローしました。`,
            type: MESSAGE_TYPE.FOLLOW,
          },
        };
        addMessage(followNoti);
      }
    },
    [addMessage]
  );

  useEffect(() => {
    if (userId && showListComment) {
      socketClient?.on(channel_follow, _handleFollowSocket);
      return () => {
        socketClient?.off(channel_follow);
      };
    }
  }, []);

  const handlePress = (item: Record<string, any>) => {
    if (item?.message?.type === MESSAGE_TYPE.PRODUCT) {
      return;
    }

    props?.onPress &&
      props?.onPress('onPress', {
        itemId: item?.userRecordId || item?.user?.userId,
      });
  };

  const onChangeSizeChat = () => {
    if (!showListComment) return;
    if (!heightFullSizeAndroid) return;
    if (!heightFullSizeIOS) return;
    if (!maximumChat) return;
    setIsFullChat(!isFullChat);
    let size = height * 2;
    const countChat = +maximumChat;
    const maxChat = +maximumChat + 1;

    const heightCmtAndroid =
      +heightCommentAndroid > 0 ? +heightCommentAndroid : 44;
    const heightCmtIOS = +heightCommentIOS > 0 ? +heightCommentIOS : 42;

    if (
      Platform.OS === 'android' &&
      typeof +heightFullSizeAndroid === 'number'
    ) {
      const heightCalAndroid =
        !isFullChat && showListComment && messages.length < maxChat
          ? (countChat - messages.length) * heightCmtAndroid +
            (maxChat - messages.length)
          : 0;

      size = +heightFullSizeAndroid * originY - heightCalAndroid * originY;
    } else if (typeof +heightFullSizeIOS === 'number') {
      const heightCalIOS =
        !isFullChat && showListComment && messages.length < maxChat
          ? (countChat - messages.length) * heightCmtIOS -
            (countChat - messages.length)
          : 0;
      size = +heightFullSizeIOS * originY - heightCalIOS * originY;
    }

    if (!isFullChat) {
      setRootHeight(size);
    } else {
      // setTimeout(() => {
      //   listRef.current?.scrollToEnd({ animated: true });
      // }, 100);
      setRootHeight(height);
    }
  };

  // const onScrollToEnd = () => {
  //   setIsNewMessages({ isBottom: false, isNew: false });
  //   setTimeout(() => {
  //     listRef.current?.scrollToEnd({ animated: true });
  //   }, 100);
  // };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: any) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  return (
    <View
      style={[
        styles.container,
        {
          width,
          height: rootHeight,
          borderRadius,
          opacity,
          borderColor,
          borderWidth,
          justifyContent: 'flex-end',
        },
      ]}
      key={`livestream_chat_${showChat}_${showListComment}`}
    >
      {showListComment && (
        <>
          {messages.length > 3 && +maximumChat > 0 && (
            <View style={{ paddingLeft: 10 }}>
              <TouchableOpacity
                onPress={() => onChangeSizeChat()}
                style={{ width: 80, height: 25 }}
              >
                {isFullChat ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Icon name="chevron-down" size={15} color={'#d9d9d9'} />
                    <Text style={{ paddingLeft: 10, color: '#d9d9d9' }}>
                      {textNarrow}
                    </Text>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Icon name="chevron-up" size={15} color={'#d9d9d9'} />
                    <Text style={{ paddingLeft: 10, color: '#d9d9d9' }}>
                      {textExtend}
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
            </View>
          )}
          <View
            style={{
              ...(showChat
                ? {
                    height: rootHeight - 20,
                  }
                : {
                    height: rootHeight - 70,
                    marginBottom: 10,
                  }),
            }}
          >
            {!target && Platform.OS === 'web' ? (
              [...Array(Math.round(rootHeight / 60))?.fill(1)]?.map((e, i) => (
                <ContentLoader
                  width={width}
                  height={rootHeight - (showChat ? 70 : 20)}
                  backgroundColor="#d9d9d9"
                  foregroundColor="#ededed"
                  key={i}
                >
                  <rect
                    x="50"
                    y="15"
                    rx="5"
                    ry="5"
                    width={width - 100}
                    height="15"
                  />
                  <rect
                    x="50"
                    y="39"
                    rx="5"
                    ry="5"
                    width={width - 120}
                    height="9"
                  />
                  <rect x="0" y="10" rx="100" ry="100" width="40" height="40" />
                </ContentLoader>
              ))
            ) : (
              <ScrollView
                scrollEventThrottle={16}
                style={{ flex: 1, zIndex: 1000 }}
                scrollEnabled={true}
                nestedScrollEnabled={true}
                ref={(ref) => {
                  listRef.current = ref;
                }}
                showsVerticalScrollIndicator={false}
                onContentSizeChange={() => {
                  if (isNewMessages.isBottom) {
                    setTimeout(() => listRef.current?.scrollToEnd(), 100);
                  }
                }}
                onScroll={({ nativeEvent }) => {
                  if (isCloseToBottom(nativeEvent)) {
                    setIsNewMessages({ isBottom: true, isNew: false });
                  } else {
                    setIsNewMessages({ ...isNewMessages, isBottom: false });
                  }
                }}
              >
                {Array.isArray(messages)
                  ? messages.map((item) => (
                      <Comment
                        item={item}
                        textStyle={{ color, fontSize }}
                        width={width}
                        chatBgColor={backgroundColor}
                        colors={{
                          joinColors,
                          commentColors,
                          giftColors,
                          purchaseColors,
                          followColors,
                        }}
                        onPress={() => handlePress(item)}
                      />
                    ))
                  : null}
              </ScrollView>
            )}
            {/* {isNewMessages.isNew && (
              <TouchableOpacity
                onPress={() => {
                  onScrollToEnd();
                }}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  backgroundColor: '#fff',
                  borderRadius: 10,
                  padding: 5,
                  zIndex: 99999999,
                }}
              >
                <Text>new messages</Text>
              </TouchableOpacity>
            )} */}
          </View>
        </>
      )}
      {showInput() && showChat ? (
        <View
          style={{
            width: '100%',
            height: 40,
            display: 'flex',
            flexDirection: 'row',
            // padding: 10,
            paddingRight: 5,
            paddingLeft: 5,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <TextInput
            ref={(ref) => {
              inputRef.current = ref;
            }}
            style={{
              backgroundColor: '#EBECED',
              marginRight: 10,
              borderWidth: 1,
              borderRadius: 10,
              paddingLeft: 5,
              flex: 1,
              color: 'black',
              height: 40,
              borderColor: 'white',
            }}
            placeholderTextColor={'#7b7b7b63'}
            onChange={({ nativeEvent: { eventCount, target, text } }) => {
              alertMaxLength(text);
              setMessageText(text);
            }}
            placeholder="コメント入力"
            maxLength={100}
            onSubmitEditing={(_) => {
              if (!isOnlySpace) {
                sendMessage({
                  text: messageText,
                });
              }
              setMessageText('');
            }}
            value={messageText}
          />

          <TouchableOpacity
            style={{
              backgroundColor: !isOnlySpace ? activeColor : '#adadad',
              borderRadius: 5,
              width: 50,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              if (!isOnlySpace) {
                sendMessage({
                  text: messageText,
                });
              }
              setMessageText('');
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: '#fff',
                lineHeight: fontSize,
                margin: 6,
              }}
            >
              送信
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
};

export default memo(LivestreamChat);
