import { useBinding } from '@common/hooks/useBinding';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { IInput } from '@common/types/element';
import { get, isNil, replace, debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import createStyles from './style';

const CusInput: FC<IInput> = (attributes) => {
  const recordId = get(attributes, 'record._id');
  const keyItem = get(attributes, 'keyItem');
  const getTableId = get(attributes, 'selectedItem.tableId');
  const currentRecord = useSelector(getCurrentRecord) || {};
  const { handleBindingField } = useBinding();

  const { changeInput, valueInputs }: UseValueInputProps = useValueInputs({
    ...attributes,
    id: !isNil(keyItem)
      ? `${attributes.id}`
      : recordId
      ? `${attributes.id}__${recordId}`
      : attributes.id,
  });

  const styles = createStyles(attributes);
  const keyBoardType = getKeyBoardType(
    attributes.inputType,
    attributes.isInteger
  );

  const key: any = attributes.id;

  const isPassword = attributes.inputType === 'password';
  const isEmail = attributes.id === 'email';
  const isName = attributes.id === 'fullName';

  const checkMultiline = () => {
    if ((isPassword && isEmail && isName) || attributes.multiline === false) {
      return false;
    } else {
      return attributes.multiline;
    }
  };

  const [valueInput, setValueInput] = useState<string | undefined>(undefined);

  const maxLength = handleBindingField(
    attributes?.maxLength?.formula,
    attributes.record || currentRecord[getTableId]
  );

  const handleChange = useCallback(
    debounce((value) => {
      // const numberRegex = new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/);
      const floatRegex = /^[+-]?\d*(?:[.,]\d*)?$/;

      const isInt = attributes.isInteger;

      if (isInt) {
        changeInput(convertNumberInt(value));
        return;
      }

      const isChangeValue =
        attributes.inputType !== 'number' ||
        (attributes.inputType === 'number' && floatRegex.test(value));

      if (isChangeValue) {
        changeInput(value);
      }
    }, 300),
    [attributes.inputType, changeInput, key, attributes.isInteger]
  );
  const max = !isNaN(maxLength) ? +maxLength : undefined;

  useEffect(() => {
    setValueInput(
      attributes.inputType === 'tel'
        ? convertNumberToTel(valueInputs?.toString())
        : undefined
    );
  }, [valueInputs]);

  return (
    <TouchableOpacity activeOpacity={1} style={styles.container}>
      <View style={styles.wrapper}>
        <TextInput
          style={styles.text}
          value={valueInput}
          onChangeText={(value) => {
            setValueInput(undefined);
            handleChange(value);
          }}
          placeholder={formatValue(attributes.placeholder)}
          placeholderTextColor={attributes.placeholderColor}
          keyboardType={keyBoardType}
          multiline={checkMultiline()}
          secureTextEntry={isPassword}
          maxLength={max}
          defaultValue={valueInputs}
          textAlignVertical={
            checkMultiline() && Platform.OS === 'android' ? 'top' : 'auto'
          }
        />
      </View>
    </TouchableOpacity>
  );
};

const convertNumberToTel = (number: string) => {
  const numberArr = number?.split('-').join('').split('') || [];
  let numberNew: string[] = [];

  numberArr.forEach((i: string, index: number) => {
    if (index < 3) return numberNew.push(i);
    if ((index - 3) % 4 === 0) return numberNew.push('-', i);
    return numberNew.push(i);
  });

  return numberNew.join('');
};

const convertNumberInt = (number: string) => {
  if (!number) return number;
  return number.match(/\d+/g)?.join('');
};

const getKeyBoardType = (inputType: string, isInteger?: boolean) => {
  if (isInteger) {
    if (Platform.OS === 'android') {
      return 'number-pad';
    }
    return 'number-pad';
  }

  switch (inputType) {
    case 'email':
      return 'email-address';

    case 'number':
    case 'tel':
      return 'numeric';

    default:
      return 'default';
  }
};

const formatValue = (str: any) =>
  replace(str, /<br ?\/?>/g, '\n')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/<\/?p[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/<\s*\/?\s*span\s*.*?>/g, '')
    .replace(/<[^>]*>/g, '\n')
    .replace(/&amp;/g, '&')
    .replace(/\n*$/g, '');

export default CusInput;
