import React, { useMemo, useState, Fragment } from 'react';
import { Card } from 'react-native-paper';
import {
  View,
  Image,
  TouchableOpacity,
  Text,
  ActivityIndicator,
} from 'react-native';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { getPlaceholder, imageUrl } from '../../utils/handleCardDetail';
import { parseText } from '@nocode/components/func';
import { defaultURL } from '../../utils/common.constant';
import createStyles from '@nocode/components/CustomHorizontalList/style';

type CardDetail = {
  title: string;
  tag: { icon: string; text: string };
  _id: string;
};

type Props = {
  cardItem: CardDetail;
  attributes: Record<string, any>;
  index: number;
  heightCard: number | undefined;
  onPress?: (id: string | undefined, options: Record<string, any>) => void;
  isLast: boolean;
  cardMargin?: {
    marginBottom: number;
    marginTop: number;
    marginRight: number;
    marginLeft: number;
  };
  records?: any;
};

const CardItem = ({
  cardItem,
  attributes,
  index,
  heightCard,
  onPress,
  isLast,
  cardMargin,
  records,
}: Props) => {
  const styles = createStyles(attributes, cardMargin, isLast, index);

  const [loading, setLoading] = useState(false);

  const compera = (comparator: string, a: string, b: string) => {
    let checkCompera = false;

    switch (comparator) {
      case '==':
        if (a === b) checkCompera = true;
        break;
      case '!=':
        if (a !== b) checkCompera = true;
        break;
      case 'contains':
        if (a.includes(b)) checkCompera = true;
        break;
      case 'notContains':
        if (!a.includes(b)) checkCompera = true;
        break;
      case '>':
        if (a > b) checkCompera = true;
      case '<':
        if (a < b) checkCompera = true;
        break;
      case '>=':
        if (a >= b) checkCompera = true;
      case '<=':
        if (a <= b) checkCompera = true;
        break;
      default:
        break;
    }
    return checkCompera;
  };

  const checkFilter = (data: any, tagFilter: any, id: string) => {
    let isShow = false;
    if (data && data.length && id) {
      const item = data.find((ite: any) => ite._id === id);

      if (tagFilter.field.value && tagFilter.value) {
        isShow = compera(
          tagFilter.comparator.value,
          item[tagFilter.field.value]?.toString(),
          tagFilter.value.toString()
        );
      }
    }

    return isShow;
  };

  const isShowTag: any = useMemo(() => {
    return checkFilter(records, attributes?.tag?.option, cardItem?._id);
  }, [records, attributes?.tag?.option, cardItem?._id]);

  const handlePress = async (id: string, item: Record<string, any>) => {
    if ((attributes?.tag?.disableClick && isShowTag) || loading) return;

    const groupActionId = get(attributes, 'groupActionId', '');
    const records = get(attributes, 'records', []);

    if (id === 'onPress') {
      setLoading(true);
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          (await onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
          }));
      } else {
        onPress &&
          (await onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
            externalRecord: records[item.indexRecord],
          }));
      }
      setLoading(false);
    }
  };

  const isCanvas =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  return (
    <>
      <Card
        style={[
          styles.cardWrapper,
          {
            ...(heightCard && !isCanvas && { height: heightCard }),
          },
        ]}
        onPress={() => {
          handlePress('onPress', {
            itemId: cardItem?._id,
            indexRecord: index,
          });
        }}
      >
        <Image
          style={styles.cardImage}
          defaultSource={{
            uri: getPlaceholder(attributes?.cardCover) || defaultURL,
          }}
          source={{
            uri:
              imageUrl(attributes?.cardCover, cardItem) ||
              getPlaceholder(attributes?.cardCover) ||
              defaultURL,
          }}
          resizeMode={attributes?.cardCover?.image?.imageResize || 'cover'}
        />

        {attributes?.tag?.enabled && isShowTag && (
          <TouchableOpacity
            style={[
              styles.cardWrapperTag,
              !attributes?.tag?.disableClick &&
                isShowTag && { borderColor: '#ff0000', borderWidth: 1 },
            ]}
            onPress={() => {
              handlePress('onPress', {
                itemId: cardItem?._id,
                indexRecord: index,
              });
            }}
          >
            <Text style={styles.cardTag} ellipsizeMode="tail" numberOfLines={1}>
              {parseText(cardItem?.tag)}
            </Text>
          </TouchableOpacity>
        )}
        <View style={styles.cardTitleWrapper}>
          <Text
            style={styles.cardTitle}
            ellipsizeMode="tail"
            numberOfLines={attributes?.title?.titleLineNum === 1 ? 1 : 2}
          >
            {parseText(cardItem?.title)}
          </Text>
        </View>
      </Card>
      {loading ? (
        <View
          style={[
            styles.cardWrapper,
            {
              ...(heightCard && !isCanvas && { height: heightCard }),
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              height: attributes?.cardCoverStyle?.height + 4,
              borderWidth: 0,
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: 'rgba(255, 255, 255, 0)',
            },
          ]}
        >
          <ActivityIndicator color={'#4259AC'} />
        </View>
      ) : (
        <Fragment />
      )}
    </>
  );
};

export default CardItem;
