import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { excuteBinding } from '@common/hooks/useBinding';
import { getCurrentRecord } from '@common/redux/selectors/database';
import useLiveStream from '@common/hooks/useLiveStream';
import store from '@common/redux/store';
import ViewLiveWeb from '@common/components/LiveStreamCamera/WebSource/ViewLiveWeb';
import StartLiveWeb from '@common/components/LiveStreamCamera/WebSource/StartLiveWeb';
import { changeValueLiveStream } from '@common/redux/slice/liveStream';

const LiveStreamCamera: React.FC<any> = (attrs) => {
  const { defaultScreen, streamId, thumbnail, title, id } = attrs;

  const state: any = store.getState();
  const profile = state?.auth?.profile || null;

  return (
    <>
      {defaultScreen === 'view' ? (
        <ViewLiveWeb attrs={attrs} profile={profile}></ViewLiveWeb>
      ) : (
        <StartLiveWeb
          infoUser={profile}
          thumbnail={thumbnail}
          title={title}
          attrs={attrs}
        ></StartLiveWeb>
      )}
    </>
  );
};

export default React.memo(LiveStreamCamera);
