import React, { memo } from 'react';
import { ActivityIndicator, Text, TouchableOpacity } from 'react-native';

interface Props {
  loading?: boolean;
  isSaveCardAction: boolean;
  rememberCard: boolean;
  handleSubmit: VoidFunction;
  styles: any;
  text: string;
}
const SubmitButton: React.FC<Props> = ({
  loading,
  isSaveCardAction,
  rememberCard,
  handleSubmit,
  styles,
  text,
}) => {
  const inactiveStyle = isSaveCardAction && !rememberCard;
  const disabled = loading || inactiveStyle;
  return (
    <TouchableOpacity
      style={[
        inactiveStyle ? styles.clickInactive : styles.clickActive,
        { flexDirection: loading ? 'row' : 'column' },
      ]}
      onPress={() => handleSubmit()}
      disabled={disabled}
    >
      {loading && (
        <ActivityIndicator
          size="small"
          color="white"
          style={{ paddingRight: 10 }}
        />
      )}
      <Text style={styles.label}>{`${text}`}</Text>
    </TouchableOpacity>
  );
};
export default memo(SubmitButton);
