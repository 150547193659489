import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { get } from 'lodash';
import React from 'react';
import { Platform, Text, View } from 'react-native';
import { Checkbox } from 'react-native-paper';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      lineHeight: '1.5715',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: 'red',
    },
  },
  hidePostalCode: true,
};

const Form = (props: {
  onChangeCard: (e: any) => void;
  message: { error?: string; success?: string };
  styles: {
    container: { width: any; opacity: any };
    errorText: { color: string };
    successText: { color: string };
    title: { fontFamily: string; marginBottom: number };
    wrapperInput: {
      padding: number;
      borderRadius: number;
      borderColor: string;
      borderWidth: number;
      minHeight: number;
    };
    block: { borderColor: string; borderWidth: number; borderRadius: number };
    clickActive: {
      backgroundColor: any;
      borderRadius: any;
      justifyContent: 'center';
      borderColor: any;
      marginTop: number;
      minHeight: number;
      alignItems: 'center';
    };
    clickInactive: {
      backgroundColor: any;
      borderRadius: any;
      justifyContent: 'center';
      borderColor: any;
      marginTop: number;
      minHeight: number;
      alignItems: 'center';
    };
    label: {
      fontFamily: string;
      fontSize: number;
      color: any;
      textTransform: 'uppercase';
    };
    group: {
      position: 'absolute';
      top: number;
      flexDirection: 'row';
      justifyContent: 'space-between';
      width: string;
      paddingHorizontal: number;
    };
    placeHolder: { color: string };
    rememberCard: {
      display: 'flex';
      flexDirection: 'row';
      justifyContent: 'center';
      alignItems: 'center';
      height: number;
      position: 'relative';
    };
    rememberCardIosUncheck: {
      marginRight: number;
      width: number;
      height: number;
      borderWidth: number;
      borderRadius: number;
      borderColor: string;
    };
  };
  renderSubmitButton: ({ stripe, elements }: any) => JSX.Element;

  rememberCard: boolean;
  attributes: {
    successActions?: (() => Promise<void>) | undefined;
    failedActions?: (() => Promise<void>) | undefined;
    fontFamily: string;
    inactiveColor: string;
    activeColor: string;
    color: string;
    rememberCheckbox: {
      enabled: boolean;
      text: string;
      cardTokenSaved?: string | undefined;
      cardTokenSavedFid?: string | undefined;
      customer?: string | undefined;
      customerFid?: string | undefined;
      icon: string;
    };
    testMode: { enabled: boolean; publishableKey: string; secretKey: string };
    email: { emailBuyer: string };
    changedescription: { description: string };
    paymentOptions: { paymentAmount: number; typeCurrency: 'jpy' | 'usd' };
    submitButton: { text: string; borderRadius: number };
    opacity: number;
    title: { enabled: boolean; text: string };
  };
  onClickCheckBox: () => void;
}): JSX.Element => {
  const {
    onChangeCard,
    message,
    styles,
    renderSubmitButton,
    rememberCard,
    attributes,
    onClickCheckBox,
  } = props;
  const { rememberCheckbox } = attributes;

  const elements = useElements();
  const stripe = useStripe();
  return (
    <>
      <View
        style={{
          borderColor: '#f3f3f3',
          borderRadius: 10,
          borderWidth: 1,
          height: 40,
          justifyContent: 'center',
          paddingLeft: 5,
        }}
      >
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChangeCard} />{' '}
      </View>
      {message.error && <Text style={styles.errorText}>{message.error}</Text>}
      {message.success && (
        <Text style={styles.successText}>{message.success}</Text>
      )}
      {rememberCheckbox.enabled && (
        <View style={styles.rememberCard}>
          <View
            style={[
              Platform.OS === 'ios' && rememberCard !== true
                ? styles.rememberCardIosUncheck
                : {},
            ]}
          >
            <Checkbox
              status={rememberCard === true ? 'checked' : 'unchecked'}
              uncheckedColor={get(attributes, 'inactiveColor', 'orange')}
              onPress={onClickCheckBox}
              color={get(attributes, 'activeColor', 'orange')}
            />
          </View>
          <Text style={[styles.title, { fontSize: 12 }]}>
            {rememberCheckbox?.text}
          </Text>
        </View>
      )}
      {renderSubmitButton({ stripe, elements })}
    </>
  );
};

export default Form;
