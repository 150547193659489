import useAvailability from '@common/hooks/useVisibility';
import { getValueFields } from '@common/redux/selectors/formInput';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import {
  find,
  get,
  isNil,
  pick,
  isEmpty,
  isArray,
  includes,
  omit,
} from 'lodash';
import React, { FC, Fragment, memo, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  databaseSelector,
  getAuth,
  getCurrentRecord,
  getDataSourceStore,
  getListDatabases,
  reFreshStatusSelector,
} from '@common/redux/selectors/database';
import {
  getLocaleApp,
  getSortAndFilterData,
  getSortCondition,
  SortAndFilterType,
  SortConditionType,
} from '@common/utils/functions';
import useFilter from '@common/hooks/useFilter';
import {
  getParentListRecordComponent,
  getRecordExternalCollection,
  getRecords,
} from '@common/utils/database';
import { updateExternal } from '@common/redux/slice/externalCollection';
import Loading from '@common/components/Loading';
import { IRecord } from '@common/types/database';
import BindingNewComponent from './BindingNewComponent';
import { setLoadingAction } from '@common/redux/slice/action';
import { paginate } from '@common/utils/handleBinding/function';
import { useRefreshTable } from '@common/hooks/databaseListener/automaticList';
import ActionWrapper from '@common/screens/LoadingAction';
import { IUseFilterType } from '@common/types/';

import { RootState } from '@common/redux/store';
import { getListRecord } from '@common/redux/selectors/listRecords';
import { setListRecordValue } from '@common/redux/slice/listRecords';
import { externalCollectionSelector } from '@common/redux/selectors/externalCollection';

type Props = {
  keyItem?: number;
  ObjectClass: FC;
  obj: any;
  parentRecord?: IRecord;
};

type BindingDataProps = {
  keyItem?: number;
  ObjectClass: FC;
  obj: any;
  databaseUuid: string;
  onPress?: (actionRef: string, options?: { itemId: string }) => void;
  loading?: boolean;
  loadingAction?: boolean;
  parentRecord?: IRecord;
};

const isShowLoadingAction = [
  'label',
  'image',
  'section',
  'Icon',
  'ActionButton',
  'Button',
];

const BindingComponent = ({
  ObjectClass,
  obj,
  keyItem,
  parentRecord,
}: Props) => {
  const isNewList = [
    'HorizontalCardList',
    'AvatarList',
    'ChipList',
    'RankingList',
  ].includes(obj.componentName);

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const currentRecord = useSelector(getCurrentRecord) || {};

  const locale = getLocaleApp();

  const visibilityConfig =
    !isEmpty(obj?.visibilities) && isArray(obj?.visibilities)
      ? obj?.visibilities
      : obj.visibility;

  const isDisplay = useAvailability(
    visibilityConfig,
    obj?.record,
    getParentListRecordComponent(obj)
  );
  const databaseUuid =
    obj?.dataBinding?.tableId ||
    obj?.dataBinding?.source?.tableId ||
    obj?.attributes?.items?.source?.tableId ||
    obj?.attributes?.database?.idCollectionSelected ||
    obj?.database?.source?.tableId ||
    obj?.attributes?.markerCollection?.source?.tableId;

  let refresh =
    get(obj, 'dataBinding.source.options.listenForChanges', false) ||
    get(obj, 'attributes.items.source.options.listenForChanges', false);

  useRefreshTable(databaseUuid, refresh);

  if (!isDisplay)
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
        }}
        pointerEvents="none"
      />
    );

  if (!ObjectClass) return <Fragment />;

  const handlePress = async (
    actionRef: string,
    options?: {
      itemId?: string;
      groupActionId?: string;
      indexRecord?: number;
      externalId?: any;
      externalRecord?: any;
      record?: IRecord;
      payloadComponent?: Record<string, any>;
    }
  ) => {
    const { payloadComponent } = options || {};
    // get actions
    let arrayAction: Array<any> = [];

    const isObjList = includes(
      [
        'SimpleList',
        'CardList',
        'list',
        'CustomHorizontalList',
        'horizontal-card-list',
      ],
      obj.componentName
    );
    // check action ref
    if (
      (actionRef &&
        (obj.attributes[actionRef] ||
          obj.attributes[actionRef]?.action ||
          obj.attributes[actionRef]?.onPress)) ||
      obj.componentName === 'Table'
    ) {
      let attActionsId =
        obj.attributes[actionRef]?.actionId ||
        obj.attributes[actionRef]?.action?.actionId ||
        obj.attributes[actionRef]?.onPress?.actionId ||
        null;
      if (obj.componentName === 'Table') attActionsId = actionRef;
      const actionComponent = obj?.actions?.[attActionsId];

      arrayAction = actionComponent?.actions || [];
    } else {
      if (options?.groupActionId) {
        const actionComponent = obj?.actions?.[options?.groupActionId];

        arrayAction = actionComponent?.actions || [];
      } else {
        if (
          obj?.isInCustomList ||
          !isObjList ||
          obj?.componentName === 'list'
        ) {
          let actions = obj?.actions && Object.values(obj?.actions);
          if (
            actions?.length &&
            (!actionRef ||
              typeof actionRef === 'object' ||
              !isNil(obj.attributes[actionRef]))
          ) {
            arrayAction = actions[0].actions;
          }
        } else {
          if (isObjList) {
            let actions: Record<string, any>[] = obj?.actions;
            const actionId = obj?.attributes?.action?.actionId;
            if (
              !isEmpty(actions) &&
              !isNil(actions) &&
              !isNil(actionId) &&
              !isNil(actions[actionId])
            ) {
              arrayAction = actions[actionId].actions;
            }
          }
        }
      }
    }

    // get current item in list
    const recordId =
      options?.itemId || obj?.selectedItem?.itemId || obj?.record?._id;
    const itemTableId =
      obj?.selectedItem?.tableId || obj?.record?.databaseUuid || databaseUuid;

    let currentData: any = {};

    if (itemTableId && recordId && arrayAction.length) {
      const records: Record<string, any> = await getRecords(
        itemTableId || databaseUuid
      );

      if (records) {
        currentData = find(records, {
          _id: recordId,
        });
      }
    }

    if (obj.isInCustomList && !isObjList) {
      const records: Record<string, any> = await getRecords(
        obj.parentListDatabaseUuid
      );
      if (records) {
        const parentListItem = find(records, {
          _id: obj.parentListItemId,
        });
        currentData = parentListItem
          ? [parentListItem, currentData]
          : currentData;
      }
    }

    if (isObjList && obj.isInCustomList) {
      const records: Record<string, any> = await getRecords(databaseUuid);
      if (records) {
        const parentListItem = find(records, {
          _id: recordId,
        });
        currentData = parentListItem
          ? [
              {
                databaseId: itemTableId,
                record: obj.record,
                _id: obj.record?._id,
              },
              parentListItem,
            ]
          : {
              databaseId: itemTableId,
              record: obj.record,
              _id: obj.record?._id,
            };
      }
    }

    const itemIndex = get(obj, 'selectedItem.itemIndex');

    const externalId = get(obj, 'selectedItem.externalId');

    const externalIndex =
      itemIndex !== undefined ? itemIndex : options?.indexRecord;

    const externalUpdateId =
      externalId !== undefined ? externalId : options?.externalId;

    const externalRecord = {
      databaseUuid:
        obj.selectedItem?.tableId ||
        get(obj, 'attributes.items.source.tableId') ||
        get(obj, 'dataBinding.source.tableId'),
      externalRecord: options?.externalRecord || obj?.record,
    };

    const parentRecordList = getParentListRecordComponent(obj);

    let currentRecord = currentData || obj?.record;
    const recordCustomButton = options?.record;

    if (isEmpty(currentRecord)) currentRecord = options?.externalRecord || {};

    // excute actions
    if (arrayAction.length) {
      setLoading(true);
      const resultPromise = await actionPromise({
        actionComponent: arrayAction,
        currentRecord,
        itemIndex,
        locale,
        isForm: undefined,
        externalIndex: externalIndex?.toString(),
        externalUpdateId,
        externalRecord,
        parentRecordList,
        formId: '',
        recordCustomButton,
        componentId: obj?.id || '',
        payloadComponent,
        getAllResult: true,
      });
      dispatch(setLoadingAction({ componentId: obj?.id, status: false }));
      setLoading(false);
      arrayAction.forEach((item, index) => {
        if (resultPromise?.[index]?.status) {
          arrayAction[index] = {
            ...item,
            ...resultPromise[index],
          };
        }
      });
      return arrayAction;
    }
  };

  if (
    loading &&
    isShowLoadingAction.includes(obj.componentName) &&
    obj.enableLoading
  ) {
    return <ActionWrapper obj={obj} />;
  }

  if (databaseUuid) {
    if (isNewList) {
      return (
        <BindingNewComponent
          ObjectClass={ObjectClass}
          obj={obj}
          handlePress={handlePress}
          databaseUuid={databaseUuid}
          keyItem={keyItem}
          zIndex={obj.zIndex}
        />
      );
    }

    return (
      <BindingDataComponent
        ObjectClass={ObjectClass}
        obj={{
          ...obj,
          record: {
            ...obj.record,
            ...(parentRecord && { parentRecord }),
          },
        }}
        databaseUuid={databaseUuid}
        onPress={handlePress}
        loadingAction={loading}
        keyItem={keyItem}
      />
    );
  }

  return (
    <ObjectClass
      {...{
        ...obj,
        loadingAction: loading,
        record: {
          ...obj.record,
          ...(parentRecord && { parentRecord }),
        },
      }}
      onPress={handlePress}
      keyItem={keyItem}
    />
  );
};
const LIST_COMPONENT_LIST = [
  'CardList',
  'SimpleList',
  'CustomList',
  'Table',
  'ChipList',
  'AvatarList',
  'HorizontalCardList',
  'RankingList',
  'SelectList',
  'list',
  'Carousel',
  'CustomHorizontalList',
  'select',
];

export type FilterItemProp = {
  comparator: string;
  fieldId: string | object;
  id: string;
  comparison: any;
  comparison2?: any;
};

const _BindingDataComponent = ({
  ObjectClass,
  obj,
  databaseUuid,
  onPress,
  keyItem,
  loadingAction,
}: BindingDataProps) => {
  const dataSource = useSelector(getDataSourceStore);
  const auth = useSelector(getAuth);
  const data = dataSource[databaseUuid];

  if (!data) {
    return <Loading />;
  }

  const ObjectBinding = useSelector(getValueFields);

  const { filter, limit, source }: SortAndFilterType =
    getSortAndFilterData(obj);

  let { sort }: SortConditionType = getSortCondition(obj);

  const { database } = useSelector(databaseSelector);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const externalCollection = find(database, {
    databaseUuid,
  });

  const externals = useSelector(externalCollectionSelector);

  const externalCollections = externals.externalCollections;
  const external = find(externalCollections, {
    databaseUuid,
  });

  const loadingPageByAction = get(
    obj,
    'dataBinding.source.options.loadingPageByAction',
    false
  );

  let objBindingDependency = '';
  const refreshing = useSelector(reFreshStatusSelector);

  try {
    if (loadingPageByAction)
      objBindingDependency = JSON.stringify(ObjectBinding);
  } catch (error) {}

  const dependencyCheck = obj?.id + databaseUuid + objBindingDependency;

  useEffect(() => {
    const getDataExternal = async () => {
      if (databaseUuid) {
        if (
          (externalCollection && externalCollection.isThirdParty && !loading) ||
          (externalCollection && externalCollection.isThirdParty && refreshing)
        ) {
          setLoading(true);
          const metadata = JSON.parse(externalCollection.databaseMetadata)[0];
          if (metadata) {
            await getRecordExternalCollection(metadata, externalCollection)
              .then((value: any) => {
                if (value) {
                  const dataFilter = paginate(value, {
                    maximum: limit,
                    sort,
                    filterOptions: filter,
                    databaseUuid,
                    ObjectBinding,
                  });

                  dispatch(
                    updateExternal({
                      externalCollections: {
                        data: dataFilter,
                        databaseUuid: databaseUuid,
                        dataRoot: value,
                      },
                    })
                  );
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      }
    };
    getDataExternal();
  }, [dependencyCheck, refreshing]);

  useEffect(() => {
    dispatch(
      setListRecordValue({
        id: obj.id,
        rootValue: data,
      })
    );
  }, [dependencyCheck]);

  let attrs = {
    ...obj,
    externalCollection,
    records: [],
    loadingAction,
  };

  const isList = LIST_COMPONENT_LIST.includes(obj.componentName);
  if (isList) {
    const collections = useSelector(getListDatabases);
    const collection: any = collections.find(
      (item: Record<string, any>) => item.databaseUuid === databaseUuid
    );
    const listRecord = useSelector((state: RootState) =>
      getListRecord(state, obj.id)
    );

    const filterResponse: IUseFilterType = useFilter(
      listRecord?.value || data || [],
      source,
      {
        filter,
        sort,
        maximum: limit,
        ObjectBinding,
        databaseUuid,
        collection,
        auth,
        obj,
      }
    );
    let records = filterResponse?.records || [];

    if (
      external?.data &&
      externalCollection &&
      externalCollection.isThirdParty
    ) {
      records = external.data;
    }

    attrs = {
      ...obj,
      records,
      externalCollection,
      ...omit(filterResponse, 'records'),
    };
  }

  return (
    <React.Fragment>
      <>
        {loading ? (
          <Loading />
        ) : (
          data && (
            <ObjectClass
              onPress={onPress}
              databaseUuid={databaseUuid}
              keyItem={keyItem}
              zIndex={obj.zIndex}
              {...attrs}
            />
          )
        )}
      </>
    </React.Fragment>
  );
};

const BindingDataComponent = memo(_BindingDataComponent);
export default BindingComponent;
