import React from 'react';
import { View } from 'react-native';

import createStyles from '@common/components/InAppPurchase/style';

const InAppPurchase: React.FC<any> = (props) => {
  const styles = createStyles(props);

  return (
    <View style={styles.container}>
      <View style={styles.screenContainer}></View>
    </View>
  );
};

export default InAppPurchase;
