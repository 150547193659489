import React, { useCallback, useEffect } from 'react';
import {
  View,
  Text,
  Platform,
  Image,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import queryString from 'query-string';
import { get, isEmpty } from 'lodash';

import EmptyList from '../EmptyList';
import { checkFont } from '../func';

export const SELECT_LIST = 'selectList';

const CHECKED = require('./checked.png').default;

const SelectList: React.FC<any> = (props) => {
  const {
    dataBinding = [],
    width,
    height,
    attributes = {},
    changeInput,
    valueInputs,
    onPress,
  } = props;

  const {
    opacity,
    backgroundColor,
    color,
    borderRadius,
    fontSize,
    fontFamily,
    items,
    title,
    subTitle2,
    defaultData,
  } = attributes;

  const parseText = useCallback((val) => {
    if (typeof val === 'string') {
      return val;
    } else {
      return val?.text;
    }
  }, []);

  const getInitValues = () => {
    if (Platform.OS !== 'web') {
      // App
      if (dataBinding) {
        return Object.keys(dataBinding)?.length === 0 ? items : dataBinding;
      }
      return items;
    } else {
      // web
      const search = queryString.parse(window?.location?.search);
      const target = search?.target;
      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return items;
      }
    }
  };

  const listData = getInitValues();

  useEffect(() => {
    const defaultVal = dataBinding.find(
      (data: any) => data?.subTitle === defaultData
    );

    if (defaultVal) {
      changeInput && changeInput(parseText(defaultVal.subTitle));
    } else {
      changeInput && changeInput(parseText(listData[0]?.subTitle));
    }
  }, []);

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;
  const tableId = get(props, 'attributes.items.source.tableId');

  if (target && !tableId && !props?.loading)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: props.zIndex,
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );

  return (
    <View
      style={{
        width,
        height,
        backgroundColor: backgroundColor,
        borderTopRightRadius: borderRadius,
        borderTopLeftRadius: borderRadius,
        opacity,
      }}
    >
      <View
        style={{
          width,
          height: 58,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopRightRadius: borderRadius,
          borderTopLeftRadius: borderRadius,
          backgroundColor: 'white',
        }}
      >
        <Text
          style={{
            maxWidth: width - 50,
            fontFamily: checkFont(fontFamily),
            fontSize,
            fontWeight: 'bold',
            color,
          }}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {typeof title?.text === 'string' ? title?.text : title?.text[0]}
        </Text>
      </View>
      {subTitle2?.enabled ? (
        <View
          style={{
            width,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              marginTop: 15,
              marginBottom: 15,
              marginRight: 40,
              marginLeft: 40,
              fontFamily: checkFont(fontFamily),
              fontSize,
              color,
              textAlign: 'center',
            }}
            numberOfLines={3}
            ellipsizeMode="tail"
          >
            {typeof subTitle2?.text === 'string'
              ? subTitle2?.text
              : subTitle2?.text[0]}
          </Text>
        </View>
      ) : null}
      <FlatList
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        maxToRenderPerBatch={4}
        scrollEnabled={true}
        data={Array.isArray(listData) ? listData : []}
        renderItem={({ item }) => {
          return (
            <TouchableOpacity
              style={{
                width,
                height: 58,
                marginTop: 3,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
              }}
              onPress={() => {
                changeInput && changeInput(parseText(item?.subTitle));
                onPress && onPress();
              }}
            >
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{
                    width: width - 50,
                    fontFamily: checkFont(fontFamily),
                    fontSize,
                    color,
                  }}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {parseText(item?.subTitle)}
                </Text>
              </View>
              {valueInputs === parseText(item?.subTitle) ? (
                <Image
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                  source={{
                    uri: CHECKED,
                  }}
                />
              ) : null}
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};

export default SelectList;
