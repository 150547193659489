import { useCallback, useState } from 'react';
import api from '@common/configs/api';
import {
  CameraType,
  LIVE_STREAM_ACTION_TYPES,
} from '@common/constants/livestream.constant';
import { AxiosResponse } from 'axios';
import { IGetLiveStreamDetail, ILiveStreamDetail } from '@common/types';
import { useDispatch } from 'react-redux';
import {
  changeValueLiveStream,
  setLoseConnect,
} from '@common/redux/slice/liveStream';
import { Alert, Platform } from 'react-native';
import { pick } from 'lodash';

const useLiveStream = (attributes?: any) => {
  const [cameraType, setCameraType] = useState<CameraType>('back');
  const [muteVoice, setMuteVoice] = useState(false);
  const dispatch = useDispatch();
  const [streaming, setStreaming] = useState<boolean>(false);

  const locale = 'ja';

  const getLiveStream = useCallback(
    async ({ streamId, accessToken, userRecordId }) => {
      try {
        const res: AxiosResponse<ILiveStreamDetail> = await api({
          method: 'post',
          url: `/livestreams`,
          data: {
            streamId,
            userRecordId,
            accessToken,
            Action: LIVE_STREAM_ACTION_TYPES.GetStreamUrl,
          },
        });
        return res.data;
      } catch {
        if (Platform.OS === 'web') {
          alert(
            ' フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
          );
        } else {
          Alert.alert(
            '',
            'フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
          );
        }
      }
    },
    []
  );

  const getLiveStreamDetail = useCallback(async ({ streamId }) => {
    try {
      const res: AxiosResponse<IGetLiveStreamDetail> = await api({
        method: 'get',
        url: `/livestreams/${streamId}`,
      });
      return res.data;
    } catch (err) {
      if (Platform.OS === 'web') {
        alert(
          ' フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
        );
      } else {
        Alert.alert(
          '',
          'フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
        );
      }
    }
  }, []);

  const saveStreamingVideo = useCallback(
    async ({ streamId, accessToken, userRecordId }) => {
      try {
        const res: AxiosResponse<ILiveStreamDetail> = await api({
          method: 'post',
          url: `/livestreams`,
          data: {
            streamId,
            userRecordId,
            accessToken,
            Action: LIVE_STREAM_ACTION_TYPES.CreateLiveStreamRecordIndexFiles,
          },
        });
        return res.data;
      } catch {}
    },
    []
  );

  const createLiveStreamNew = useCallback(
    async ({ infoUser, title }) => {
      try {
        const { accessToken, ...owner } = infoUser;
        const avatarFid =
          Object.keys(owner).find((key) => {
            if (key === 'avatar') {
              return true;
            }
            if (typeof owner[key] === 'object' && !Array.isArray(owner[key])) {
              if (owner[key]?.url && owner[key]?.filename) {
                return true;
              }
            }
            return false;
          }) || 'avatar';
        const userInfoFids = [
          'email',
          'fullName',
          'username',
          'livestreaming',
          'userId',
          'createdAt',
          'updatedAt',
          avatarFid,
        ];

        const res: AxiosResponse<ILiveStreamDetail> = await api({
          method: 'post',
          url: `/livestreams`,
          data: {
            accessToken: infoUser.accessToken,
            appId: infoUser.appId,
            userRecordId: infoUser.userId,
            metadata: {
              owner: pick(owner, userInfoFids),
              title,
            },
            Action: LIVE_STREAM_ACTION_TYPES.CreateIngestUrl,
          },
        });

        return res.data;
      } catch (err: any) {
        let errorMsg =
          '配信を開始できませんでした。電波状況が良好なエリアで再度実行してください。';
        try {
          if (err?.response?.data?.data[locale]) {
            errorMsg = err?.response?.data?.data[locale];
          }
        } finally {
          if (Platform.OS === 'web') {
            alert(errorMsg);
          } else {
            Alert.alert(
              '',
              errorMsg,
              [
                {
                  text: 'はい',
                  onPress: () => {
                    handleFinishLive();
                    attributes.onPress();
                  },
                },
              ],
              {
                cancelable: false,
              }
            );
          }
        }
      }
    },
    [attributes]
  );

  const pushView = useCallback(async ({ infoUser, streamId }) => {
    try {
      const { accessToken, ...owner } = infoUser;
      const res: AxiosResponse<ILiveStreamDetail> = await api({
        method: 'post',
        url: `/livestreams`,
        data: {
          streamId,
          accessToken: infoUser.accessToken,
          userRecordId: infoUser.userId,
          Action: LIVE_STREAM_ACTION_TYPES.IncrementView,
        },
      });
      return res.data;
    } catch (err) {
      console.log('pushView ERROR', err);
    }
  }, []);

  const getMoreVideos = useCallback(async ({ appId, videos }) => {
    try {
      const res: AxiosResponse<ILiveStreamDetail> = await api({
        method: 'get',
        url: `/livestreams?appId=${appId}&next=[${videos}]`,
      });
      return res.data;
    } catch (err) {}
  }, []);

  const stopLiveStream = useCallback(async ({ infoUser, streamId }) => {
    try {
      const res: AxiosResponse<ILiveStreamDetail> = await api({
        method: 'post',
        url: `/livestreams`,
        data: {
          accessToken: infoUser.accessToken,
          streamId: streamId,
          userRecordId: infoUser.userId,
          Action: LIVE_STREAM_ACTION_TYPES.StopLiveStream,
        },
      });
      return res?.data;
    } catch (err: any) {}
  }, []);

  const changeValue = useCallback(
    (value: any) => {
      dispatch(
        changeValueLiveStream({
          objectId: attributes?.id,
          value,
        })
      );
    },
    [dispatch, attributes]
  );

  const convertTimeLive = useCallback((timeLiveStream) => {
    let h = 0;
    let m = 0;
    let s = 0;

    let mTemp = 0;

    mTemp = Math.floor(timeLiveStream / 60);

    h = Math.floor(mTemp / 60);
    m = mTemp % 60;
    s = timeLiveStream % 60;

    const convertTime = (n: number) => (n < 10 ? `0${n}` : n);

    return `${convertTime(h)}:${convertTime(m)}:${convertTime(s)}`;
  }, []);

  const handleFinishLive = useCallback(() => {
    setStreaming(false);
    dispatch(setLoseConnect(false));
  }, []);

  const handleStopLiveStream = useCallback(() => {
    setStreaming(false);
  }, [dispatch]);

  const changeCameraType = useCallback(
    (cameraType) => {
      if (cameraType === 'front') {
        setCameraType('back');
      } else {
        setCameraType('front');
      }
    },
    [setCameraType]
  );

  const onMuteVoice = useCallback(() => {
    setMuteVoice((prev) => !prev);
  }, [setMuteVoice]);

  return {
    locale,
    dispatch,
    pushView,
    streaming,
    muteVoice,
    cameraType,
    changeValue,
    onMuteVoice,
    setStreaming,
    getMoreVideos,
    setCameraType,
    getLiveStream,
    stopLiveStream,
    convertTimeLive,
    changeCameraType,
    handleFinishLive,
    saveStreamingVideo,
    getLiveStreamDetail,
    createLiveStreamNew,
    handleStopLiveStream,
  };
};

export default useLiveStream;
