import api from '@common/configs/api';
import { setError, setNotification } from '@common/redux/slice/action';
import { loginSuccessWithTorus as loginSuccess } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { registerFCMUser } from '@common/utils/handleActions/ActionItem/FCMregister';
import { pick } from 'lodash';
import { Platform } from 'react-native';
import appConfig from '../../appConfig.json';

export type LoginSuccessWithTorus = {
  publicAddress?: string;
  privKey: string;
  userInfo: {
    email: string;
    name: string;
    idToken: string;
    typeOfLogin: string;
    verifierId: string;
  };
};

export const useTorusLogin = () => {
  const dispatch = store.dispatch;
  const loginSuccessWithTorus = async (
    loginInfo: LoginSuccessWithTorus
  ): Promise<boolean> => {
    console.log('===torus loginSuccessWithTorus', loginInfo);
    let appid = appConfig.appId;
    if (Platform.OS === 'web') {
      const url = window.location.href;
      appid = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
    }

    try {
      const { data }: any = await api.post(`/apps-auth/${appid}/torus/login`, {
        email: loginInfo.userInfo.email,
        fullName: loginInfo.userInfo.name,
        publicAddress: loginInfo.publicAddress,
        typeOfLogin: loginInfo.userInfo.typeOfLogin,
        privKey: loginInfo.privKey,
      });

      await registerFCMUser(data, appid);

      dispatch(
        loginSuccess({
          user: {
            ...data.record,
            databaseId: data.databaseId,
            userId: data._id,
            appId: appid,
            torusPrivateKey: loginInfo.privKey,
            ...pick(data, ['createdAt', 'updatedAt']),
          },
        })
      );
      dispatch(setNotification('Login Success !'));
      return true;
    } catch (e: any) {
      dispatch(
        setError(`Login Error!, ${e.response?.data?.message || e.message}`)
      );
      return false;
    }
  };

  return {
    loginSuccessWithTorus,
  };
};
