import React from 'react';
import { View, Image, Text, Platform } from 'react-native';
import IconUser from '@common/components/CustomerChat/images/IconUser';
import moment from 'moment';
const defaultUserIcon = require('./images/icon-user.png');

type CommentProp = {
  item: any;
  textStyle: any;
  width: number;
  sellerAvatar: string;
  buyerAvatar: string;
  email: string;
};

const Comment: React.FC<CommentProp> = (props) => {
  const { item, textStyle, width, sellerAvatar, buyerAvatar, email } = props;
  const { fontSize } = textStyle;
  const getUrlAvatar = (isOrder: boolean) => {
    let url = '';
    if (isOrder && buyerAvatar) {
      url = buyerAvatar;
    }
    if (!isOrder && sellerAvatar) {
      url = sellerAvatar;
    }
    return url;
  };
  const getTime = (timer: string, format: string) => {
    return moment(timer).format(format);
  };
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: 5,
        marginLeft: 5,
        marginTop: 1,
      }}
    >
      <View
        style={{
          padding: 5,
          maxWidth: width - 20,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {getUrlAvatar(item?.isOrder) !== '' ? (
            <Image
              style={{
                minHeight: 30,
                minWidth: 30,
                borderRadius: 15,
                borderWidth: 2,
                borderColor: '#707070',
              }}
              source={{
                uri: getUrlAvatar(item?.isOrder),
              }}
            />
          ) : (
            <View
              style={{
                height: 30,
                width: 30,
                borderRadius: 20,
                borderWidth: 2,
                borderColor: '#707070',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Platform.OS === 'web' ? (
                <Image
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 20,
                  }}
                  source={{
                    uri: defaultUserIcon.default,
                  }}
                />
              ) : (
                <IconUser />
              )}
            </View>
          )}
        </View>
        <View
          style={{
            borderRadius: 25,
            backgroundColor: item?.email === email ? '#ffe280' : '#c7c5c5',
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 10,
            marginRight: 8,
            maxWidth: width * 0.65,
          }}
        >
          <Text
            style={{
              fontSize: fontSize,
              flexWrap: 'wrap',
              color: item?.email === email ? '#575757' : '#ffffff',
            }}
          >
            {item?.text}
          </Text>
        </View>
        <View>
          <Text>{getTime(item?.createdAt, 'YYYY/MM/DD')}</Text>
          <Text>{getTime(item?.createdAt, 'HH:mm')}</Text>
        </View>
      </View>
    </View>
  );
};

export default Comment;
