import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  View,
  Platform,
  TouchableOpacity,
  Image,
  Alert,
  FlatList,
  TextInput,
} from 'react-native';

import createStyles from '@common/components/CustomerChat/style';
import Comment from '@common/components/CustomerChat/Comment';
import store from '@common/redux/store';
import { getFieldValue } from '@common/utils/binding';
import useCustomerChat from '@common/hooks/useCustomerChat';
import SendBtn from '@common/components/CustomerChat/images/SendBtn';
import history from '@common/routes/history';
import { showAleart } from '@common/utils/handleActions/func/func';

const defaultSendBtn = require('./images/send-btn.png');

const buyerTextError =
  '発送完了ができませんでした。電波状況が良好なエリアで再度実行してください。';

const sellerTextError =
  'チャットが送信できませんでした。電波状況が良好なエリアで再度実行してください。';

const getCurrentTimeUTC = () => {
  return new Date().toISOString();
};

const CustomerChat: React.FC<any> = (props) => {
  const styles = createStyles(props);
  const state: any = store.getState();
  const auth = state.auth.profile;
  const { callApi } = useCustomerChat();
  const {
    width,
    height,
    backgroundColor,
    opacity,
    fontSize,
    color,
    borderRadius,
    onPress,
  } = props;

  const { userId, email } = auth;
  const appId = history.location.pathname.split('/')[2];

  const chatJson = getFieldValue(
    props?.chatJson || '',
    props?.record,
    props?.selectedItem?.itemIndex
  );
  const buyerMail = getFieldValue(
    props?.buyerMail || '',
    props?.record,
    props?.selectedItem?.itemIndex
  );
  const sellerAvatar = getFieldValue(
    props?.sellerAvatar || '',
    props?.record,
    props?.selectedItem?.itemIndex
  );
  const buyerAvatar = getFieldValue(
    props?.buyerAvatar || '',
    props?.record,
    props?.selectedItem?.itemIndex
  );
  const orderId = getFieldValue(
    props?.orderId || '',
    props?.record,
    props?.selectedItem?.itemIndex
  );

  const [messageText, setMessageText] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const listRef: any = useRef();

  const addMessage = useCallback(
    (message: Record<string, any>) => {
      setMessages((oldMessageState: any[]) => {
        try {
          if (oldMessageState.length === 0 && chatJson) {
            const oldMessages = JSON.parse(chatJson);
            oldMessages?.forEach((element: any) => {
              if (element.content && element.content.text) {
                oldMessageState.push(element.content);
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
        oldMessageState.push(message);
        return oldMessageState;
      });
    },
    [chatJson, email, buyerMail]
  );

  const sendMessage = useCallback(
    async (mes: string) => {
      mes = mes.trim();
      if (!mes) {
        setMessageText('');
        return;
      }
      const payloadMessage = {
        text: mes,
        createdAt: getCurrentTimeUTC(),
        isOrder: email === buyerMail,
        email,
      };
      addMessage(payloadMessage);
      setMessageText('');
      await callApi(
        {
          message: payloadMessage,
          fid: orderId,
          userId,
        },
        appId
      ).catch((error: any) => {
        if (!error.response) {
          showAleart(email === buyerMail ? buyerTextError : sellerTextError);
        } else {
          showAleart(error.response?.data?.message);
        }
      });
      if (email === buyerMail) {
        onPress && onPress('buyerClick');
      } else {
        onPress && onPress('sellerClick');
      }
    },
    [email, orderId, buyerMail, addMessage]
  );

  const alertMaxLength = useCallback((messageText: string) => {
    if (messageText && messageText.length >= 100) {
      if (Platform.OS === 'web') {
        alert('Enter up to 100 characters');
      }
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        Alert.alert('Enter up to 100 characters');
      }
    }
  }, []);

  useEffect(() => {
    if (chatJson && userId) {
      try {
        const oldMessages = JSON.parse(chatJson);
        const mess: any = [];
        oldMessages?.forEach((element: any) => {
          if (element.content && element.content.text) {
            mess.push(element.content);
          }
        });
        setMessages(mess);
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      setMessages([]);
    };
  }, [chatJson]);

  useEffect(() => {
    listRef?.current?.scrollToEnd({ animated: false });
  }, [listRef]);

  return (
    <View
      style={[
        styles.container,
        {
          width,
          height,
          backgroundColor,
          borderRadius,
          opacity,
          minHeight: height,
          maxHeight: height,
        },
      ]}
    >
      <View
        style={{
          height: '80%',
          minHeight: '80%',
          maxHeight: '80%',
          paddingTop: 10,
        }}
      >
        <FlatList
          onContentSizeChange={() => {
            listRef?.current?.scrollToEnd({ animated: false });
          }}
          showsVerticalScrollIndicator={false}
          nestedScrollEnabled={true}
          ref={(ref) => {
            listRef.current = ref;
          }}
          maxToRenderPerBatch={4}
          scrollEnabled={true}
          data={Array.isArray(messages) ? messages : []}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => {
            return (
              <Comment
                key={index}
                item={item}
                textStyle={{ color, fontSize }}
                width={width}
                sellerAvatar={sellerAvatar}
                buyerAvatar={buyerAvatar}
                email={email}
              />
            );
          }}
        />
      </View>

      <View style={styles.footer}>
        <TextInput
          style={styles.input}
          placeholderTextColor={'#E3E8EC'}
          onChange={({ nativeEvent: { text } }) => {
            alertMaxLength(text);
            setMessageText(text);
          }}
          placeholder="メッセージを送る"
          maxLength={100}
          onSubmitEditing={() => sendMessage(messageText)}
          value={messageText}
        />
        <TouchableOpacity
          style={styles.buttonSend}
          onPress={() => sendMessage(messageText)}
        >
          <View
            style={{
              height: 30,
              width: 30,
              backgroundColor: '#fff',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {Platform.OS === 'web' ? (
              <Image
                style={{
                  width: 30,
                  height: 30,
                }}
                source={{
                  uri: defaultSendBtn.default,
                }}
              />
            ) : (
              <SendBtn />
            )}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default CustomerChat;
