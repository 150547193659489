import api from '@common/configs/api';
import { Stringifiable } from 'query-string';

const useCustomerChat = () => {
  const callApi = async (messages: any, appId: Stringifiable) => {
    await api({
      method: 'post',
      url: `/app-order/${messages.fid}/conversation`,
      data: {
        userId: messages.userId,
        appId,
        content: messages.message,
      },
    });
  };

  return {
    callApi,
  };
};

export default useCustomerChat;
