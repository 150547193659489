import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  const { color = '#000' } = attributes;
  return StyleSheet.create({
    container: {
      flex: 1,
      display: 'flex',
      zIndex: attributes.zIndex,
    },
    footer: {
      width: '100%',
      height: '20%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
    },
    input: {
      backgroundColor: '#fff',
      borderWidth: 1,
      borderRadius: 25,
      height: 35,
      borderColor: '#707070',
      width: '80%',
      padding: 5,
      paddingLeft: 15,
      color,
    },
    buttonSend: {
      width: 50,
      height: 33,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerComment: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: 5,
      marginLeft: 5,
      marginTop: 1,
    },
  });
};

export default createStyles;
