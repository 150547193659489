import React, { Dispatch, SetStateAction } from 'react';
import { View, Text } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { cloneDeep, cloneDeepWith, findIndex } from 'lodash';

import store from '@common/redux/store';
import { setForgotPassword } from '@common/redux/slice/action';
import createStyles from './style';
import { Response } from './index';
import { actionPromise } from '@common/utils/handleActions/excuteAction';

interface IProps {
  email: string;
  locale: string;
  setResponse: Dispatch<SetStateAction<Response>>;
}

const ConfirrmPopup = ({ email, locale, setResponse }: IProps) => {
  const styles = createStyles();

  const dispatch = store.dispatch;
  const state = store.getState();
  const actionComponent = state.action.actionComponent;

  const isJa = locale === 'ja';

  const indexAction = findIndex(actionComponent, {
    actionType: 'forgotPassword',
  });

  const onClose = () => {
    dispatch(
      setForgotPassword({
        state: false,
        animation: 'fadeOutDownBig',
      })
    );
    setResponse({
      status: false,
      loading: false,
      error: '',
    });

    const cloneActionComponent = cloneDeep(actionComponent);
    const parseActions = cloneActionComponent
      .slice(indexAction)
      .map((item: any) => ({
        ...item,
        ...(item.actionType === 'forgotPassword' && {
          isStatus: 'SUCCEED',
          type: 'forgotPassword',
        }),
      }));

    return actionPromise({ actionComponent: parseActions });
  };

  return (
    <View>
      <Text style={styles.title}>
        {isJa ? 'メールが送信されました' : 'An email is on its way!'}
      </Text>
      <Text style={styles.desc}>
        {isJa ? (
          <>
            パスワードのリセット方法を記載したメールを
            <Text style={styles.email}>{email}</Text> に送信しました。
          </>
        ) : (
          <>
            We sent an email to <Text style={styles.email}>{email}</Text> with a
            temporary password and instructions on how to reset your password.
          </>
        )}
      </Text>

      <RNButton
        onPress={onClose}
        labelStyle={{
          color: '#fff',
          fontSize: 14,
        }}
        style={{
          backgroundColor: '#34A0DD',
          borderRadius: 4,
          minHeight: 40,
          justifyContent: 'center',
          marginTop: 16,
        }}
      >
        {isJa ? '完了しました' : 'AWESOME'}
      </RNButton>
    </View>
  );
};

export default ConfirrmPopup;
