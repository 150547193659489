import React, { useState, useEffect, useCallback, memo } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { Text, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import queryString from 'query-string';
import { ICustomButton } from '@nocode/types';
import { checkFont } from '../func';

const CustomButton: React.FC<ICustomButton> = (attribute) => {
  const {
    attributes,
    height,
    zIndex,
    isDisplay,
    onPress,
    dataBinding,
    loading,
  } = attribute;

  const [currentAttributes, setCurrentAttributes] = useState<any>(attributes);
  const [id, setId] = useState<string | undefined>(undefined);
  const [backgroundGradient, setBackgroundGradient] = useState<{
    hidden: boolean;
    firstColor: string;
    secondColor: string;
  }>({
    hidden: false,
    firstColor: '',
    secondColor: '',
  });

  const replaceButton = attributes?.replaceButton;

  useEffect(() => {
    const search = queryString.parse(window?.location?.search);
    const target = search?.target;
    if (!loading) {
      if (
        (target && replaceButton?.enabled && !isDisplay) ||
        (!target && replaceButton?.preview && replaceButton?.enabled)
      ) {
        setBackgroundGradient({
          hidden: attributes.isBgGradientReplace,
          firstColor: attributes?.replaceButtonStyle?.bgGradientFirst,
          secondColor: attributes?.replaceButtonStyle?.bgGradientSecond,
        });
        setCurrentAttributes({
          ...attributes,
          ...replaceButton,
          ...attributes?.replaceButtonTextStyles,
          opacity: attributes.opacity,
        });
        setId('replaceButton');
      } else {
        setBackgroundGradient({
          hidden: attributes.isBgGradient,
          firstColor: attributes?.buttonStyle?.bgGradientFirst,
          secondColor: attributes?.buttonStyle?.bgGradientSecond,
        });

        setCurrentAttributes({
          ...attributes,
        });
        setId(undefined);
      }
    }
  }, [attribute, replaceButton, loading]);

  const {
    type,
    opacity,
    backgroundColor,
    fontSize,
    color,
    borderRadius,
    borderColor,
    borderWidth,
    text,
    upperCase,
  } = currentAttributes;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const textOptions = Array.isArray(text) ? text[0] : text;

  if (isDisplay === false && !replaceButton?.enabled) return <></>;

  const handleOnPress = useCallback(async () => {
    if (!loading) {
      await onPress(id, { record: dataBinding?.recordVisibility });
    }
  }, [loading, id, dataBinding?.recordVisibility]);

  const checkTypeButton = () => {
    switch (type) {
      case 'outlined':
        return {
          borderColor: color,
          borderWidth: 1,
          borderRadius: 50,
        };
      case 'text':
        return {
          borderColor: '',
          borderWidth: 0,
          borderRadius: 0,
        };
      default:
        return {};
    }
  };

  return (
    <TouchableOpacity onPress={handleOnPress}>
      <LinearGradient
        colors={
          type === 'contained'
            ? backgroundGradient.hidden
              ? [
                  backgroundGradient?.firstColor,
                  backgroundGradient?.secondColor,
                ]
              : [backgroundColor, backgroundColor]
            : ['rgba(52, 160, 221, 0)', 'rgba(52, 160, 221, 0)']
        }
        style={{
          opacity,
          borderRadius,
          height,
          borderWidth,
          minHeight: 15,
          justifyContent: 'center',
          zIndex: zIndex || 1,
          borderColor,
          ...checkTypeButton(),
        }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          {loading ? (
            <ActivityIndicator size="small" color={color} />
          ) : (
            <Text
              style={{
                fontFamily: checkFont(attributes.fontFamily, undefined, target),
                fontSize,
                ...(attributes.fontSize && {
                  lineHeight: attributes.fontSize,
                }),
                color,
                textAlign: 'center',
              }}
            >
              {textOptions
                ? upperCase
                  ? textOptions.toUpperCase()
                  : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
                : ''}
            </Text>
          )}
        </View>
      </LinearGradient>
    </TouchableOpacity>
  );
};

// export default CustomButton;
export default memo(CustomButton, (prev, next) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  // on canvas
  if (!target) {
    return false;
  }

  const con =
    prev.id === next.id &&
    prev.isDisplay === next.isDisplay &&
    prev.loading === next.loading &&
    prev.attributes.replaceButton?.enabled ===
      next.attributes.replaceButton?.enabled &&
    prev.attributes.text === next.attributes.text &&
    prev.attributes.replaceButton?.text ===
      next.attributes.replaceButton?.text &&
    prev.hidden === next.hidden;
  // !con && console.log('=== check CustomButton', prev.id, prev.name, con);
  return con;
});
