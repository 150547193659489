import { Clipboard } from 'react-native';

import { IAction } from '@common/types/';
import { ActionResponse } from '../excuteAction';
import { getTextBinding } from '@common/utils/handleBinding';

const copyToClipboard: (action: IAction) => Promise<ActionResponse> = async (
  action: IAction
) => {
  try {
    const textValue = getTextBinding(action?.options?.textValue);

    const copyToClipboard = (textValue: string) => {
      Clipboard.setString(`${textValue}`);
    };

    copyToClipboard(textValue);
    return {
      status: 'SUCCEED',
    };
  } catch (error) {
    return {
      status: 'FAILED',
    };
  }
};

export default copyToClipboard;
