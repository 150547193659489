import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApp } from '@common/types';

type State = {
  notification: string;
  error: string;
  forgotPassword: {
    state: boolean;
    animation: string;
  };
  temporayPassword: string;
  actionComponent: any;
  loadingActions: any;
};

const initialState: State = {
  notification: '',
  error: '',
  forgotPassword: {
    state: false,
    animation: 'fadeInUpBig',
  },
  temporayPassword: '',
  actionComponent: null,
  loadingActions: {},
};

const appSlice = createSlice({
  name: 'action',
  initialState: initialState,
  reducers: {
    setNotification: (state: State, action: PayloadAction<string>) => {
      state.notification = action.payload;
      state.error = '';
    },
    setError: (state: State, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.notification = '';
    },
    resetAction: (state: State) => {
      state.error = '';
      state.notification = '';
    },
    setForgotPassword: (
      state: any,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.forgotPassword = action.payload;
    },
    setTemporayPassword: (state: any, action: PayloadAction<string>) => {
      state.temporayPassword = action.payload;
    },
    setActionComponent: (state: any, action: PayloadAction<any>) => {
      state.actionComponent = action.payload;
    },
    setLoadingAction: (
      state: any,
      action: PayloadAction<{ componentId: string; status: boolean }>
    ) => {
      state.loadingActions[action.payload.componentId] = action.payload.status;
    },
  },
});

export const {
  setNotification,
  setError,
  resetAction,
  setForgotPassword,
  setTemporayPassword,
  setActionComponent,
  setLoadingAction,
} = appSlice.actions;
export default appSlice.reducer;
