import React, { ComponentProps } from 'react';
import { Button as RNButton } from 'react-native-paper';
import { View, Text, Platform } from 'react-native';
import queryString from 'query-string';
import { IButton } from '@nocode/types';
import { checkFont } from '../func';
// import point from '../CustomIcon/point.svg';
import PointIcon from '../CustomIcon';

type Props = ComponentProps<typeof RNButton> | any;

const Button: React.FC<IButton> = ({
  attributes,
  onPress,
  loading,
  height,
  zIndex,
}) => {
  const {
    type,
    icon,
    opacity,
    backgroundColor,
    fontSize,
    color,
    borderRadius,
    text,
    upperCase,
  } = attributes;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const textOptions = Array.isArray(text) ? text[0] : text;
  const childProps: Props = {
    mode: type,
    children: textOptions
      ? upperCase
        ? textOptions.toUpperCase()
        : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
      : '',
    icon: icon,
    style: {
      opacity,
      ...(type === 'contained' && {
        backgroundColor,
      }),
      borderRadius,
      minHeight: height,
      justifyContent: 'center',
      borderColor: type === 'contained' ? backgroundColor : color,
      zIndex: zIndex || 1,
    },
    uppercase: upperCase ? upperCase : false,
    labelStyle: {
      // error font family______________________
      fontFamily: checkFont(attributes.fontFamily, undefined, target),
      fontSize,
      ...(attributes.fontSize && {
        lineHeight: attributes.fontSize * 1.15,
        paddingVertical: 1.5,
      }),
      color,
    },
    // color: type === 'contained' ? backgroundColor : color ? color : '#4259AC',
  };

  if (icon === 'point') {
    let copyChildProps = { ...childProps };
    delete copyChildProps.icon;
    delete copyChildProps.children;

    // const pointStyle = StyleSheet.create({
    //   icon: {
    //     width: 24,
    //     height: 24,
    //     tintColor: color,
    //     marginBottom: 3,
    //     marginRight: 10,
    //   },
    // });

    return (
      <RNButton
        {...copyChildProps}
        onPress={!loading && onPress}
        loading={loading}
        icon={() => <PointIcon width={24} height={24} fill={color} />}
      >
        <Text
          {...(Platform.OS !== 'web' && {
            style: {
              fontFamily: checkFont(attributes.fontFamily),
              fontSize,
              color,
            },
          })}
        >
          {textOptions
            ? upperCase
              ? textOptions.toUpperCase()
              : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
            : ''}
        </Text>
      </RNButton>
    );
  }

  return (
    <RNButton {...childProps} onPress={!loading && onPress} loading={loading} />
  );
};

export default Button;
