import React, { Suspense, useEffect } from 'react';
import { Platform, Dimensions } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppRoutes from './app';
import WebRoutes from './web';
import { useDispatch } from 'react-redux';

import {
  setDimensionStore,
  setLoadingDimension,
} from '@common/redux/slice/page';
import FCMComponent from '@common/utils/FCM/FCMComponent';
import { useAppDatabaseSocket } from '@common/hooks/useAppDatabaseSocket';

export function Routes() {
  const dispatch = useDispatch();
  const windowSize = Dimensions.get('window');

  useEffect(() => {
    if (windowSize) {
      dispatch(setDimensionStore(windowSize));
    }
  }, []);

  const onChange = ({ window }: any) => {
    dispatch(setLoadingDimension(true));
    if (window) {
      dispatch(setDimensionStore(window));
    }
  };

  // TODO:
  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  useAppDatabaseSocket();

  return (
    <Suspense fallback={null}>
      <SafeAreaProvider>
        {Platform.OS === 'web' ? (
          <WebRoutes />
        ) : (
          <FCMComponent>
            <AppRoutes />
          </FCMComponent>
        )}
      </SafeAreaProvider>
    </Suspense>
  );
}
