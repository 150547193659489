import { Alert } from 'react-native';
import { loadApp } from '@common/redux/slice/app';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../configs/api';
import { IApp } from '@common/types/element';
import { ICollection } from '@common/types';
import { isEmpty } from 'lodash';
import { setDataSource, loadDatabase } from '@common/redux/slice/database';
import { getDataEncrypt } from '@common/utils/encryptData';

type Props = {
  appId: string;
  debug?: boolean;
  isShareScreen?: boolean;
};

type APIResponse<T> = {
  data: T | undefined;
  error: string | null;
  isLoading: boolean;
};

const loadProductionSchema = async (appId: string, params: object) => {
  try {
    const res: AxiosResponse<{ schema: Array<ICollection> }> = await api({
      method: 'get',
      url: `v2/apps/${appId}/versions/latest`,
      params,
    });
    return res?.data?.schema || (await loadDevSchema(params));
  } catch (error) {
    return await loadDevSchema(params);
  }
};

const loadDevSchema = async (params: object) => {
  const res: AxiosResponse<Array<ICollection>> = await api({
    method: 'get',
    url: `/v2/database/collections`,
    params,
  });
  return res?.data || [];
};
const useAppDetail = ({ appId, debug, isShareScreen }: Props) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState<APIResponse<IApp>>({
    data: undefined,
    error: null,
    isLoading: false,
  });

  const [collections, setCollection] = useState<Array<ICollection>>([]);

  const fetchCollection = async (appInfo: IApp) => {
    const metadataApp = JSON.parse(appInfo.metadata || `{}`);
    setResponse({ ...response, ...{ isLoading: true } });
    try {
      const params = {
        appId: appInfo.shareDatabaseAppId || appInfo.id,
        $limit: 100,
        hasCache: metadataApp?.hasCache,
      };

      const database = debug
        ? await loadDevSchema(params)
        : await loadProductionSchema(appId, params);

      if (!isEmpty(database)) {
        const apiRecords = database.map((collection: ICollection) =>
          api({
            method: 'get',
            url: `v2/database/${collection.databaseUuid}/records`,
            params: {
              appId: appInfo.shareDatabaseAppId || appInfo.id,
              hasCache: metadataApp?.hasCache,
            },
          })
        );

        const data = await axios
          .all(apiRecords)
          .then((apiResponse: any) =>
            apiResponse.map((x: any) => ({
              ...x,
              data: getDataEncrypt(x?.data),
            }))
          )
          .then((data) => {
            let dataSource: any = {};

            for (let i = 0; i < database.length; i++) {
              const collection: ICollection = database[i];

              dataSource[collection.databaseUuid] = data[i]?.data?.data || [];
            }

            return dataSource;
          });

        dispatch(setDataSource(data));
      }
      dispatch(loadDatabase(database));
      setCollection(database);
    } catch (error) {
      setResponse({
        ...response,
        ...{ isLoading: false, error: 'get data error' },
      });
    }
  };

  const _fetchData = useCallback(async () => {
    setResponse({ ...response, ...{ isLoading: true } });
    try {
      const res: AxiosResponse<IApp> = await api({
        method: 'get',
        url: `/v2/share/${appId}`,
      });
      !isShareScreen && (await fetchCollection(res.data));

      dispatch(loadApp(res.data));
      setResponse({ ...response, ...{ isLoading: false, data: res.data } });
    } catch (error) {
      Alert.alert(
        '',
        'アプリが起動できません。電波状況が良好なエリアで再度実行してください。'
      );
      setResponse({
        ...response,
        ...{ isLoading: false, error: 'get data error' },
      });
    }
  }, [response, isShareScreen]);

  useEffect(() => {
    _fetchData();
  }, []);

  return { ...response, collections };
};

export default useAppDetail;
