import { BINDING_SELECTOR_TYPE } from '@common/constants/';
import { getRecords } from '@common/utils/database';
import store from '@common/redux/store';
import { getRecordDataSource } from '@common/utils/database';
import { bindingType } from '@common/utils/functions';
import {
  find,
  findIndex,
  get,
  isArray,
  isEmpty,
  isString,
  map,
  some,
  uniq,
} from 'lodash';
import { IRecord } from '@common/types/';
import {
  getAvailability,
  getAvailabilityMultiConditional,
  isTwoDirectionArray,
} from '@common/hooks/useVisibility';

export const getFieldRelationValue = (fieldId: string, value: any) => {
  if (fieldId === 'id') {
    return get(value, '_id', '') || get(value, `record._id`, '');
  }

  return get(value, fieldId) || get(value, `record.${fieldId}`);
};

export const mapperRecordManyRelation = (ids: any[], records: any[]) => {
  if (isEmpty(ids)) return [];

  return map(ids, (item) => {
    const index = findIndex(records, { _id: item });
    if (index !== -1) {
      return records[index];
    }
  }).flat(1);
};

export const getValueDataTypeList = (source: any, initialValue: any) => {
  const idsRelation = map(initialValue, (value) =>
    getFieldRelationValue(source?.fieldId, value)
  ).flat(1);
  const records = getRecords(source?.tableId);

  return mapperRecordManyRelation(uniq(idsRelation), records);
};

const getData = (source: any, initialValue: any) => {
  const state: any = store.getState();
  const selectorType = source.selector?.type;
  const currentRecord = state.database.currentRecord;
  const createdRecord = state.database.recordCreated;
  const authProfile = state.auth.profile;
  const valuesInputs = state.formInputs.values;

  switch (selectorType) {
    case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
      return authProfile;

    case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
      const routerParamRecord = get(currentRecord, source?.tableId, null);

      return !isEmpty(routerParamRecord)
        ? getRecordDataSource(source?.tableId, routerParamRecord?._id)
        : null;

    case BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR:
      const selectObjectId = source.selector?.selectObjectId;
      const idSelected = valuesInputs[selectObjectId] || null;

      if (!idSelected) return null;
      return getRecordDataSource(source?.tableId, idSelected) || null;

    case BINDING_SELECTOR_TYPE.CREATED_OBJECT:
      return createdRecord[source?.tableId] || null;
    default:
      // FIx list in list
      if (!initialValue?.listObjectId) return initialValue;

      const listObjectId = get(source, 'selector.listObjectId', '');
      return initialValue?.listObjectId.includes(listObjectId)
        ? initialValue
        : initialValue?.parentRecord;
  }
};

const getBelongsToRelation = (source: any, initialValue: any) => {
  if (isEmpty(initialValue)) return null;
  const dataType = source?.dataType;
  if (dataType === 'list') {
    // before ralation is manyRelationship
    return getValueDataTypeList(source, initialValue);
  } else {
    const idRelation = getFieldRelationValue(source?.fieldId, initialValue);
    const records = getRecords(source?.tableId);

    if (!isEmpty(idRelation)) {
      return find(records, { _id: idRelation });
    } else if (initialValue?.currentParent) {
      // Fix bug relationship Map 2483
      const idParentRelation = getFieldRelationValue(
        source?.fieldId,
        initialValue?.currentParent
      );
      return find(records, { _id: idParentRelation });
    }
  }
};

const gethasManyRelation = (source: any, initialValue: any) => {
  const dataType = source?.dataType;

  if (dataType === 'list' && isArray(initialValue)) {
    // before ralation is manyRelationship
    return getValueDataTypeList(source, initialValue);
  } else {
    const idsRelation = getFieldRelationValue(source?.fieldId, initialValue);
    if (isEmpty(idsRelation)) return [];
    const records = getRecords(source?.tableId);

    return mapperRecordManyRelation(idsRelation, records);
  }
};

const getManyToManyRelation = (source: any, initialValue: any) => {
  const dataType = source?.dataType;

  if (dataType === 'list' && isArray(initialValue)) {
    return getValueDataTypeList(source, initialValue);
  } else {
    const idsRelation = getFieldRelationValue(source?.fieldId, initialValue);
    if (isEmpty(idsRelation)) return [];
    const records = getRecords(source?.tableId);

    return mapperRecordManyRelation(idsRelation, records);
  }
};

const convertOption = (option: any, record: any) => {
  return {
    ...option,
    source: isString(option?.fieldId)
      ? {
          fieldId: option?.fieldId,
          record,
          bindingType: 'bindindFieldOptions',
        }
      : option?.fieldId,
  };
};

const getValueFilterOptions = (records: IRecord[], filterOptions: any) => {
  if (records.length < 0) return [];

  if (isEmpty(filterOptions)) return records;

  const value = records.filter((record) => {
    const config =
      isTwoDirectionArray(filterOptions) || !isArray(filterOptions)
        ? filterOptions
        : [[...filterOptions]];

    return some(config, (option) => {
      if (isArray(option)) {
        return getAvailabilityMultiConditional(
          option.map((option) => convertOption(option, record)),
          record
        );
      } else {
        return getAvailability(convertOption(option, record), record);
      }
    });
  });

  return value;
};

export const excuteRoundRelationship = (
  source: any,
  flattenSource: any[],
  initialValue: any,
  sourceParent?: any
) => {
  let resp: any[] = [];

  for (let i = 0; i < flattenSource.length; i++) {
    const item = flattenSource[i];

    if (i === 0) {
      resp.push(getData(item, initialValue));
    } else {
      switch (item.type) {
        case bindingType.BE_LONG_RELATIONSHIP:
          resp.push(getBelongsToRelation(item, resp[i - 1]));
          break;
        case bindingType.MANY_TO_MANY_RELATIONSHIP:
          resp.push(getManyToManyRelation(item, resp[i - 1]));
          break;
        case bindingType.FIELD:
          resp.push(getFieldRelationValue(item?.fieldId, resp[i - 1]));
          break;
        default:
          resp.push(gethasManyRelation(item, resp[i - 1]));
      }
    }
  }

  const lastValue = resp[flattenSource.length - 1];

  switch (source.dataType) {
    case 'object':
      return lastValue ? lastValue?._id : null;
    // type list (fixed filter)
    default:
      if (sourceParent?.options?.filter) {
        return getValueFilterOptions(
          lastValue || [],
          sourceParent?.options?.filter
        );
      } else {
        return lastValue;
      }
  }
};
