import React from 'react';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import { get, filter, isEmpty, isString } from 'lodash';
import { defaultURL } from '../../../utils/common.constant';

const CarouselItem = (props: any) => {
  const { item, width, height, attributes, onPress, index } = props;
  const { borderRadius, cardCover } = attributes;
  const imageResize = cardCover?.image?.imageResize;

  const imageType = get(cardCover, 'image.imageType');

  const placeholderImageUrl = get(
    attributes,
    'cardCover.image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    attributes,
    'cardCover.image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const imageCanvas =
    typeof cardCover.imageUrl === 'string'
      ? cardCover.imageUrl || placeholderImage
      : Array.isArray(cardCover.imageUrl)
      ? cardCover.imageUrl[0] || placeholderImage
      : placeholderImage;

  const imageUrl = (item: any) => {
    const binding = get(item, 'image.binding');

    if (item?.image?.imageType == 'custom') {
      return binding;
    }

    const isObj = filter(
      cardCover.imageUrl,
      (item) => typeof item === 'object'
    );

    if (imageType === 'internal') {
      return isString(binding)
        ? binding
        : binding?.url || placeholderImage || defaultURL;
    }

    if (imageType === 'url' && !isEmpty(isObj)) {
      if (binding) {
        return isString(binding)
          ? binding
          : binding?.url || placeholderImage || defaultURL;
      } else {
        return item?.image || placeholderImage || defaultURL;
      }
    }

    return imageCanvas;
  };

  const handlePress = () => {
    onPress &&
      onPress(undefined, {
        itemId: item._id,
        groupActionId: undefined,
        indexRecord: index,
      });
  };
  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[styles.container, { borderRadius }]}
    >
      <Image
        source={{ uri: imageUrl(item) }}
        style={[styles.keyImage, { width, height }]}
        resizeMethod="resize"
        resizeMode={imageResize}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#000000',
  },
  keyImage: {
    justifyContent: 'center',
    flex: 1,
    paddingRight: 8,
  },
});

export default CarouselItem;
