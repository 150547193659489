import io from 'socket.io-client';
import appConfig from '../../appConfig.json';

export let appDataBaseSocket: SocketIOClient.Socket;
export const getAppDatabaseChannel = (
  appDatabaseId?: string | number | undefined
) => (appDatabaseId ? `appdatabase_${appDatabaseId}` : null);

export const SOCKET_STATE = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  NOT_CONNECTED: 'not-connected',
  DISCONNECTED: 'disconnected',
};

export const handleAppdataBaseSocket = (channel: any, callback: any) => {
  if (appDataBaseSocket?.connected) {
    callback && callback();
    return;
  }

  appDataBaseSocket = io(appConfig.baseUrl, {
    query: {
      channel, // required name 'channel'
    },
    transports: ['websocket'],
    transportOptions: {
      polling: {
        extraHeaders: {
          // authorization: "Bearer " + "a1bde50f-e72d-4b2e-a4e8-21b33c31bb4c",
        },
      },
    },
  });

  // appDataBaseSocket.on('connect', () => {
  //   console.log('Connected to server appDatabaseSocket: ', channel);
  // });

  // appDataBaseSocket.on('disconnect', (data: any) => {
  //   console.log('Disconnected to server appDatabaseSocket', data);
  // });

  callback && callback();
};
