import { StyleSheet } from 'react-native';
import { checkFont } from '@nocode/components/func';
import ITorusLogin from '@nocode/types/torus.type';

const createStyles = (props: ITorusLogin) => {
  const { width, height, attributes } = props;
  const iconSize = (width < height ? width : height) * 0.7;
  const fontFamily = checkFont(
    attributes.fontFamily,
    attributes.fontWeightBold
  );
  return StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height,
      width,
      opacity: attributes.opacity,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: attributes.backgroundColor,
      borderRadius: attributes.borderRadius,
      borderStyle: attributes.borderStyle,
      borderWidth: attributes.borderWidth,
      borderColor: attributes.borderColor,
      position: 'relative',
    },
    imageWrapper: {
      position: 'relative',
    },
    iconImage: {
      height: iconSize,
      width: iconSize,
    },
    textWrapper: {
      maxWidth: width,
      maxHeight: height,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      paddingHorizontal: 5,
    },
    text: {
      maxWidth: width,
      maxHeight: height,
      fontSize: attributes.fontSize,
      color: attributes.color,
      fontFamily,
      fontStyle: attributes.fontStyleItalic ? 'italic' : 'normal',
      fontWeight: attributes.fontWeightBold ? 'bold' : 'normal',
      opacity: attributes.opacity,
      overflow: 'hidden',
      lineHeight: attributes.lineHeight,
    },
  });
};

export default createStyles;
