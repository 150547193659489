import { BINDING_SELECTOR_TYPE } from '@common/constants/shared';
import {
  deleteRecordSuccess,
  setCurrentRecord,
} from '@common/redux/slice/database';
import { setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { ActionTypes, IAction, IRecord } from '@common/types/';
import { MetaData } from '@common/types/action';
import { getLastedRecordCreate, getTable } from '@common/utils/database';
import { navigateDeleteUserLogin } from '@common/utils/functions';
import excuteDeleteExternal from '@common/utils/handleActions/ActionItem/deleteExternal';
import excuteLogout from '@common/utils/handleActions/ActionItem/logout';
import { get, isArray, isNil } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { deleteRecordApi } from '../func/apiFunction';
import {
  getCollectionNameUuid,
  getUserLoginRecord,
  handlePermissonDelete,
} from '../func/func';
import { getTargetRecord } from './update';

export const isRelation = [
  'belongsToRelation',
  //TODO:
  // 'hasManyRelation',
  // 'manyToManyRelation',
];

export const excuteDeleted: (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  locale?: string
) => Promise<ActionResponse> = async (
  action: IAction,
  metadata: MetaData,
  currentRecord?: any,
  locale?: string
) => {
  const { databaseUuid } = metadata;
  const state: any = store.getState();
  const dispatch = store.dispatch;

  const dataSource = state.database.dataSource;
  const database = get(dataSource, databaseUuid, []);

  const userLoginRecord: IRecord | any = getUserLoginRecord();

  const table = getTable(databaseUuid);

  const {
    options: { selector, tableId, type },
  } = action;

  const appUser = state?.appInfo?.app?.app_team?.map(
    (user: any) => user.user_id
  );

  let targetRecord: IRecord | undefined;
  let shouldUpdateMessage: boolean = false;

  const getSource: any = (obj: Record<string, any>) => {
    if (obj?.source?.selector) {
      const selectorType = get(obj, 'source.selector.type', '');

      switch (selectorType) {
        case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
          const fieldRelationship = database.find(
            (item: any) =>
              item?._id === get(userLoginRecord, `${obj?.fieldId}`, '')
          );

          targetRecord = fieldRelationship;
          break;

        //TODO:
        // case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
        //   break;

        // case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
        //   break;

        default:
          targetRecord = undefined;
          break;
      }
    } else {
      return getSource(obj.source);
    }
  };

  if (table && table.isThirdParty) {
    const currentRecordId = state.externalCollection.recordId;
    return excuteDeleteExternal(state, table, dispatch, currentRecordId);
  }

  //Delete record
  if (selector) {
    switch (selector.type) {
      case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
        targetRecord = get(state, `database.currentRecord[${tableId}]`, {});
        break;

      case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
        // targetRecord = get(state, `database.currentRecord[${tableId}]`, {});
        targetRecord = isArray(currentRecord)
          ? getTargetRecord(currentRecord, databaseUuid)
          : currentRecord;
        break;
      case BINDING_SELECTOR_TYPE.CURRENT_RECORD:
        targetRecord = isArray(currentRecord)
          ? getTargetRecord(currentRecord, databaseUuid)
          : currentRecord;
        break;

      case BINDING_SELECTOR_TYPE.CREATED_OBJECT:
        shouldUpdateMessage = true;
        targetRecord = getLastedRecordCreate(databaseUuid);
        break;

      case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
        if (!userLoginRecord) {
          targetRecord = undefined;
        } else {
          targetRecord = { ...userLoginRecord, _id: userLoginRecord.userId };
        }

        break;

      default:
        targetRecord = undefined;
        break;
    }
  }

  //Delete record of field relationship
  if (type && isRelation.includes(type)) {
    getSource(action?.options);
  }

  const permission = handlePermissonDelete({
    databaseUuid,
    targetRecord: get(targetRecord, 'record', {}),
  });

  if (permission.status) {
    dispatch(
      setError({
        message: permission.message,
        isError: true,
      })
    );

    return {
      status: 'FAILED',
      message: 'No Edit Permission !',
    };
  }

  if (isNil(targetRecord))
    return {
      status: 'FAILED',
      message: 'No selected record',
    };

  try {
    if (targetRecord && targetRecord._id) {
      const recordDeleted: IRecord | any = await deleteRecordApi(
        databaseUuid,
        targetRecord._id,
        appUser
      );

      if (recordDeleted && !recordDeleted.code) {
        const state: any = store.getState();

        if (
          recordDeleted?._id ===
          get(state, `database.currentRecord[${tableId}]`, {})?._id
        ) {
          dispatch(setCurrentRecord({ databaseId: tableId }));
        }

        dispatch(
          deleteRecordSuccess({
            databaseUuid,
            recordDeleted,
          })
        );

        if (
          action?.options?.selector?.type ===
            BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR ||
          selector?.type === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR
        ) {
          excuteLogout({
            ...action,
            actionType: ActionTypes.LOGOUT,
            options: {},
            tableId: null,
          });

          navigateDeleteUserLogin();
        }

        return {
          status: 'SUCCEED',
          target: targetRecord,
          message: locale === 'ja' ? 'Deleted record' : 'Deleted record',
          current: recordDeleted,
        };
      } else {
        return {
          status: 'FAILED',
          message: `Not found record created in ${getCollectionNameUuid(
            databaseUuid
          )}`,
        };
      }
    } else {
      return {
        status: 'FAILED',
        message: `Not found record created in ${getCollectionNameUuid(
          databaseUuid
        )}`,
      };
    }
  } catch (err: any) {
    return {
      status: 'FAILED',
      message: err?.message,
    };
  }
};

export default excuteDeleted;
