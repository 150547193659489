import { Keyboard } from 'react-native';

const dismissKeyboard: () => Promise<any> = async () => {
  Keyboard.dismiss();
  return {
    status: 'SUCCEED',
    message: 'Dismiss keyboard success !',
  };
};

export default dismissKeyboard;
