import { MetaData } from '@common/types/action';
import { IAction } from '@common/types/element';
import sendEmail from '@common/utils/handleActions/ActionItem/sendEmail';
import { getUserLoginRecord } from '@common/utils/handleActions/func/func';

export const torusGetMyPrivateKey = async (
  action: IAction,
  meta: MetaData,
  locale?: string
) => {
  const userLoginRecord: { torusPrivateKey: string; email: string } =
    getUserLoginRecord();

  if (!userLoginRecord.torusPrivateKey) {
    return {
      status: 'FAILED',
      message: 'No privateKey, please login with Torus first!',
    };
  }

  return sendEmail(
    action,
    {
      ...meta,
      optionsNofitication: {
        address: userLoginRecord.email,
        title: 'Your Torus Wallet Private Key',
        body: userLoginRecord.torusPrivateKey,
      },
    },
    locale
  );
};
