import React, { memo, useCallback } from 'react';
import { Image, Text, View, TouchableOpacity } from 'react-native';
import validateColor from 'validate-color';
import { MESSAGE_TYPE } from './index';
import IconUser from './IconUser';
import ProductIcon from './ProductIcon';
import { StyleSheet } from 'react-native';
import { getFontWeight, getTextDecorationLine } from '../func';

type ColorsProp = {
  backgroundColor: string;
  textStyle: {
    fontSize?: number;
    textDecorationLineThrough?: boolean;
    textDecorationUnderline?: boolean;
    fontWeightBold?: boolean;
    fontStyleItalic?: boolean;
    color?: string;
  };
};

type CommentProp = {
  item: any;
  textStyle: any;
  width: number;
  chatBgColor: string;
  onPress: any;
  colors?: {
    joinColors?: ColorsProp;
    commentColors?: ColorsProp;
    giftColors?: ColorsProp;
    purchaseColors?: ColorsProp;
    followColors?: ColorsProp;
  };
};

const getTextOptions = (value: any): string => {
  if (!value) return '';
  if (typeof value === 'string') return value;
  if (Array.isArray(value)) return value.join('');
  return `${value}`;
};

const Comment = (props: CommentProp) => {
  const { item, textStyle, width, chatBgColor, onPress, colors } = props;
  const {
    giftColors,
    purchaseColors,
    joinColors,
    followColors,
    commentColors,
  } = colors || {};
  const { color, fontSize } = textStyle;

  const getUrlAvatar = useCallback((user: any) => {
    let url = '';
    if (user) {
      Object.keys(user).forEach((key) => {
        if (
          Boolean(user[key]) &&
          typeof user[key] === 'object' &&
          user[key]?.url
        ) {
          url = user[key].url;
        }
      });
    }
    return url;
  }, []);

  const getValidateColor = useCallback(
    ({ defaultColor, color }: { color?: string; defaultColor: string }) => {
      if (color && validateColor(color)) {
        return color;
      }
      return defaultColor;
    },
    []
  );

  const getColorProp = useCallback((type: string): ColorsProp => {
    switch (type) {
      case MESSAGE_TYPE.SEND_GIFT: {
        return {
          backgroundColor: getValidateColor({
            color: giftColors?.backgroundColor,
            defaultColor: '#FCA2CF',
          }),
          textStyle: {
            ...giftColors?.textStyle,
            color: getValidateColor({
              defaultColor: color,
              color: giftColors?.textStyle?.color,
            }),
            fontSize: giftColors?.textStyle?.fontSize || fontSize,
          },
        };
      }
      case MESSAGE_TYPE.PRODUCT: {
        return {
          backgroundColor: getValidateColor({
            color: purchaseColors?.backgroundColor,
            defaultColor: '#FFD700',
          }),
          textStyle: {
            ...purchaseColors?.textStyle,
            color: getValidateColor({
              defaultColor: color,
              color: purchaseColors?.textStyle?.color,
            }),
            fontSize: purchaseColors?.textStyle?.fontSize || fontSize,
          },
        };
      }
      case MESSAGE_TYPE.JOIN: {
        return {
          backgroundColor: getValidateColor({
            color: joinColors?.backgroundColor,
            defaultColor: '#B38CFDB2',
          }),
          textStyle: {
            ...joinColors?.textStyle,
            color: getValidateColor({
              defaultColor: color,
              color: joinColors?.textStyle?.color,
            }),
            fontSize: joinColors?.textStyle?.fontSize || fontSize,
          },
        };
      }
      case MESSAGE_TYPE.FOLLOW: {
        return {
          backgroundColor: getValidateColor({
            color: followColors?.backgroundColor,
            defaultColor: '#00DC00B2',
          }),
          textStyle: {
            ...followColors?.textStyle,
            color: getValidateColor({
              defaultColor: color,
              color: followColors?.textStyle?.color,
            }),
            fontSize: followColors?.textStyle?.fontSize || fontSize,
          },
        };
      }
      default: {
        return {
          backgroundColor: getValidateColor({
            color: commentColors?.backgroundColor,
            defaultColor: chatBgColor,
          }),
          textStyle: {
            ...commentColors?.textStyle,
            color: getValidateColor({
              defaultColor: color,
              color: commentColors?.textStyle?.color,
            }),
            fontSize: commentColors?.textStyle?.fontSize || fontSize,
          },
        };
      }
    }
  }, []);

  const getStyle = useCallback(
    (type: string) => {
      const colorProp: ColorsProp = getColorProp(type);
      return StyleSheet.create({
        text: {
          fontSize: colorProp.textStyle.fontSize,
          fontWeight: getFontWeight({
            fontWeight: colorProp.textStyle.fontWeightBold,
          })
            ? 'bold'
            : 'normal',
          textDecorationLine: getTextDecorationLine({
            linethrough: !!colorProp.textStyle.textDecorationLineThrough,
            textDecoration: !!colorProp.textStyle.textDecorationUnderline,
          }),
          color: colorProp.textStyle.color,
        },
        container: {
          backgroundColor: colorProp.backgroundColor,
        },
      });
    },
    [getColorProp]
  );

  const styles = getStyle(item?.message?.type);

  return item !== '' ? (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: 5,
        marginLeft: 5,
        marginTop: 1,
      }}
    >
      <View
        style={[
          styles.container,
          {
            borderRadius: 10,
            padding: 5,
            maxWidth: width - 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: item?.message?.type === MESSAGE_TYPE.PRODUCT ? 9 : 5,
            paddingTop: item?.message?.type === MESSAGE_TYPE.PRODUCT ? 9 : 5,
          },
        ]}
      >
        <TouchableOpacity style={{ width: 35 }} onPress={onPress}>
          {item?.message?.type === MESSAGE_TYPE.PRODUCT ? (
            <View
              style={{
                height: 30,
                width: 30,
                borderRadius: 15,
                marginRight: 5,
                borderWidth: 1,
                borderColor: '#707070',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ProductIcon size={28} />
            </View>
          ) : getUrlAvatar(item?.user) ? (
            <Image
              style={{
                height: 30,
                width: 30,
                borderRadius: 15,
                marginRight: 5,
                borderWidth: 1,
                borderColor: '#707070',
              }}
              source={{ uri: getUrlAvatar(item?.user) }}
            />
          ) : (
            <View
              style={{
                height: 30,
                width: 30,
                borderRadius: 15,
                marginRight: 5,
                borderWidth: 1,
                borderColor: '#707070',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconUser size={28} />
            </View>
          )}
        </TouchableOpacity>
        <View style={{ width: width - 50 }}>
          {item?.message?.type === MESSAGE_TYPE.PRODUCT ? (
            <></>
          ) : (
            <Text style={styles.text}>{item?.user?.username || ''}</Text>
          )}
          <Text
            style={[
              styles.text,
              {
                flexWrap: 'wrap',
              },
            ]}
          >
            {item?.message?.text}
          </Text>
        </View>
      </View>
    </View>
  ) : (
    <View></View>
  );
};
export default memo(Comment);
