import React, { memo } from 'react';
import { View, ActivityIndicator } from 'react-native';
import styles from './style';

type Props = {
  size?: 'small' | 'large' | number;
};

const Loading = (props: Props) => (
  <View style={[styles.container, styles.horizontal]}>
    <ActivityIndicator size="large" />
  </View>
);

export default memo(Loading);
